import adjustKeyNames, {
  addBottomLinksToMegaMenus,
  formatMenus,
} from "../methods/menuHelper";
import axios from "axios";
import stgURL from "../helperObjects/envURLs";
import footerFetch from "./footerFetch";
import { handleSetItem } from "../localStorage/localStorageHandler";

const auth = { auth: { username: "admin", password: "MEP2020" } };

const fetchRegionsAndLanguages = async (language) => {
  // Fetches all region and language options data from Drupal
  const regions = await regionsFetch(language);
  const languages = await languagesFetch(language);

  return { regions: regions, languages: languages };
};

const regionsFetch = async (language = "en") => {
  const response = await axios.get(
    `${stgURL}api4/${language}/mep_country_list`,
    auth
  );

  const regions = adjustKeyNames(response.data);

  regions.forEach((region) => {
    region.isos = [];
    if (region.machine !== null) {
      region.isos = region.machine.split(",");
    }
  });

  handleSetItem("sitewide_countries", regions);

  return regions;
};

const languagesFetch = async (language = "en") => {
  const response = await axios.get(`${stgURL}api5/${language}/getLangs`, auth);

  const languages = adjustKeyNames(response.data);

  handleSetItem("sitewide_langs", languages);

  return languages;
};

export const getLocation = async () => {
  const shhhh = "5FeeMMNq0pC4YNt";
  // Retrieves the user's location for automatic region assignment
  try {
    const response = await axios.get(
      `https://pro.ip-api.com/json/?key=${shhhh}&fields=status,countryCode,query&lang=`
    );

    if (response && response.data && response.data.countryCode) {
      return response;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e, "error");
    return null;
  }
};

export const generalConfigFetch = async (language) => {
  // Fetches general data that is needed for the site, some of which is systemic, some of which is used for populating labels on various pages
  const response = await axios.get(
    `${stgURL}${language}/api-v1/GetNodeByURL/settings$settings/$`,
    auth
  );

  var elements = adjustKeyNames(response.data);
  handleSetItem("general_config", elements);
  return elements;
};

export const generateRegionAndLanguages = (
  sitewideLanguages,
  sitewideRegions,
  divisionName,
  location,
  language,
  regionLabel,
  languageLabel,
  helpText,
  currentRegionLabel,
  saveBtn,
  cancelBtn,
  regionTitle,
  languageTitle
) => {
  // Formats the region/language selector modal data before it is passed into the NavBar

  if (!sitewideLanguages || !sitewideRegions) {
    return null;
  }

  const regions = sitewideRegions.map((region) => {
    return {
      region: region.name,
      languages: sitewideLanguages.map((language) => {
        return `${language.id.toUpperCase()}: ${language.name}`;
      }),
    };
  });

  return {
    regions,
    labels: {
      // TODO generalConfigFetch - get this from Drupal
      currentRegion: currentRegionLabel
        ? currentRegionLabel
        : "Your Current Default Region and Language",
      cancel: cancelBtn ? cancelBtn : "Dismiss",
      save: saveBtn ? saveBtn : "Save",
      region: regionLabel ? regionLabel : "Region",
      language: languageLabel ? languageLabel : "Language",
      helpText: helpText
        ? helpText
        : "If needed, select or change your preferred region and language",
      selectedRegion: regionTitle,
      selectedLanguage: languageTitle,
    },
    companyDivision: divisionName,
    activeRegion: {
      region: location,
      language: language,
    },
  };
};

const getOverviewLabel = (generalConfigData) => {
  if (
    generalConfigData &&
    generalConfigData[0] &&
    generalConfigData[0].settings &&
    generalConfigData[0].settings.trade_overview_label
  ) {
    return generalConfigData[0].settings.trade_overview_label;
  } else {
    return "Overview";
  }
};

export const menuFetch = async (
  language,
  generalConfig = [],
  activeRegion,
  regions
) => {
  const regionObject = regions.find((reg) => reg.name === activeRegion);

  if (regionObject && regionObject.menu_name) {
    const url = `${stgURL}${language}/api-v1/FetchMenuByName/${regionObject.menu_name}`;

    const response = await axios.get(url, auth);

    const overviewLabel = getOverviewLabel(generalConfig);

    const menus = formatMenus(response.data, language, overviewLabel);

    const menusWithBottomLinks = addBottomLinksToMegaMenus(
      menus,
      generalConfig
    );

    handleSetItem("main_menu", menusWithBottomLinks);

    return {
      menus: menusWithBottomLinks,
    };
  } else {
    const response = await axios.get(
      `${stgURL}${language}/api-v1/FetchMenuByName/main`,
      auth
    );

    const overviewLabel = getOverviewLabel(generalConfig);

    const menus = formatMenus(response.data, language, overviewLabel);

    const menusWithBottomLinks = addBottomLinksToMegaMenus(
      menus,
      generalConfig
    );

    handleSetItem("main_menu", menusWithBottomLinks);

    return {
      menus: menusWithBottomLinks,
    };
  }

  // Fetches and formats data for the Footer and some menu data from Drupal
};

export default fetchRegionsAndLanguages;
