import React, { Component } from "react";
import mapProducts from "../../utils/methods/mapProducts";
import PropTypes from "prop-types";
import _ from "lodash";
import "./CollectionsPage.scss";
// import { connect } from "react-redux";
// import { updateStatus, updateInnerPageNav } from "../../actions";
import stgURL from "../../utils/helperObjects/envURLs";
import Loading from "../../components/Loading/Loading";

import collectionsFetch from "../../utils/async/collectionsFetch";
import {
  Feat4Flex,
  FilterHero,
} from "@trimble-creative-strategy/luna-component-lib";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateAlert,
  updateCTAOverride,
} from "../../actions";
import ErrorPage from "../ErrorPage/ErrorPage";
import * as Sentry from "@sentry/browser";
import queryString from "query-string";

class CollectionsPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
      trades: null,
      categories: null,
      defaultText: "All",
      blockFilters: false,
    };
  }

  componentDidMount() {
    this.getData();
    this.checkForQueryInPath();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
    if (prevProps.region !== this.props.region) {
      // for some reason on page load, this.props.region is an empty string and updates pretty quickly AFTER componentDidMount. Bc this.props.region is an empty string at componentDidMount, the methods that are fired off that need region for manipulation do not fire correctly. therefore I call this.checkCountryForFiltering() here, too.
      this.checkCountryForFiltering();
    }
  }

  checkForQueryInPath = () => {
    const values = queryString.parse(this.props.location.search);
    if (values) {
      if (values.trade && values.category) {
        this.setState({
          tradeQuery: values.trade,
          categoryQuery: values.category,
        });
      } else if (values.trade && !values.category) {
        this.setState({ tradeQuery: values.trade, categoryQuery: null });
      } else if (!values.trade && values.category) {
        this.setState({ categoryQuery: values.category, tradeQuery: null });
      }
    }
  };

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getURL = () => {
    return `${stgURL}${this.getLanguage()}/api-v1/FetchAllNodesByType/product_page`;
  };

  getLanguage = () => {
    if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  getData = async () => {
    this.setLoading();

    try {
      const data = await collectionsFetch(this.getLanguage());
      this.checkCountryForFiltering();
      this.props.updateCTAOverride(null);
      this.setSuccess(data);
    } catch (error) {
      console.log(error);
      this.setError();
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
      this.props.updateInnerPageNav({ pageTitle: "500", links: [] });
    }
  };

  setLoading = () => {
    this.props.updateStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = ({ data, innerNavData, metadata }) => {
    this.props.updateStatus("success");
    if (innerNavData) {
      this.props.updateInnerPageNav(innerNavData);
    }
    if (metadata) {
      this.props.updateMetadata(metadata);
    }

    this.setState({
      content: data,
      defaultText: data.hero.dropdowns[0].defaultText,
    });
    window.prerenderReady = true;

    if (this.state.tradeQuery) {
      this.setPreFilledForm(this.state.tradeQuery);
    }
    if (this.state.categoryQuery) {
      this.setPreFilledForm(this.state.categoryQuery);
    }
  };

  setError = () => {
    this.props.updateStatus("error");
    window.prerenderReady = true;
  };

  mapProducts = () => {
    // What the heck is this for? I don't see it called anywhere. - KS
    return mapProducts(this.state.content);
  };

  setPreFilledForm = (input) => {
    let values = queryString.parse(this.props.location.search);
    // check if values is an empty object - for some reason checking if an object is empty is more complex than an array and so I used lodash instead of writing my one function to check..
    // _.isEmpty({}) will return true if object is empty.
    if (!_.isEmpty(values)) {
      let keys = Object.keys(queryString.parse(this.props.location.search));
      let keyOne = keys[0] ? keys[0] : null;
      let keyTwo = keys[1] ? keys[1] : null;
      this.state.content.hero.dropdowns.forEach((trade = {}, index) => {
        trade.options.forEach((option) => {
          if (option && option.toLowerCase().includes(input.toLowerCase())) {
            // these conditionals check if input is in "Trades" dropdown or "Categories" dropdown
            if (keyOne) {
              if (input.toLowerCase() === values[keyOne].toLowerCase()) {
                // call handleDropdownSelect to set dropdown values to query
                this.handleDropdownSelect(option, trade.machineName);
                // setFromSelected will make the dropdown present in the dropdowns
                this.setFormSelected(option);
              }
            }
            if (keyTwo) {
              if (input.toLowerCase() === values[keyTwo].toLowerCase()) {
                // call handleDropdownSelect to set dropdown values to query
                this.handleDropdownSelect(option, trade.name);
                // setFromSelected will make the dropdown present in the dropdowns
                this.setFormSelected(option);
              }
            }
          }
        });
      });
    }
  };

  setFormSelected = (input) => {
    let values = queryString.parse(this.props.location.search);
    let keys = Object.keys(queryString.parse(this.props.location.search));
    let keyOne = keys[0] ? keys[0] : null;
    let keyTwo = keys[1] ? keys[1] : null;
    if (this.state.content.hero.dropdowns) {
      this.state.content.hero.dropdowns.forEach((trade = {}, index) => {
        // checks if query is an exact match of the values or if it is included. Reason for inlcuded string prototype method is bc terms like "Design Detailing", if design or detailing is the query, we want a result to show up. We have 2 conditionals to account for both dropdowns. We reset the default text to whatever the query is, but this does NOT affect the first indice of "All" default text.
        if (keyTwo) {
          if (
            input.toLowerCase() === values[keyTwo].toLowerCase() ||
            (input.toLowerCase().includes(values[keyTwo].toLowerCase()) &&
              index === 0)
          ) {
            trade.defaultText = input;
          }
        }
        if (keyOne) {
          if (
            input.toLowerCase() === values[keyOne].toLowerCase() ||
            (input.toLowerCase().includes(values[keyOne].toLowerCase()) &&
              index === 1)
          ) {
            trade.defaultText = input;
          }
        }
      });
    }
  };

  handleDropdownSelect = (selection, name) => {
    const nameLowerCase = name.toLowerCase();
    const { dropdowns } = this.state.content.hero;

    //. Capture the first array index and use it to compare to the response for language-agnoistic 'ALL' options
    let dropdown = dropdowns.find((dropdown) => {
      return dropdown.name.toLowerCase() === nameLowerCase;
    });

    const machineName = dropdown.machineName.toLowerCase();

    // we need to set the machine names to be state so our state is always the same in every language. Proir to this, our state in ENGLISH looked like `this.state.trade` and `this.state.category`, but in french our state for trade was `this.state.commerce` and `this.state.categorie` for category. this was happening because we were passing in the name of the dropdown and using that to set our state, not taking into account that the name of our dropdown would change depending on language. Throughout this file, when referencing `this.state.trade`, in languages other than english, `this.state.trade` was coming back as null (bc the word TRADE was translated into whichever language selected.)

    this.setState({
      [machineName]: selection === dropdown.options[0] ? null : selection,
    });
  };

  handleCheckboxSelect = (bool, name) => {
    // This is currently vestigial as we are not using bheckboxes in any of the FilterHeros - might need later?
    this.setState({ [name]: bool });
  };

  setFilters = () => {
    // What does this do again? Doesn't seem to be called anywhere.
    this.setCheckboxes();
    this.setDropdowns();
  };

  // dropdowns are listing by translated name instead of machine name. We need to commpare data with machine name instead of translated name. this methodis used in filterCards and filterDropdowns

  taxonomyTerm = () => {
    const taxonomyTerm = JSON.parse(
      JSON.stringify(this.state.content.hero.dropdowns[1].taxonomy)
    );

    let categoryMachineName = taxonomyTerm.find((term = {}) => {
      return term.name === this.state.category;
    });

    return categoryMachineName ? categoryMachineName.machine : null;
  };

  machineNameCategoryList = () => {
    const taxonomyTerm = JSON.parse(
      JSON.stringify(this.state.content.hero.dropdowns[1].taxonomy)
    );
    return taxonomyTerm.map((tax = {}) => {
      return tax.machine;
    });
  };

  filterCards = () => {
    // evalute whether or not any of the filters have selections and return content as described
    // I'm sure this can be refactored into something less verbose, but I can't be bothered.
    if (this.state.trade && this.state.category) {
      const categoryMachineName = this.taxonomyTerm();

      const cards = this.state.content.cards
        .filter((card = {}) => {
          return card.trades.includes(this.state.trade.toLowerCase());
        })
        .filter((card = {}) => {
          return card.categories.includes(categoryMachineName.toLowerCase());
        });
      return this.filterByRegion(cards);
    } else if (this.state.trade) {
      const cards = this.state.content.cards.filter((card = {}) => {
        return card.trades.includes(this.state.trade.toLowerCase());
      });
      return this.filterByRegion(cards);
    } else if (this.state.category) {
      const categoryMachineName = this.taxonomyTerm();

      if (categoryMachineName) {
        const cards = this.state.content.cards.filter((card = {}) => {
          return card.categories.includes(categoryMachineName.toLowerCase());
        });
        return this.filterByRegion(cards);
      }
    } else {
      const cards = this.state.content.cards;
      return this.filterByRegion(cards);
    }
  };

  filterByRegion = (cards) => {
    return cards.filter((card) => {
      if (card.regions.country_exclude === false) {
        // Filter out cards that are marked for region inclusion that don't have the matching region in redux
        return card.regions.countries.reduce((accu, region) => {
          if (region.name.toLowerCase() === this.props.region.toLowerCase()) {
            accu = true;
          }
          return accu;
        }, false);
      } else if (card.regions.country_exclude === true) {
        // Filter out the cards that are marked for region exclusion that match the region in redux
        return card.regions.countries.reduce((accu, region) => {
          if (region.name.toLowerCase() === this.props.region.toLowerCase()) {
            accu = false;
          }
          return accu;
        }, true);
      } else {
        return card;
      }
    });
  };

  filterDropdowns = () => {
    const taxonomyListForCategories =
      this.state &&
      this.state.content &&
      this.state.content.hero &&
      this.state.content.hero.dropdowns &&
      this.state.content.hero.dropdowns[1] &&
      this.state.content.hero.dropdowns[1].taxonomy
        ? this.state.content.hero.dropdowns[1].taxonomy
        : [];

    const dropdowns = JSON.parse(
      JSON.stringify(this.state.content.hero.dropdowns)
    );

    const trades = dropdowns[0].options;

    if (this.state.category) {
      // If a category is selected, filter out trades that don't correspond to that category
      const categoryMachineName = this.taxonomyTerm();

      const filteredTrades = trades.filter((trade = {}, index) => {
        let present = false;

        const filteredCards = this.state.content.cards.filter((card = {}) => {
          return card.categories.includes(categoryMachineName.toLowerCase());
        });

        filteredCards.forEach((card) => {
          if (card.trades.includes(trade.toLowerCase())) {
            present = true;
          }
        });

        return present || index === 0;
      });

      dropdowns[0].options = filteredTrades;
    }

    if (this.state.trade) {
      // machineNameCategoryList is an array of machine names for category which looks like this
      // --> [estimation, design-detailing, project-managament]. we need this because the cards are filtered by machine names instead of names listed in the dropdown.
      let machineNameCategoryList = this.machineNameCategoryList();

      // If a trade is  selected, filter out the categories that don't respond
      const filteredCategories = machineNameCategoryList.filter(
        (category, index) => {
          let present = false;

          this.state.content.cards.forEach((card = {}) => {
            if (card.categories.includes(category.toLowerCase())) {
              if (card.trades.includes(this.state.trade.toLowerCase())) {
                present = true;
              }
            }
          });

          return present || index === 0;
        }
      );

      // filteredCategories returns a list using machine names. This does not include defaultText of "All". We need to translate the machine names to the taxonomy names, which is what is happening below:

      //finalCategoryOptions will become an array
      let finalCategoryOptions;

      // here we check to make sure there is defaultText in state. Realistically, defaultText should always be in state, if for some reason it is not, we manually push "All" into the finalCategoryOptions array as a fail safe.
      this.state.content.hero.dropdowns[0].defaultText
        ? (finalCategoryOptions = [this.state.defaultText])
        : (finalCategoryOptions = ["All"]);

      // we loop through our taxonomyListForCategories, which is the unfiltered category dropdown list that contains both name (which is displayed) and machine name.
      if (filteredCategories) {
        taxonomyListForCategories.forEach((tax = {}) => {
          filteredCategories.forEach((cat) => {
            if (cat === tax.machine) {
              finalCategoryOptions.push(tax.name);
            }
          });
        });
      }

      // we set the categories dropdown to the newly filtered category list with names instead of machine names.
      dropdowns[1].options = finalCategoryOptions;
    }

    // the last two conditionals seem pointless but they need to be here for the query to work properly..
    if (
      this.state.trade &&
      !this.state.category &&
      this.state.tradeQuery &&
      !this.state.categoryQuery
    ) {
      dropdowns[1].defaultText = this.state.defaultText;
    }

    if (
      !this.state.trade &&
      this.state.category &&
      !this.state.tradeQuery &&
      this.state.categoryQuery
    ) {
      dropdowns[0].defaultText = this.state.defaultText;
    }
    return dropdowns;
  };

  checkCountryForFiltering = () => {
    const country = this.props.country;
    const region = this.props.region;
    if (
      country === "FR" ||
      country === "DE" ||
      country === "CH" ||
      country === "AT"
    ) {
      this.setState({ blockFilters: true });
    } else if (
      region === "Germany and Austria" ||
      region === "France" ||
      region === "Switzerland"
    ) {
      this.setState({ blockFilters: true });
    } else {
      this.setState({ blockFilters: false });
    }
  };

  render() {
    const { content } = this.state;
    const { status, navbarReady } = this.props;
    return (
      <main
        className={`mep-collections-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-collections-page--loading"}`}
      >
        {(status === "loading" || (!navbarReady && status !== "error")) && (
          <Loading />
        )}
        {status === "error" && <ErrorPage />}
        {status === "success" && navbarReady && content && (
          <div>
            {!this.state.blockFilters && (
              <FilterHero
                header={content.hero.header}
                text={content.hero.text}
                dropdowns={this.filterDropdowns()}
                checkboxes={content.hero.checkboxes}
                theme="brand-very-dark"
                handleCheckboxSelect={this.handleCheckboxSelect}
                handleDropdownSelect={this.handleDropdownSelect}
                noKeyUpo
              />
            )}
            {this.state.blockFilters && (
              <FilterHero
                header={content.hero.header}
                text={content.hero.text}
                theme="brand-very-dark"
              />
            )}
            {this.filterCards().length > 0 && (
              <Feat4Flex
                cards={this.filterCards()}
                motion
                motionDirection="bottom"
                theme="theme-1"
              />
            )}
            {this.filterCards().length === 0 && <p>no results</p>}
            {/* TODO CollectionsPage - Replace the above with a real page - probably not necessary since filtering stops this anyway*/}
          </div>
        )}
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateStatus: (status) => dispatch(updateStatus(status)),
    updateMetadata: (data) => dispatch(updateMetadata(data)),
    updateInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateAlert: (alert) => dispatch(updateAlert(alert)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
  };
};

const mapStateToProps = (state) => ({
  status: state.status,
  innerPageNav: state.innerPageNav,
  language: state.language,
  metadata: state.metadata,
  region: state.region,
  navbarReady: state.navbarReady,
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsPage);

CollectionsPage.propTypes = {
  match: PropTypes.object.isRequired,
};
