const redirects = [
  {
    redirect: "/products/prodesign",
    conditionals: [
      { string: "prodesign" },
      { contains: false, string: "prodesign-3d" },
    ],
    row: 10,
  },
  // commented out due to new redirect requirement on 5/18/21 - LS
  // {
  //   redirect: "/products/stabicad",
  //   conditionals: [{ string: "stabicad" }],
  //   row: 11,
  // },
  {
    redirect: "/products/fasttest",
    conditionals: [{ string: "fasttest" }],
    row: 12,
  },
  {
    redirect: "/products/singlecable",
    conditionals: [{ string: "singlecable" }],
    row: 13,
  },
  {
    redirect: "/products/prodesign-3d",
    conditionals: [{ string: "prodesign-3d" }],
    row: 14,
  },
  {
    redirect: "/products/luckinslive",
    conditionals: [{ string: "luckinslive" }],
    row: 15,
  },
  {
    redirect: "/products/luckins",
    conditionals: [
      { string: "luckins" },
      { string: "luckinslive", contains: false },
    ],
    row: 16,
  },
  {
    redirect: "/training",
    conditionals: [
      { string: "training" },
      // commented out due to new redirect requirement on 5/18/21 - LS
      // { string: "stabicad", contains: false },
    ],
    row: 17,
  },
  {
    redirect: "/products/protect",
    conditionals: [{ string: "protect" }],
    row: 18,
  },
  {
    redirect: "/products/job-costing",
    conditionals: [{ string: "job-costing" }],
    row: 19,
  },
  {
    redirect: "/products/payroll-costing",
    conditionals: [{ string: "payroll-costing" }],
    row: 20,
  },
  {
    redirect: "/resources/",
    conditionals: [{ string: "case-study" }],
    row: 21,
  },
  {
    redirect: "/products/livecount",
    conditionals: [
      { string: "livecount" },
      { string: "quickscale", contains: true },
    ],
    row: 22,
  },
  {
    redirect: "/support",
    conditionals: [
      { string: "support" },
      { string: "support-nederland-belgie", contains: false },
    ],
    row: 24,
  },
  {
    redirect: "/mechanical-solutions/field-technology",
    conditionals: [{ string: "field" }, { string: "scanner", contains: true }],
    row: 25,
  },
  {
    redirect: "/services/3d-modeling-services",
    conditionals: [{ string: "3d-model" }],
    row: 26,
  },
  {
    redirect: "/products/enterprise-estimating",
    conditionals: [
      { string: "estimating/enterprise" },
      { string: "enterprise-estimating", contains: true },
    ],
    row: 27,
  },
  {
    redirect: "/products/extreme-estimating",
    conditionals: [{ string: "extreme" }, { string: "xg", contains: true }],
    row: 28,
  },
  {
    redirect: "/products/powernet",
    conditionals: [{ string: "powernet" }],
    row: 29,
  },
  {
    redirect: "/products/contract-management",
    conditionals: [{ string: "contract-management" }],
    row: 30,
  },
  {
    redirect: "/products/service-manager",
    conditionals: [
      { string: "service-manager" },
      { string: "service-management", contains: true },
    ],
    row: 31,
  },
  {
    redirect: "/products/trimble-sysque",
    conditionals: [{ string: "sysque" }],
    row: 32,
  },
  {
    redirect: "/products/quotemaster",
    conditionals: [{ string: "quotemaster" }],
    row: 33,
  },
  {
    redirect: "/products/cost-modeller",
    conditionals: [{ string: "cost-modeller" }],
    row: 34,
  },
  {
    redirect: "/products/toolsmaster",
    conditionals: [{ string: "toolsmaster" }],
    row: 35,
  },
  {
    redirect: "/products/contractors-accounts",
    conditionals: [{ string: "contractors-accounts" }],
    row: 36,
  },
  {
    redirect: "/products/stock-control",
    conditionals: [{ string: "stock-control" }],
    row: 37,
  },
  {
    redirect: "/training",
    conditionals: [{ string: "node/16" }],
    row: 38,
  },
  {
    // TODO when this page is published, we need to change this back to: redirect: "/products/trimble-nova",
    redirect: "/products/",
    conditionals: [{ string: "plancal" }],
    row: 40,
  },
  {
    redirect: "/products/trimble-connect",
    conditionals: [{ string: "connect" }],
    row: 41,
  },
  {
    redirect: "/mechanical-solutions/field-technology",
    conditionals: [
      { string: "implantation" },
      { string: "scanner", contains: true },
      { string: "numerisation", contains: true },
    ],
    row: 43,
  },
  {
    redirect: "/services/3d-laser-scan-and-scan-modeling-services",
    conditionals: [
      { string: "service" },
      { string: "video", contains: false },
      { string: "manager", contains: false },
      { string: "management", contains: false },
    ],
    row: 44,
  },
  {
    redirect: "/support",
    conditionals: [{ string: "support" }, { string: "user", contains: true }],
    row: 47,
  },
  {
    redirect: "/training",
    conditionals: [{ string: "schulung" }],
    row: 49,
  },
  {
    redirect: "/mechanical-solutions/field-technology",
    conditionals: [
      { string: "mess" },
      { string: "laser", contains: true },
      { string: "scan", contains: true },
    ],
    row: 50,
  },
  {
    redirect: "/products/trimble-accubid-classic",
    conditionals: [{ string: "accubid-classic" }],
    row: 53,
  },
  {
    redirect: "/mechanical-solutions/design-detailing",
    conditionals: [
      { string: "ductdesigner" },
      { string: "ductmaker", contains: true },
    ],
    row: 56,
  },
  {
    redirect: "/products/trimble-livecount",
    conditionals: [
      { string: "livecount" },
      { string: "training", contains: false },
    ],
    row: 57,
  },
  {
    redirect: "/products/trimble-autobid-mechanical",
    conditionals: [
      { string: "autobid-mechanical" },
      { string: "training", contains: false },
    ],
    row: 58,
  },
  {
    // TODO - when this page is published, we have to change this back to: redirect: "/products/trimble-accubid-enterprise"
    redirect: "/products/",
    conditionals: [
      { string: "accubid-enterprise" },
      { string: "training", contains: false },
      { string: "change-management", contains: false },
    ],
    row: 59,
  },
  {
    // TODO - when this page is published, we have to change this back to: "/products/trimble-accubid-enterprise-change-management"
    redirect: "/products/",
    conditionals: [{ string: "change-management" }],
    row: 60,
  },
  {
    redirect: "/resources/webinars-events-webinar-recordings",
    conditionals: [{ string: "webinar" }],
    row: 61,
  },
  {
    redirect: "/products/trimble-ec-cad",
    conditionals: [{ string: "ec-cad" }],
    row: 62,
  },
  {
    redirect: "/mechanical-solutions/field-technology",
    conditionals: [
      { string: "field" },
      { string: "layout", contains: true },
      { string: "rts", contains: true },
    ],
    row: 63,
  },
  {
    redirect: "/mechanical-solutions/field-technology",
    conditionals: [
      { string: "realworks" },
      { string: "tx6", contains: true },
      { string: "scan", contains: true },
    ],
    row: 64,
  },
  {
    redirect: "/mechanical-solutions/field-technology",
    conditionals: [
      { string: "edgewise" },
      { string: "gnss", contains: true },
      { string: "rpt", contains: true },
    ],
    row: 65,
  },
  {
    redirect: "/products/trimble-accubid-anywhere",
    conditionals: [{ string: "accubid-anywhere" }],
    row: 66,
  },
  {
    redirect: "/products/trimble-fabshop",
    conditionals: [{ string: "fabshop" }],
    row: 67,
  },
  {
    // TODO when this page is published, we need to change this back to: redirect: "/products/trimble-pipedesigner-3d",
    redirect: "/products/",
    conditionals: [{ string: "pipedesigner" }],
    row: 68,
  },
  // {
  //   redirect: "/products/trimble-estimation",
  //   conditionals: [{ string: "estimation" }],
  //   row: 69,
  // },
  {
    redirect: "/products/trimble-autobid-sheetmetal",
    conditionals: [{ string: "autobid-sheet-metal" }],
    row: 70,
  },
  {
    redirect: "/mechanical-solutions/field-technology",
    conditionals: [{ string: "alltrack" }, { string: "asset", contains: true }],
    row: 71,
  },
  {
    redirect: "/products/tra-ser",
    conditionals: [{ string: "tra-ser" }],
    row: 72,
  },
  {
    redirect: "/products/trimble-accubid-changeorder",
    conditionals: [{ string: "changeorder" }],
    row: 73,
  },
  {
    redirect: "/get-in-touch",
    conditionals: [{ string: "get-in-touch" }],
    row: 74,
  },
  {
    redirect: "/products/trimble-connect",
    conditionals: [{ string: "trimble-connect" }],
    row: 75,
  },
  {
    redirect: "/products/allpriser-winpriser",
    conditionals: [{ string: "winpriser" }],
    row: 76,
  },
  {
    redirect: "/products/trimble-time-and-material-billing",
    conditionals: [{ string: "time-and" }],
    row: 77,
  },
  {
    redirect: "/products/submittal-manager",
    conditionals: [{ string: "submittal-manager" }],
    row: 78,
  },
  {
    redirect: "/products/allpriser-epriser",
    conditionals: [{ string: "epriser" }],
    row: 79,
  },
  {
    redirect: "/mechanical-solutions/mep-content",
    conditionals: [{ string: "itm" }],
    row: 80,
  },
  {
    redirect: "/services/3d-modeling-services",
    conditionals: [{ string: "3d-modeling" }],
    row: 81,
  },
  {
    redirect: "/bim-services",
    conditionals: [{ string: "dienst" }],
    row: 88,
  },
  {
    redirect: "/products/pallete-cad-ch",
    conditionals: [{ string: "palette" }],
    row: 89,
  },
  {
    redirect: "/products/trimble-metrics",
    conditionals: [{ string: "business" }, { string: "snap", contains: false }],
    row: 90,
  },
  {
    redirect: "/resources/ch-de-events-webinare-demnächst",
    conditionals: [{ string: "event" }],
    row: 91,
  },
  {
    redirect: "/mechanical-solutions/field-technology",
    conditionals: [
      { string: "scan" },
      { string: "numerisation", contains: true },
      { string: "implantation", contains: true },
    ],
    row: 96,
  },
  {
    redirect: "/resources/ch-fr-vidéos-toutes-les-vidéos",
    conditionals: [
      { string: "video" },
      { string: "novatip", contains: false },
      { string: "truest-demo-short", contains: false },
    ],
    row: 97,
  },
  {
    redirect: "/products/truest/",
    conditionals: [{ string: "/product/truest/plumbing/" }],
  },
  {
    redirect: "/products/truest/",
    conditionals: [{ string: "/products/truest/plumbing/" }],
  },
  {
    redirect: "/products/truest/",
    conditionals: [{ string: "/product/truest/mechanical/" }],
  },
  {
    redirect: "/products/truest/",
    conditionals: [{ string: "/products/truest/mechanical/" }],
  },
  {
    redirect:
      "https://mep.trimble.com/en/resources/product-truest/truest-how-to-perform-takeoff",
    conditionals: [
      { string: "/resources/videos/truest-how-to-perform-takeoff/" },
    ],
    external: true,
  },
  {
    redirect:
      "https://mep.trimble.com/en/training/north-america/training/start-up-videos/truest-systems-and-materials",
    conditionals: [
      { string: "/training/start-up-videos/truest-systems-and-materials" },
    ],
    external: true,
  },
  {
    redirect:
      "https://mep.trimble.com/en/training/north-america/training/start-up-videos/truest-perform-takeoff",
    conditionals: [
      { string: "/training/start-up-videos/truest-perform-takeoff" },
    ],
    external: true,
  },
  {
    redirect: "/products/truest",
    conditionals: [{ string: "/products/truest//" }],
  },
  {
    redirect: "/products/truest/",
    conditionals: [{ string: "/product/truest/" }],
  },
  {
    redirect:
      "https://mep.trimble.com/en/resources/product-truest/truest-explainer",
    conditionals: [{ string: "/resources/videos/truest-demo-short" }],
    external: true,
  },
  {
    redirect:
      "https://mep.trimble.com/en/training/north-america/training/start-up-videos/truest-labor-factoring",
    conditionals: [
      { string: "/training/start-up-videos/truest-labor-factoring" },
    ],
    external: true,
  },
  {
    redirect: "/en/resources/product-truest/truest-explainer",
    conditionals: [{ string: "/resources/videos/truest-demo-short/ " }],
    external: true,
  },
  {
    redirect: "/",
    conditionals: [{ string: "sheet-metal/fabrication" }],
    row: "sentry-issue",
  },
  {
    redirect: "/",
    conditionals: [{ string: "constructjob" }],
  },
];

export default redirects;
