import basicFetch from "./basicFetch";
import stgURL from "../helperObjects/envURLs";

const searchFetch = async (searchString, lang) => {
  const url = `${stgURL}${lang}/api-v1/FetchSearchResults/0/100/${searchString}`;

  const response = await basicFetch(url);
  return response.data;
};

export const getNoResultsData = async (lang) => {
  const url = `${stgURL}${lang}/api-v1/getNodeByUrl/no-search-results/$`;
  const response = await basicFetch(url);

  return response.data[0].content_sections["404_hero"][0];
};

export default searchFetch;
