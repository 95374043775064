export default (data, regions) => {
  if (data) {
    const regionsData = fixRegionsData(regions) || [];

    const region = regionsData.find((region) => {
      return region.countries.includes(data);
    });

    if (region) {
      return region.name;
    }
  }

  // Failsafe
  return "North America";
};

const fixRegionsData = (regions) => {
  if (regions && Array.isArray(regions)) {
    return regions.map((item) => {
      const countries = item.iso_code ? item.iso_code.split(",") : null;
      return {
        name: item.name,
        countries,
      };
    });
  } else {
    return [
      {
        name: "North America",
        countries: ["US", "CA"],
      },
      {
        name: "UK and Middle East",
        countries: [
          "GB",
          "BH",
          "CY",
          "EG",
          "IR",
          "IQ",
          "IL",
          "JO",
          "KW",
          "LB",
          "OM",
          "QA",
          "SA",
          "SY",
          "TR",
          "AE",
          "YE",
        ],
      },
    ];
  }
};
