export const updateStatus = (status) => {
  return {
    type: "UPDATE_STATUS",
    status,
  };
};

export const updateLanguage = (language) => {
  return {
    type: "UPDATE_LANGUAGE",
    language,
  };
};

export const updateInnerPageNav = (innerPageNav) => {
  return {
    type: "UPDATE_INNER_PAGE_NAV",
    innerPageNav,
  };
};

export const updateMetadata = (metadata) => {
  return {
    type: "UPDATE_METADATA",
    metadata,
  };
};

export const updateOffsetY = (offsetY) => {
  return {
    type: "UPDATE_OFFSET_Y",
    offsetY,
  };
};

export const updateRegion = (region) => {
  return {
    type: "UPDATE_REGION",
    region,
  };
};

export const updateAlert = (alert) => {
  return {
    type: "UPDATE_ALERT",
    alert,
  };
};

export const updateCTAOverride = (link) => {
  return {
    type: "UPDATE_CTA_OVERRIDE",
    link,
  };
};

export const updateNavbarReady = (bool) => {
  return {
    type: "UPDATE_NAVBAR_READY",
    bool,
  };
};

export const updateLearnMoreLabel = (text) => {
  return {
    type: "UPDATE_LEARN_MORE_LABEL",
    text,
  };
};

export const updateErrorStatus = (errorStatus) => {
  return {
    type: "UPDATE_ERROR_STATUS",
    errorStatus,
  }
}
