import fpobox from "../../assets/images/fpobox.png";
import squareFPO from "../../assets/images/500fpo.png";
import { isEmpty } from "./utilityMethods";
import SixByNineFPO from "../../assets/images/600x900.png";
import React from "react";
import { AccordionFold } from "terra-component-lib";

export const generatePatternData = (pattern) => {
  // takes pattern data in an array and returns it as an object
  if (!pattern) return {};

  return {
    theme: pattern[0],
    parallax: pattern[1],
    backgroundImage: pattern[2],
    position: pattern[3],
    offsetX: pattern[4],
    offsetY: pattern[5],
    width: pattern[6],
    height: pattern[7],
  };
};

export const formatFeatTwoRedirectData = (content = {}) => {
  // Formats the FeatTwo content
  return {
    sectionName: content.sectionName,
    header: content.header,
    image_large: {
      url:
        content.image_large && content.image_large.source
          ? content.image_large.source
          : null,
      alt:
        content.image_large && content.image_large.alt
          ? content.image_large.alt
          : null,
    },
    children: generateFeatTwoChildren(content.list),
  };
};

const generateFeatTwoChildren = (list = {}) => {
  // Generate accordion folds for Feat Two
  return Object.values(list).reduce((accu, value = {}) => {
    if (value.list) {
      const children = Object.values(value.list);
      children.forEach((child = {}) => {
        const fold = (
          <AccordionFold key={child.header} header={child.header}>
            {child.content}
          </AccordionFold>
        );
        accu.push(fold);
      });
    }
    return accu;
  }, []);
};

export const generateFeatFourFlexHeader = (content) => {
  if (content) {
    if (content.title && typeof content.title === "string") {
      return content.title;
    }
    if (content.values) {
      if (content.values[0]) {
        const keys = Object.keys(content.values[0]);

        const key = keys.find((key) => {
          return key !== "type";
        });

        const teaserObject = content.values[0][key];

        if (teaserObject.teaser) {
          if (teaserObject.teaser.teaser_title) {
            return teaserObject.teaser.teaser_title;
          }
        }
      }
    }
  } else {
    return null;
  }
};

export const featOneImageCheck = (image) => {
  // replaces no image with an FPO image
  return {
    url: image.source || squareFPO,
    altText: image.alt || "No alt text",
  };
};

export const cleanFeatFourFlexCards = (
  content,
  category,
  trade,
  sort,
  label
) => {
  // formats cards for Feat4Flex
  const cards = content.values.reduce((accu, card) => {
    const keys = Object.keys(card);
    const filteredKeys = keys.filter((key) => {
      return key !== "type";
    });

    const data = card[filteredKeys[0]].teaser;

    const formattedCard = {
      header: data.teaser_title,
      image: cleanCardImages(data.teaser_img),
      text: data.teaser_desc,
      link: {
        url: addTradeCatPath(data.teaser_url, category, trade),
        text: label ? label : "Learn More",
      },
      regions: data.regions,
    };

    // This will check for duplicate cards and push only if there are no duplicates
    let okayPushIt = true;

    accu.forEach((item) => {
      if (
        item.header === formattedCard.header &&
        item.text === formattedCard.text
      ) {
        okayPushIt = false;
      }
    });

    if (okayPushIt) {
      accu.push(formattedCard);
    }

    return accu;
  }, []);

  // If sort is passed as a prop (from Trade/Category pages), it will sort the cards alphabetically, otherwise just return the cards as-is.
  if (sort) {
    return cards.sort((a, b) => {
      if (a.header && b.header && a.header < b.header) {
        return -1;
      }
      if (a.header && b.header && a.header > b.header) {
        return 1;
      }
      return 0;
    });
  } else {
    return cards;
  }
};

export const cleanCardImages = (image) => {
  // generic method for formatting image data for cards
  const cleanedImage = {};

  if (image.source) {
    cleanedImage.url = image.source;
  } else {
    cleanedImage.url = fpobox;
  }

  if (image.alt) {
    cleanedImage.altText = image.alt;
  } else {
    cleanedImage.altText = "No accessibility text";
  }

  return cleanedImage;
};

export const formatFeatNineContent = (unformattedContent) => {
  // FAILSAFE CONDITIONAL //
  if (
    !unformattedContent.testimonials ||
    isEmpty(unformattedContent.testimonials)
  ) {
    return false;
  }

  // Data comes in a nested object with random numbers for key names - takes the data and makes it readable by Feat9
  const keys = Object.keys(unformattedContent.testimonials);

  const testimonial = keys.reduce((accu, key) => {
    if (unformattedContent.testimonials[key].type === "testimonial") {
      accu.quote = {
        source: unformattedContent.testimonials[key].testimonial.name,
        sourceTitle: unformattedContent.testimonials[key].testimonial.title,
        text: unformattedContent.testimonials[key].testimonial.body || "",
      };
    } else if (unformattedContent.testimonials[key].type === "cta_section") {
      accu.cta = {
        url: unformattedContent.testimonials[key].ctas.ctaOne.url,
        text: unformattedContent.testimonials[key].ctas.ctaOne.text || "",
      };
    } else if (unformattedContent.testimonials[key].type === "text") {
      accu.quote = {
        text: unformattedContent.testimonials[key].text || "",
      };
    }
    return accu;
  }, {});

  return testimonial;
};

export const formatHeroOneImages = (images) => {
  const formattedImage = { mobile: {}, tablet: {}, desktop: {} };

  if (
    images.image_small &&
    images.image_small.source &&
    images.image_small.alt
  ) {
    formattedImage.mobile.url = removeSpaces(images.image_small.source);
    formattedImage.mobile.altText = images.image_small.alt;
  } else {
    formattedImage.mobile = undefined;
  }

  if (
    images.image_medium &&
    images.image_medium.source &&
    images.image_medium.alt
  ) {
    formattedImage.tablet.url = removeSpaces(images.image_medium.source);
    formattedImage.tablet.altText = images.image_medium.alt;
  } else if (
    images.image_large &&
    images.image_large.source &&
    images.image_large.alt
  ) {
    formattedImage.tablet.url = removeSpaces(images.image_large.source);
    formattedImage.tablet.altText = images.image_large.alt;
  } else {
    formattedImage.tablet = undefined;
  }

  if (
    images.image_large &&
    images.image_large.source &&
    images.image_large.alt
  ) {
    formattedImage.desktop.url = removeSpaces(images.image_large.source);
    formattedImage.desktop.altText = images.image_large.alt;
  } else {
    formattedImage.desktop = undefined;
  }

  return formattedImage;
};

const removeSpaces = (url) => {
  return url.replace(/\s/g, "%20");
};

export const generateHeroFiveImage = (content) => {
  if (content.image_large) {
    return {
      url: content.image_large.source,
      altText: content.image_large.alt,
    };
  } else if (content.image_medium) {
    return {
      url: content.image_medium.source,
      altText: content.image_medium.alt,
    };
  } else return undefined;
};

export const cleanFeatEightContent = (
  content,
  category,
  trade,
  learnMoreLabel
) => {
  // clean up the content from the featurette eight item from Drupal
  const keys = Object.keys(content.list);
  let newCard;
  const cards = keys
    .map((card) => {
      return content.list[card];
    })
    .filter((card) => {
      return card.teaser;
    })
    .map((card) => {
      const { teaser } = card;
      if (
        teaser &&
        teaser.teaser_cta &&
        teaser.teaser_cta.url.includes("https")
      ) {
        newCard = {
          image: cleanCardImages(teaser.teaser_img),
          header: teaser.teaser_title,
          text: teaser.teaser_desc,
          buttonLink: {
            url: teaser.teaser_cta.url,
            external: true,
            text:
              teaser && teaser.teaser_cta && teaser.teaser_cta.text
                ? teaser.teaser_cta.text
                : learnMoreLabel
                ? learnMoreLabel
                : "Learn More",
          },
        };
      } else {
        newCard = {
          image: cleanCardImages(teaser.teaser_img),
          header: teaser.teaser_title,
          text: teaser.teaser_desc,
          buttonLink: {
            url: addTradeCatPath(
              teaser && teaser.teaser_cta && teaser.teaser_cta.url
                ? teaser.teaser_cta.url
                : teaser && teaser.teaser_url
                ? teaser.teaser_url
                : null,

              category,
              trade
            ),
            text:
              teaser && teaser.teaser_cta && teaser.teaser_cta.text
                ? teaser.teaser_cta.text
                : learnMoreLabel
                ? learnMoreLabel
                : "Learn More",
          },
        };
      }

      return newCard;
    });

  return cards.reduce((accu, card) => {
    let okayPushIt = true;

    accu.forEach((item) => {
      if (item.header === card.header && item.text === card.text) {
        okayPushIt = false;
      }
    });

    if (okayPushIt) {
      accu.push(card);
    }

    return accu;
  }, []);
};

const addTradeCatPath = (url, category, trade) => {
  if (url && category && trade) {
    const product = url.replace("/products", "");
    return `/${trade}-solutions/${category}${product}`;
  } else {
    return url || "";
  }
};

export const generateFeatEightCTAs = (ctas) => {
  if (!ctas) {
    return [];
  }

  const keys = Object.keys(ctas);

  return keys.map((cta) => {
    return {
      text: ctas[cta].text,
      url: ctas[cta].url,
    };
  });
};

export const cleanCTAData = (ctas) => {
  if (!ctas) return null;
  const formattedCTAs = {};

  if (ctas.ctaOne && (ctas.ctaOne.url || ctas.ctaOne.onClick)) {
    formattedCTAs.ctaOne = {
      ...ctas.ctaOne,
      className: "ter-button--primary--1",
    };
  }

  if (ctas.ctaTwo && (ctas.ctaTwo.url || ctas.ctaTwo.onClick)) {
    formattedCTAs.ctaTwo = {
      ...ctas.ctaTwo,
      className: "ter-button--secondary--1",
    };
  }

  if (ctas.ctaSub && ctas.ctaSub.url) {
    formattedCTAs.subCTA = ctas.ctaSub;
  }

  if (!formattedCTAs.ctaOne) {
    return null;
  }

  return formattedCTAs;
};

export const generateFeatThreeItems = (content) => {
  if (content) {
    const keys = Object.keys(content);

    const list = keys.reduce((accu, key) => {
      if (content[key].type === "icon_list_item") {
        accu.push(content[key]);
      }
      return accu;
    }, []);

    return list;
  } else {
    return [];
  }
};

export const generateFeatThreeImages = (content) => {
  const images = {};

  if (content.image_large) {
    images.desktop = {
      url: content.image_large.source,
      altText: content.image_large.alt,
    };
  } else {
    images.desktop = {
      url: SixByNineFPO,
      altText: "FPO",
    };
  }

  if (content.image_medium) {
    images.tablet = {
      url: content.image_medium.source,
      altText: content.image_medium.alt,
    };
  } else {
    images.tablet = {
      url: content.image_large.source,
      altText: content.image_large.alt,
    };
  }

  if (content.image_small) {
    images.mobile = {
      url: content.image_small.source,
      altText: content.image_small.alt,
    };
  } else {
    images.mobile = {
      url: content.image_large.source,
      altText: content.image_large.alt,
    };
  }

  return images;
};

export const generateFeatThreeHeader = (content) => {
  if (content.header) {
    return content.header;
  } else if (content.list && content.list.header) {
    return content.list.header.header;
  } else {
    return null;
  }
};

export const formatVideoThumbnail = (thumbnail) => {
  return {
    url: thumbnail.source,
    altText: thumbnail.alt,
  };
};

export const formatFeatFourCards = (cards) => {
  const cardValues = Object.values(cards);
  return cardValues.map((card) => {
    const text = card.text
      ? card.text.replace("<p>", "").replace("</p>", "")
      : null;
    return { ...card, text };
  });
};

export const formatBIMCards = (cards = {}) => {
  // The BIM services page has a unique Feat4Flex that requires its own card formatting
  return Object.values(cards).map(({ teaser = {} }) => {
    return {
      header: teaser.teaser_title,
      text: teaser.teaser_desc
        ? teaser.teaser_desc.replace("<p>", "").replace("</p>", "")
        : null,
    };
  });
};

export const formatHomePageTradesCards = (
  cards = {},
  learnMoreText = "Learn More"
) => {
  return Object.values(cards).map((cardObj = {}) => {
    const card = cardObj.teaser;
    return {
      header: card.teaser_title,
      image: {
        url:
          card.teaser_img && card.teaser_img.source
            ? card.teaser_img.source
            : null,
        altText:
          card.teaser_img && card.teaser_img.alt ? card.teaser_img.alt : null,
      },
      text: card.teaser_desc,
      link: {
        url: card.teaser_url,
        text: learnMoreText,
      },
    };
  });
};

export const formatCTAsForDemoLink = (ctas, path = "") => {
  if (
    ctas &&
    ctas.ctaOne &&
    ctas.ctaOne.url &&
    ctas.ctaOne.url.includes("demo")
  ) {
    // Sometimes the paths have an '/' at the end, and sometimes not
    if (path.length && path.substring(path.length - 1) === "/") {
      const newPath = `${path}demo`;
      ctas.ctaOne.url = newPath;
    } else {
      const newPath = `${path}/demo`;
      ctas.ctaOne.url = newPath;
    }
  }

  return ctas;
};

export const formatHTMLCards = (data) => {
  return data.map((card) => {
    return card.value;
  });
};
