import { combineReducers } from "redux";
import { statusReducer } from "./statusReducer";
import { languageReducer } from "./languageReducer";
import { innerPageNavReducer } from "./innerPageNavReducer";
import { metadataReducer } from "./metadataReducer";
import { offsetYReducer } from "./offsetYReducer";
import { regionReducer } from "./regionReducer";
import { alertReducer } from "./alertReducer";
import { ctaOverrideReducer } from "./ctaOverrideReducer";
import { navbarReadyReducer } from "./navbarReadyReducer";
import { learnMoreLabelReducer } from "./learnMoreLabelReducer";
import { errorStatusReducer } from "./errorStatusReducer";

const rootReducer = combineReducers({
  status: statusReducer,
  language: languageReducer,
  innerPageNav: innerPageNavReducer,
  metadata: metadataReducer,
  offsetY: offsetYReducer,
  region: regionReducer,
  alert: alertReducer,
  ctaOverride: ctaOverrideReducer,
  navbarReady: navbarReadyReducer,
  learnMoreLabel: learnMoreLabelReducer,
  errorStatus: errorStatusReducer,
});

export default rootReducer;
