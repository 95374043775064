import fetchFromURL from "../async/basicFetch";
import stgURL from "../../utils/helperObjects/envURLs";

export const checkForRedirectFetch = async (path, getLanguage, language) => {
  const parsedPath = path.split("/");

  let url;

  if (getLanguage) {
    url = `${stgURL}${getLanguage(parsedPath[1])}/api-v1/GetNodeByURL/${
      parsedPath[2]
    }/$`;
  } else if (language && !getLanguage) {
    url = `${stgURL}${checkForLanguage(language)}/api-v1/GetNodeByURL/${
      parsedPath[2]
    }/$`;
  } else {
    return;
  }

  const response = await fetchFromURL(url);

  if (response?.data?.status_code === "404") {
    return;
  }

  if (response?.data?.status_code === "301") {
    if (
      response?.data?.redirect_destination &&
      response?.data?.redirect_destination.length
    ) {
      window.open(response?.data?.redirect_destination[0], "_self");
    }
  } else {
    return null;
  }
};

const checkForLanguage = (language) => {
  if (language) {
    return language;
  } else {
    return "en";
  }
};
