import React from "react";
import { TabsPanel, IconListItem } from "terra-component-lib";
import { Feat4Flex } from "@trimble-creative-strategy/luna-component-lib";

const mapTabs = (tabs, theme) => {
  if (tabs) {
    const keys = Object.keys(tabs);
    return keys.reduce((accu, key, index) => {
      const tabData = tabs[key];
      if (tabData.type === "tab_list_items" && tabData.list) {
        const list = formatIconListItemData(tabData.list);
        const tab = generateTabsPanelForListItems(
          {
            name: tabData.name,
            list
          },
          index,
          theme
        );
        accu.push(tab);
      } else if (tabData.type === "tab" && tabData.list) {
        const cards = formatCards(tabData.list);
        const tab = generateTabsPanelForCards(tabData.name, cards, index);

        accu.push(tab);
      }

      return accu;
    }, []);
  } else {
    return null;
  }
};

export default mapTabs;

const generateIconListItems = (items, theme) => {
  return items.map(item => {
    return (
      <IconListItem
        key={item.header}
        content={item}
        insertHTML={true}
        theme={theme}
      />
    );
  });
};

const generateTabsPanelForListItems = (tab, index, theme) => {
  return (
    <TabsPanel key={`${tab.name}-${index}`} name={tab.name}>
      {generateIconListItems(tab.list, theme)}
    </TabsPanel>
  );
};

const formatIconListItemData = items => {
  return Object.keys(items).map(key => {
    return {
      header: items[key].header,
      text: items[key].text,
      icon: items[key].icon
    };
  });
};

const generateTabsPanelForCards = (name, cards, index, theme) => {
  return (
    <TabsPanel key={`${name}-${index}`} name={name}>
      <Feat4Flex cards={cards} theme={theme} />
    </TabsPanel>
  );
};

const formatCards = cards => {
  return Object.keys(cards).map(key => {
    const card = cards[key].teaser;
    return {
      header: card.teaser_title,
      text: card.teaser_desc,
      image: { url: card.teaser_img.source, altText: card.teaser_img.alt },
      link: { url: card.teaser_url, text: "Learn More" },
      insertHTML: true
    };
  });
};
