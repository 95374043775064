import React, { Component } from "react";
import "./SearchResultPage.scss";
import {
  Feat4Flex,
  Hero5,
} from "@trimble-creative-strategy/luna-component-lib";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateCTAOverride,
} from "../../actions";
import searchTranslation from "../../utils/helperObjects/searchTranslation";
import searchFetch, { getNoResultsData } from "../../utils/async/searchFetch";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import * as Sentry from "@sentry/browser";
import cardFPOImage from "../../assets/images/cardfpo.png";
class SearchResultPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
      searchTerm: null,
      noResultsData: null,
    };
  }

  componentDidMount() {
    this.initializeSearchFetch();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.initializeSearchFetch();
    }
  };

  initializeSearchFetch = async () => {
    // Grab the search paramater out of the address bar and use it to query Drupal
    this.setLoading();

    const searchString = this.props.match.params.searchTerm;

    const searchTerm = searchString.replace("%20", " ");

    try {
      const content = await searchFetch(
        searchTerm,
        this.props.language || "en"
      );
      this.props.setInnerPageNav({
        pageTitle: searchTranslation[this.props.language] || "Search",
        links: [],
      });
      this.props.updateCTAOverride(null);
      this.setSuccess(content, searchTerm);
    } catch (error) {
      console.log(error);
      this.setError();
      this.props.setInnerPageNav({ pageTitle: "500", links: [] });
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
    }
  };

  renderResults = () => {
    // Format and and return the data necessary to render cards
    const { results } = this.state.content;

    // Format the product cards
    const cards = results.map((result) => {
      const card = {
        header: result.header,
        link: {
          url: result.link,
          text: this.props.learnMoreLabel || "Learn More",
        },
        text: result.text,
        regions: result.regions,
      };

      if (result.image && result.image.source && result.image.alt) {
        card.image = {
          url: result.image.source,
          altText: result.image.alt,
        };
      } else {
        card.image = {
          url: cardFPOImage,
          altText: "FPO image",
        };
      }

      return card;
    });

    // Remove duplicate cards - this is a band-aid for Drupal
    const nonDupeCards = cards.reduce((accu, card) => {
      let okayPushIt = true;

      accu.forEach((item) => {
        if (item.header === card.header) {
          okayPushIt = false;
        }
      });

      if (!card.link) {
        okayPushIt = false;
      }

      if (card.link && !card.link.url) {
        okayPushIt = false;
      }

      if (!card.header) {
        okayPushIt = false;
      }

      if (okayPushIt) {
        accu.push(card);
      }

      return accu;
    }, []);

    // Filter out the products that are not available in the current region
    const regionFilteredCards = this.filterCardsByRegion(nonDupeCards);

    return (
      <Feat4Flex
        theme={"theme-1"}
        cards={regionFilteredCards}
        motion={true}
        motionDirection={"bottom"}
        motionDistance={"100px"}
        // fallbackImage={cardFPOImage}
      />
    );
  };

  updateContent = (content) => {
    this.setState({ content });
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = async (content, searchTerm) => {
    // If there are no results from the search, fetch the data for rendering 'No results'
    if (content.count === 0) {
      const noResultsData = await getNoResultsData(this.props.language);
      await this.setNoResultsData(noResultsData, searchTerm);
    }

    this.updateContent(content);
    // this.updateSearchTerm(searchTerm);
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  setNoResultsData = (noResultsData, searchTerm) => {
    this.setState({
      noResultsData,
      searchTerm,
    });
  };

  filterCardsByRegion = (nonDupeCards = []) => {
    // Refactored this to not use conditionals
    return nonDupeCards.filter((card) => {
      return (
        (card.regions &&
          card.regions.country_exclude === false &&
          card.regions.countries.reduce((accu, region) => {
            if (region && region.name && region.name === this.props.region) {
              accu = true;
            }
            return accu;
          }, false)) ||
        (card.regions &&
          card.regions.country_exclude === true &&
          card.regions.countries.reduce((accu, region) => {
            if (region && region.name && region.name === this.props.region) {
              accu = false;
            }
            return accu;
          }, true))
      );
    });
  };

  render() {
    const { status, navbarReady } = this.props;
    const { content, noResultsData } = this.state;

    return (
      <main
        className={`mep-search-result-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-search-result-page--loading"}`}
      >
        {" "}
        {(status === "loading" || (!navbarReady && status !== "error")) && (
          <Loading />
        )}
        {status === "error" && <ErrorPage />}
        {this.state.content &&
          this.state.content.input &&
          this.state.content.input.keywords &&
          status === "success" &&
          navbarReady && (
            <p className="mep-search-result-page__text">
              {this.props.resultsForLabel || "Results for"} "
              {this.state.content.input.keywords}
              ":
            </p>
          )}
        {}
        {status === "success" && content && navbarReady && this.renderResults()}
        {(status === "success" && !content) ||
          (status === "success" &&
            content.results.length === 0 &&
            noResultsData &&
            navbarReady && (
              <NoResults
                header={noResultsData.header}
                images={(noResultsData.image_large, noResultsData.image_medium)}
                pattern={noResultsData.pattern}
                theme={noResultsData.theme}
                ctas={noResultsData.ctas}
                subHeader={noResultsData.subHeader}
                language={this.props.language}
              />
            ))}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  navbarReady: state.navbarReady,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultPage);

const NoResults = ({
  header,
  subHeader,
  ctas,
  images,
  theme,
  pattern,
  language,
}) => {
  ctas.ctaOne = {
    url: `/${language}`,
    text: ctas.ctaOne.text,
  };
  return (
    <div className="mep-no-results">
      <Hero5
        header={header}
        subHeader={subHeader}
        ctas={{ ctaOne: ctas.ctaOne }}
        image={{ url: images.source, altText: images.alt }}
        theme={theme}
        pattern={pattern}
      />
    </div>
  );
};
