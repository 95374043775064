import React, { Component } from "react";
import supportFetch from "../../utils/async/supportFetch";
import "./SupportPage.scss";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
} from "../../actions";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import { connect } from "react-redux";
import { Hero1, Feat8 } from "@trimble-creative-strategy/luna-component-lib";
import SupportLinkTiles from "../../components/SupportLinkTiles/SupportLinkTiles";
import SupportHTMLCards from "../../components/SupportHTMLCards/SupportHTMLCards";
import * as Sentry from "@sentry/browser";

class SupportPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const language = this.getLanguage();

    try {
      const {
        content = {},
        innerNavData,
        metadata,
        ctaOverride = null,
      } = await supportFetch(language);
      this.setState({
        content,
      });

      if (innerNavData) {
        this.props.updateInnerPageNav(innerNavData);
      }

      if (metadata) {
        this.props.updateMetadata(metadata);
      }

      this.props.updateCTAOverride(ctaOverride);

      this.setSuccess();
    } catch (e) {
      console.log(e);
      this.setError();
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(e);
      }
    }
  };

  getLanguage = () => {
    return this.props.language || "en";
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };
  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  render() {
    const { content } = this.state;
    const { status, navbarReady } = this.props;
    return (
      <main
        className={`mep-support-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-support-page--loading"}`}
      >
        {(status === "loading" ||
          (!navbarReady && status !== "error" && status !== "error")) && (
          <Loading />
        )}
        {status === "error" && <ErrorPage />}
        {status === "success" && navbarReady && content && (
          <React.Fragment>
            {content.hero && (
              <Hero1
                header={
                  content.hero && content.hero.header
                    ? content.hero.header
                    : null
                }
                text={
                  content.hero && content.hero.text ? content.hero.text : null
                }
                ctas={content.hero.link ? { ctaOne: content.hero.link } : null}
                theme="theme-5"
                images={
                  content.hero && content.hero.images
                    ? content.hero.images
                    : null
                }
                insertHTML
                motion
              />
            )}
            {content.linkTiles && (
              <SupportLinkTiles cards={content.linkTiles} />
            )}
            {content.HTMLCards && (
              <SupportHTMLCards cards={content.HTMLCards} />
            )}
            {content.feat8 && (
              <Feat8
                header={
                  content.feat8 && content.feat8.header
                    ? content.feat8.header
                    : null
                }
                subHeader={
                  content.feat8 && content.feat8.subHeader
                    ? content.feat8.subHeader
                    : null
                }
                cards={
                  content.feat8 && content.feat8.cards
                    ? content.feat8.cards
                    : null
                }
                ctas={
                  content.feat8 && content.feat8.ctas
                    ? content.feat8.ctas
                    : null
                }
              />
            )}
          </React.Fragment>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.status,
    language: state.language,
    navbarReady: state.navbarReady,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    updateInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (data) => dispatch(updateMetadata(data)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportPage);
