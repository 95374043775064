const convertKey = (obj, oldKey, newKey) => {
  /* This renames a key to another name. */
  if (obj.hasOwnProperty(oldKey)) {
    if (oldKey !== newKey) {
      Object.defineProperty(
        obj,
        newKey,
        Object.getOwnPropertyDescriptor(obj, oldKey)
      );
      delete obj[oldKey];
    }
  }
  return obj;
};

const adjustKeyNames = (elements, depth = 0) => {
  /* This is a recursive function to rename and remove attributes from the menu tree */
  const tempElements = [...elements];

  return tempElements.reduce((accu, element) => {
    ["description", "weight", "uuid", "uri", "absolute", "alias"].forEach(
      function(name) {
        delete element[name];
      }
    );

    element.depth = depth;
    if (depth === 0) {
      element.type = "link";
      if (element.below) {
        element.type = "mini menu";
      }
      if (element.below && element.below[0] && element.below[0].below) {
        element.type = "mega menu";
      }
    }
    element = convertKey(element, "title", "text");
    element = convertKey(element, "relative", "url");
    if (element["below"]) {
      element = convertKey(element, "below", "links");
      let ndepth = depth + 1;
      adjustKeyNames(element["links"], ndepth);
    }
    accu.push(element);

    return accu;
  }, []);
};

export const addBottomLinksToMegaMenus = (menus, generalConfig = []) => {
  return menus.map((menu) => {
    if (menu.type === "mega menu") {
      if (menu.url) {
        const fixedURL = menu.url.replace("//solutions", "/products");
        return {
          ...menu,
          bottomLink: {
            url: fixedURL,
            text:
              generalConfig &&
              generalConfig[0] &&
              generalConfig[0].settings &&
              generalConfig[0].settings.all_products_label
                ? generalConfig[0].settings.all_products_label
                : "All Products",
          },
        };

        // This 'All Products' text is replaced in App.js when passed to the navbar with Drupal data, it is here as a fail safe
      }
    }

    return menu;
  });
};

export const formatMenus = (data, language, overviewLabel) => {
  // Formats the navbar menu data

  // Adds a 'type' property to each menu object to indicate what kind of menu it is
  const typedData = assignMenuType(data);

  // Format each menu based on type
  const formattedData = typedData.map((menu) => {
    if (menu.type === "mega menu") {
      return megaMenuFormat(menu, language, overviewLabel);
    } else if (menu.type === "mini menu") {
      return miniMenuFormat(menu, language);
    } else {
      return linkFormat(menu, language);
    }
  });

  return formattedData;
};

const assignMenuType = (data) => {
  return data.map((menu) => {
    // If the menu has two levels of children, assign it 'mega menu'
    if (menu.children && menu.children[0] && menu.children[0].children) {
      menu.type = "mega menu";
    } else if (menu.children) {
      // If the menu has one level of children, assign it 'mini menu'
      menu.type = "mini menu";
      // If no children, assign it 'link'
    } else {
      menu.type = "link";
    }
    return menu;
  });
};

const megaMenuFormat = (data, language, overviewLabel) => {
  return {
    url: checkForInternalLink(data.url) ? `/${language}/${data.url}` : data.url,
    type: data.type || "",
    bottomLink: null,
    text: data.title || "",
    links: formatMegaMenuTopLevel(data.children, overviewLabel, language) || [],
  };
};

const miniMenuFormat = (data, language) => {
  return {
    type: data.type || "",
    text: data.title || "",
    links: formatMiniMenuLinks(data.children, language) || [],
  };
};

const linkFormat = (data, language) => {
  return {
    type: data.type || "",
    url: checkForInternalLink(data.url)
      ? `/${language}${data.url}`
      : data.url || "",
    text: data.title || "",
    external:
      data.attributes &&
      data.attributes.target &&
      data.attributes.target === "_self"
        ? false
        : true,
  };
};

const formatMiniMenuLinks = (links = [], language) => {
  // Used for formatting the links in the mini menu as well as the right-hand side of the MegaMenu
  return links.map((link) => {
    return {
      url: checkForInternalLink(link.url)
        ? `/${language}${link.url}`
        : link.url || "",
      text: link.title || "",
      external:
        link.attributes &&
        link.attributes.target &&
        link.attributes.target === "_self"
          ? false
          : true,
    };
  });
};

const formatMegaMenuTopLevel = (
  links = [],
  overviewLabel = "Overview",
  language
) => {
  // Format the data for the  left-hand side of the Mega Menu
  const topLevel = links.map((link) => {
    const bottomLevelLinks = formatMiniMenuLinks(link.children, language) || [];
    const bottomLevel = {
      text: link.title || "",
      links: bottomLevelLinks.filter((bottomLevelLink) => {
        return bottomLevelLink.url !== `/${language}${link.url}`;
      }),
    };

    // Creates the 'Overview' link for the right-hand side of each trade
    bottomLevel.links.unshift({
      text: overviewLabel,
      url: checkForInternalLink(link.url)
        ? `/${language}${link.url}`
        : link.url,
      external:
        link.attributes &&
        link.attributes.target &&
        link.attributes.target === "_self"
          ? false
          : true,
    });

    return bottomLevel;
  });

  return topLevel;
};

const checkForInternalLink = (link) => {
  if (link.substring(0, 4) !== "http") {
    return true;
  } else {
    return false;
  }
};

export default adjustKeyNames;
