import basicFetch from "./basicFetch";
import _ from "lodash";
import stgURL from "../../utils/helperObjects/envURLs";

const collectionsFetch = async (language) => {
  const allProductsCardsURL = `${stgURL}${language}/api-v1/FetchAllNodesByType/product_page`;

  const allProductsMiscDataUrl = `${stgURL}${language}/api-v1/GetNodeByURL/products/$`;

  const allDropdownProductCategoriesURL = `${stgURL}${language}/api-v1/FetchTaxonomyByMachineName/mep_product_categories`;
  // "https://stg-mep.trimble.com/en/api-v1/FetchTaxonomyByMachineName/mep_product_categories";

  // Get the data for generating product cards
  const allProductsCards = await basicFetch(allProductsCardsURL);
  // Get the data for the rest of the page (hero, misc labels)
  const allProductsMiscData = await basicFetch(allProductsMiscDataUrl);

  const allDropdownProductCategories = await basicFetch(
    allDropdownProductCategoriesURL
  );

  // Format the misc data
  const formattedMiscData =
    allProductsMiscData.data &&
    allProductsMiscData.data[0] &&
    allProductsMiscData.data[0].content_sections &&
    allProductsMiscData.data[0].link_label
      ? formatMiscData(
          allProductsMiscData.data[0].content_sections,
          allProductsMiscData.data[0].link_label
        )
      : null;

  // Generate the dropdowns based on the trades/categories in cards
  const dropdowns =
    allProductsCards.data &&
    allProductsCards.data.nodes &&
    allProductsMiscData.data &&
    allProductsMiscData.data[0] &&
    allProductsMiscData.data[0].all_options_label &&
    allProductsMiscData.data[0].trade_label &&
    allProductsMiscData.data[0].category_label &&
    allDropdownProductCategories.data
      ? formatDropdowns(
          allProductsCards.data.nodes,
          allProductsMiscData.data[0].all_options_label,
          allProductsMiscData.data[0].trade_label,
          allProductsMiscData.data[0].category_label,
          allDropdownProductCategories.data
        )
      : null;

  // Format the card data
  const cards =
    allProductsCards.data &&
    allProductsCards.data.nodes &&
    formattedMiscData.linkText
      ? formatCards(allProductsCards.data.nodes, formattedMiscData.linkText)
      : null;
  // Create the hero object
  const hero = {
    header: formattedMiscData.header
      ? formattedMiscData.header
      : "Explore All Our Products",
    text: formattedMiscData.text
      ? formattedMiscData.text
      : "Filter and search a comprehensive list of all our mechanical, electrical, and plumbing hardware and software products purpose-built to help you maximize productivity and increase profitability.",
    dropdowns,
    checkboxes: [],
  };

  const labels = {
    categoryLabel:
      allProductsMiscData.data &&
      allProductsMiscData.data[0] &&
      allProductsMiscData.data[0].category_label
        ? allProductsMiscData.data[0].category_label
        : null,
    industryLabel:
      allProductsMiscData.data &&
      allProductsMiscData.data[0] &&
      allProductsMiscData.data[0].industry_label
        ? allProductsMiscData.data[0].industry_label
        : null,
  };

  // Create the data object
  const data = { cards, hero, labels };

  // Create the innerNavData object
  const innerNavData = {
    pageTitle:
      allProductsMiscData.data &&
      allProductsMiscData.data[0] &&
      allProductsMiscData.data[0].name
        ? allProductsMiscData.data[0].name
        : "All Products",
    links: [],
  };

  // Create the metadata object
  const metadata = {
    title:
      allProductsMiscData.data &&
      allProductsMiscData.data[0] &&
      allProductsMiscData.data[0].name
        ? allProductsMiscData.data[0].name
        : null,
  };

  return { data, innerNavData, metadata };
};

export default collectionsFetch;

const formatCards = (cards, linkText) => {
  // Format product cards
  return cards.map((card) => {
    return {
      header: card.teaser_title ? card.teaser_title : null,
      text: card.teaser_desc ? card.teaser_desc : null,
      image: {
        url:
          card.teaser_img && card.teaser_img.source
            ? card.teaser_img.source
            : null,
        altText:
          card.teaser_img && card.teaser_img.alt ? card.teaser_img.alt : null,
      },
      link: {
        url: card.teaser_url ? card.teaser_url : null,
        text: linkText,
      },
      categories: card.categories ? removeHTMLTags(card.categories) : [],
      trades: card.trades || [],
      regions: card.regions || [],
    };
  });
};

const removeHTMLTags = (array) => {
  return array.map((point) => {
    return point.replace("<p>", "").replace("</p>", "");
  });
};

const formatDropdowns = (
  cards,
  allText,
  tradeLabel,
  categoryLabel = "Categories",
  taxonomy
) => {
  // Create and format the categories array
  const categoriesOptions = _.uniq(
    cards.reduce((accu, card) => {
      const { categories } = card;

      if (categories) {
        const nonHTMLCategories = removeHTMLTags(categories);
        accu = [...accu, ...nonHTMLCategories];
      }
      return accu;
    }, [])
  );

  // 'allText' refers to the first index of the array, usually something like 'All' or 'All Products'

  // Uppercase the first letter - if the first three letters are 'bim', uppercase them all
  const formattedCategoryOptions = categoriesOptions.map((option) => {
    const split = option.split("");
    split[0] = split[0].toUpperCase();

    if (
      split[0].toLowerCase() === "b" &&
      split[1].toLowerCase() === "i" &&
      split[2].toLowerCase() === "m"
    ) {
      split[1] = split[1].toUpperCase();
      split[2] = split[2].toUpperCase();
    }

    if (
      split[0].toLowerCase() === "m" &&
      split[1].toLowerCase() === "e" &&
      split[2].toLowerCase() === "p"
    ) {
      split[1] = split[1].toUpperCase();
      split[2] = split[2].toUpperCase();
    }

    return split.join("");
  });

  const exisitingCategoriesUsingMachineNames = formattedCategoryOptions.map(
    (cat) => {
      return cat.toLowerCase();
    }
  );

  let finalCategoryOptions = [];

  taxonomy.forEach((tax) => {
    exisitingCategoriesUsingMachineNames.forEach((cat) => {
      if (cat === tax.machine) {
        finalCategoryOptions.push(tax.name);
      }
    });
  });

  finalCategoryOptions.unshift(allText);

  const categories = {
    options: finalCategoryOptions,
    machineName: "category",
    defaultText: allText,
    name: categoryLabel,
    taxonomy: taxonomy,
  };

  // Create and format the trades array
  const tradesOptions = _.uniq(
    cards
      .reduce((accu, card) => {
        const { trades } = card;
        if (trades) {
          accu = [...accu, ...trades];
        }
        return accu;
      }, [])
      .filter((option) => {
        // Filter out the unused trades
        return (
          option.toLowerCase() !== "hvac" &&
          option.toLowerCase() !== "sheet metal" &&
          option.toLowerCase() !== "sheet-metal" &&
          option.toLowerCase() !== "ict"
        );
      })
  );

  tradesOptions.unshift(allText);

  // Uppercase the first letter of each trade
  const formattedTradeOptions = tradesOptions.map((option) => {
    const split = option.split("");
    split[0] = split[0].toUpperCase();
    return split.join("");
  });

  const trades = {
    options: formattedTradeOptions,
    defaultText: allText,
    machineName: "trade",
    name: tradeLabel,
  };
  return [trades, categories];
};

const formatMiscData = (data, linkText = "Learn More") => {
  // Generate the header and text for the hero
  const header =
    data.all_something_header &&
    data.all_something_header[0] &&
    data.all_something_header[0].field_header_text &&
    data.all_something_header[0].field_header_text.value &&
    data.all_something_header[0].field_header_text.value[0] &&
    data.all_something_header[0].field_header_text.value[0].value
      ? data.all_something_header[0].field_header_text.value[0].value
      : null;
  const text =
    data.all_something_header &&
    data.all_something_header[1] &&
    data.all_something_header[1].field_sub_header_text &&
    data.all_something_header[1].field_sub_header_text.value &&
    data.all_something_header[1].field_sub_header_text.value[0] &&
    data.all_something_header[1].field_sub_header_text.value[0].value
      ? data.all_something_header[1].field_sub_header_text.value[0].value
      : null;

  const content = { header, text, linkText };
  return content;
};
