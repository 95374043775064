import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
} from "../../actions";
import errorFetch from "../../utils/async/errorFetch";
import stgURL from "../../utils/helperObjects/envURLs";
import Loading from "../../components/Loading/Loading";
import PageBuilder from "../../components/PageBuilder/PageBuilder";
import "./FourZeroFourPage.scss";
import * as Sentry from "@sentry/browser";
import {
  handleGetItem,
  handleSetItem,
} from "../../utils/localStorage/localStorageHandler";
import RedirectManagement from "../../components/RedirectManagement/RedirectManagement";
import { withRouter } from "react-router-dom";

class FourZeroFourPage extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    this.getData();
  }

  getLanguage = () => {
    if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getURL = () => {
    return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/404/$#`;
  };

  getData = async () => {
    this.checkForWrongLanguageCode();
    const url = this.getURL();
    this.setLoading();

    try {
      const { content, metadata } = await errorFetch(url);
      this.props.setInnerPageNav({ pageTitle: "404", links: [] });
      this.props.updateMetadata(metadata);
      this.props.updateCTAOverride(null);
      handleSetItem("fourOhFourData", content);
      this.setState({ content });
      setTimeout(this.setSuccess, 700);
    } catch (error) {
      console.log(error);
      this.setError();
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };

  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  checkForWrongLanguageCode = () => {
    // check to see our current language in the application matches the language in the URl path
    const currentLanguage = this.props.language;
    const langInURL = this.props.location.pathname.split("/")[1];

    // get the new route if applicable. Route will be null if language in path is the language the application is set in and no incorrect languages are present in url string.
    const route = this.replaceLanguageInURL();

    if (route) {
      // redirect user to current path with correct language code
      window.open(`${window.location.origin}${route}`, "_self");
    }
  };

  replaceLanguageInURL = () => {
    // currentLanguage is the language the application is set as
    const currentLanguage = this?.props?.language;

    // this is the language in the url
    const langInURL = this?.props?.location?.pathname?.split("/")[1];

    // this is the url path
    const path = this?.props?.location?.pathname;

    // check if language in url is different than the current language the application and if it is, we need to build our new path
    if (langInURL !== currentLanguage) {
      // path.replace will replace the current language code in the url to the correct language
      // ex: Language for application is NL. Current url is mep.trimble.com/en/legal. This would be replace to mep.trimble.com/nl/legal

      return path.replace(langInURL, currentLanguage);
    } else {
      // If the above happens where the incorrect language is in the path, sometimes the incorrect language appears in the url after the corerct language - mep.trimble.com/en/nl/ - this causes a fetch call to be done looking for page titled "en". In this method checkForWrongPath, we account for this.
      return this.checkForWrongPath(
        currentLanguage,
        path,
        langInURL,
        this.props.languages
      );
    }
  };

  checkForWrongPath = (currentLanguage, path, langInURL, languages) => {
    // we take our path (type string) and create an array.
    // ex - path "/en/nl" becomes ["", "en", "nl"]
    const parsePath = path.split("/");

    // we want to check for the incorrect language in the path. From example ["", "en", "nl"], the incorrect language is "nl". This is what we are looking to filter.
    const findWrongLanguageInRoute = parsePath.filter((lang) => {
      if (lang !== currentLanguage && lang !== langInURL && lang !== "") {
        return this.checkLangList(lang, languages);
      }
    });

    // if above returns an array, we know this means the path is broken and we need to resolve it.
    if (
      findWrongLanguageInRoute &&
      findWrongLanguageInRoute.length &&
      findWrongLanguageInRoute.length === 1
    ) {
      if (path.includes(`/${findWrongLanguageInRoute[0]}/`)) {
        return path.replace(`/${findWrongLanguageInRoute[0]}/`, "");
      } else if (path.includes(`/${findWrongLanguageInRoute[0]}`)) {
        return path.replace(`/${findWrongLanguageInRoute[0]}`, "");
      }
      // if the path is not broken, we don't care what the path is and we need to get out of this method.
    } else {
      return null;
    }
  };

  checkLangList = (language, languageList) => {
    if (!languageList || !language) {
      return null;
    }
    // this checks if the language in wrong part of the path is one of the languages this site is available in
    return languageList.find((lang) => {
      return language === lang;
    });
  };

  render() {
    const { status, navbarReady } = this.props;
    const { content } = this.state;
    return (
      <main
        className={`mep-404-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-404-page--loading"}`}
      >
        <RedirectManagement getLanguage={this.getLanguage} />
        {(status === "loading" || (!navbarReady && status !== "error")) && (
          <Loading />
        )}
        {status === "error" && <p>error</p>}
        {status === "success" && navbarReady && content && (
          <PageBuilder content={content} />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  navbarReady: state.navbarReady,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (metadata) => dispatch(updateMetadata(metadata)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FourZeroFourPage));
