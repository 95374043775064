import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

const HelmetHandler = (props) => {
  const {
    language,
    metadata,
    country,
    region,
    allLanguages,
    allRegions,
    status,
    error,
  } = props;
  const locationForHrefLang = () => {
    // location.match(/(\/\w+|\/\w+\-\w+)\//) returns an array that looks like this: ["/en-GB/", "/en-GB", index: 0, input: "/en-GB/", groups: undefined]
    // we want indice one from the above so location.match(/(\/\w+|\/\w+\-\w+)\//)[1] will return either /en, /fr, /de, /nl, or /en-gb.
    // if the above returns something (and is not false), we want to replace location to not include the langauge (what we matched above). location, for example, could be /en/products, so it would instead only return /products

    // there was a sentry error that location was undefined, therefore I added an additional null check
    let location =
      props.location && props.location.pathname
        ? props.location.pathname
        : "https://mep.trimble.com/";
    const lang =
      location.match(/(\/\w+|\/\w+\-\w+)\//) &&
      location.match(/(\/\w+|\/\w+\-\w+)\//)[1]
        ? location.match(/(\/\w+|\/\w+\-\w+)\//)[1]
        : false;
    if (lang) {
      location = location.replace(lang, "");
      return location;
    } else {
      return;
    }
  };

  const generateHREFLangOptions = () => {
    if (allLanguages && allLanguages.length)
      return allLanguages.map((lang) => (
        <link
          rel="alternate"
          href={
            locationForHrefLang() && lang.id
              ? `https://mep.trimble.com/${lang.id}${locationForHrefLang()}`
              : lang.id && !locationForHrefLang()
              ? `https://mep.trimble.com/${lang.id}`
              : `https://mep.trimble.com/en`
          }
          hrefLang={lang.id ? lang.id : "en"}
          key={lang.id ? lang.id : "en"}
        />
      ));
  };

  return (
    <>
      <Helmet>
        <meta name="region" content={region ? region : "North America"} />
        <meta name="country" content={country ? country : "US"} />
        <meta name="lang" content={language ? language : "en"} />
        {generateHREFLangOptions()}
        <link
          id="favicon"
          rel="alternate"
          href="/favicon.ico"
          hrefLang={language ? language : "en"}
          type="image/x-icon"
          data-react-helmet="true"
        />

        <title>{metadata.title || "Trimble MEP"}</title>
        <meta charSet="utf-8" />
        {(status === "error" || error ) && (
        <meta name="prerender-status-code" content="501" />
        )}
        {metadata.og_image && (
          <meta property="og:image" content={metadata.og_image} />
        )}
        {metadata.canonical_url && (
          <link
            rel="canonical"
            href={`https://mep.trimble.com${metadata.canonical_url}`}
            hrefLang={language ? language : "en"}
          />
        )}

        {(metadata.description || metadata.pageTitle || metadata.content) && (
          <meta
            name="description"
            content={
              metadata.description || metadata.content || metadata.pageTitle
            }
          />
        )}
        {language && <html lang={language} amp />}
      </Helmet>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  metadata: state.metadata,
});

export default connect(mapStateToProps, null)(withRouter(HelmetHandler));
