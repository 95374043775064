import React from "react";
import { Icon } from "terra-component-lib";
import Fade from "react-reveal/Fade";

export const renderLinkTiles = (cards = []) => {
  return cards.map((card = {}, index) => {
    return (
      <Fade bottom distance="100px" delay={index * 200} key={index}>
        <a
          className="mep-link-tile"
          href={card.link && card.link.url ? card.link.url : "/#"}
          target={card.link.url.includes("http") ? "_blank" : "_self"}
        >
          <div className="mep-link-tile__content">
            <h4 className="mep-link-tile__header">{card.header}</h4>
            <p className="mep-link-tile__text">{card.text}</p>
          </div>
          <Icon type="open-caret-right-dark-16px" size="16px" />
        </a>
      </Fade>
    );
  });
};
