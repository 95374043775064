import React, { Component } from "react";
import "./DemoPage.scss";
import { Checkbox } from "terra-component-lib";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
} from "../../actions";
import stgURL from "../../utils/helperObjects/envURLs";
import Loading from "../../components/Loading/Loading";
import { demoLabelFetch } from "../../utils/async/demoFetch";
import { MarketoForm } from "@trimble-creative-strategy/luna-component-lib";
import ErrorPage from "../ErrorPage/ErrorPage";
import * as Sentry from "@sentry/browser";
import {
  renderInputs,
  countriesThatDontRequirePostalCode,
  germanMessageText,
  englishMessageText,
  frenchMessageText,
  dutchMessageText,
} from "./DemoFormMethods";
import loadingAnimation from "../../assets/images/loading-mini.gif";
import { removeHiddenFormAndScripts } from "./DemoFormMethods";

class DemoPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
      entries: [],
      warnings: [],
      optin: false,
      dropdowns: null,
      formReady: false,
      submitting: false,
      submissionError: false,
      submissionAgreementChecked: false,
      submissionAgreementWarning: false,
    };
    this.timeoutID = null;
  }

  componentDidMount() {
    // Sentry issue - ResizeObserver loop limit exceeded
    // From my research, this error is not something to worry about. ResizeObserver is a mechanism to avoid infinite callback loops. To remove the sentry error, this was recommended
    window.ResizeObserver = undefined;
    this.getData();
    const submittedForm = localStorage.getItem("submission");
    if (submittedForm === "true") {
      this.handleSuccess();
    }
    // Check to see if there are query strings in the pathname, and build the Marketo scripts or forward the user as necessary
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  componentWillUnmount() {
    // grabs all the generated marketo scripts and forms and removes them from the DOM so they don't just sit there
    removeHiddenFormAndScripts();
    this.clearSetTimeout();
  }

  clearSetTimeout = () => {
    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID);
    }
  };

  generateMarketoScript = () => {
    // This generates the Marketo script, appends it to the DOM, and calls the function that generates the cooresponding <form>
    const script = document.createElement("script");

    script.src = "//go.trimble.com/js/forms2/js/forms2.min.js";
    script.async = true;
    script.defer = true;
    script.type = "text/javascript";
    script.id = "mktoScript_big";
    document.body.appendChild(script);

    this.callScript();
  };

  postScript = () => {
    const script = document.createElement("script");
    script.id = "mktoForm_script_caller";
    script.text =
      'MktoForms2.loadForm("//go.trimble.com", "593-HFN-635", 5201);';
    document.body.appendChild(script);

    this.setState({ formReady: true });
    this.setSuccess();
  };

  callScript = () => {
    // Check to see if the script is ready - call a method on it if it is, or call this method again if it isn't after waiting .2 seconds

    if (window.MktoForms2 && window.MktoForms2.loadForm) {
      setTimeout(this.postScript, 3000);
    } else {
      setTimeout(this.callScript, 200);
    }
  };

  checkForUTMinURL = () => {
    const query =
      window && window.location && window.location.search
        ? window.location.search
        : "";

    let utmItems = {};

    if (query && query.includes("utm")) {
      const splitUrl = query.split("&");
      if (splitUrl && splitUrl.length) {
        for (let i in splitUrl) {
          if (splitUrl[i].includes("utm_medium")) {
            utmItems["utm_medium"] = this.formatUTMString(splitUrl[i]);
          }
          if (splitUrl[i].includes("utm_campaign")) {
            utmItems["utm_campaign"] = this.formatUTMString(splitUrl[i]);
          }
          if (splitUrl[i].includes("utm_source")) {
            utmItems["utm_source"] = this.formatUTMString(splitUrl[i]);
          }
        }
      }
    }
    return utmItems;
  };

  formatUTMString = (string) => {
    if (!string) {
      return null;
    }
    if (string.includes("=")) {
      return string.split("=")[1];
    }
  };

  renderTagManagerArgs = () => {
    let trade;
    let category;
    if (this.state.entries.length) {
      this.state.entries.forEach((entry) => {
        if (entry.machineName === "trade") {
          trade = entry.value;
        }
        if (entry.machineName === "product_category") {
          category = entry.value;
        }
      });
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      eventCategory: "formSubmission",
      eventAction:
        this.props.page === "contact" ? "contactRequest" : "demoRequest",
      eventLabel:
        trade && category ? category : trade && !category ? trade : null,
    });
  };

  checkRoute = (prevProps) => {
    // Check for pathname changes and fetch new data if necessary
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getURL = () => {
    // Generate the URL for the fetch request
    return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/demo-form-labels/$`;
  };

  getLanguage = () => {
    // Return the current language in Redux or 'en' if undefined
    if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  getTaxonomyUrl = () => {
    return `${stgURL}${this.getLanguage()}/api-v1/FetchTaxonomyByMachineName/marketo_mapping`;
  };

  getCountryURL = () => {
    return `${stgURL}${this.getLanguage()}/api-v1/FetchCountryList`;
  };

  getData = async () => {
    // Fetch data to build the page
    this.setLoading();
    const url = this.getURL();
    const taxonomyURL = this.getTaxonomyUrl();
    const countryURL = this.getCountryURL();
    try {
      const { data, dropdowns, countryData, metadata } = await demoLabelFetch(
        url,
        taxonomyURL,
        countryURL
      );

      if (metadata) {
        this.props.updateMetadata(metadata);
      }

      this.props.updateCTAOverride(null);

      this.setState({ content: data, dropdowns, countryData }, this.setTrade);

      // TODO DemoPage - Update the setInnerPageNav logic in DemoPage to use non-hard-coded results
      this.props.setInnerPageNav({
        pageTitle:
          this.props.page === "contact"
            ? data.utils.contact_header
            : data.utils.header,
        links: [],
      });
      this.setSuccess();
    } catch (error) {
      this.setError();
      console.log("error", error);
      this.props.setInnerPageNav({ pageTitle: "500", links: [] });
      Sentry.captureException(error);
    }

    this.generateMarketoScript();
  };

  setTrade = () => {
    // Grab the trade out of props and return if it's undefined
    const trade = this.props.trade;

    if (!trade) {
      this.setCategory();
      return;
    }

    // Find the trade array in inputs and return if it's undefined
    const tradeForm = this.state.content.inputs.find((input) => {
      return input.machine_name.toLowerCase() === "trade";
    });

    if (!tradeForm) {
      this.setCategory();

      return;
    }

    // Find the Trade string in the form that matches what's in the route
    const tradeToPreGen = tradeForm.options.find((option) => {
      return option.toLowerCase().includes(trade.toLowerCase());
    });

    // Set it to state if there's a match and set the category
    if (tradeToPreGen) {
      const tradeEntry = {
        machineName: tradeForm.machine_name,
        name: tradeForm.name,
        value: tradeToPreGen,
      };
      this.setCategory(tradeEntry);
    } else {
      this.setCategory();
    }
  };

  getAllCategories = () => {
    const { dropdowns = [] } = this.state;

    return dropdowns.reduce((accu, dropdown = {}) => {
      accu = [...accu, ...dropdown.categories];

      return accu;
    }, []);
  };

  setCategory = (tradeEntry) => {
    // Grab the category out of the route and return if it's undefined
    const category = this.props.match.params.categoryName;
    if (!category) {
      return;
    }

    const categoryOptions = this.getAllCategories() || [];

    // Reformat the string to remove dashes
    const formattedCategory = category
      .replace("bim-", "")
      .replace(/-/g, " ")
      .toLowerCase();

    // Find the category that matches the route
    const catToPreGen = categoryOptions.find((option) => {
      return option.name.replace("/", " ").toLowerCase() === formattedCategory;
    });

    // Set to state if found
    if (catToPreGen) {
      const categoryEntry = {
        name: "Product Category",
        value: catToPreGen.name,
        machineName: "product_category",
      };

      if (tradeEntry) {
        this.setState({ entries: [tradeEntry, categoryEntry] });
      } else {
        this.setState({ entries: [categoryEntry] });
      }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };

  handleChange = (e) => {
    // Update text inputs in state
    const newEntries = [...this.state.entries];
    const entry = newEntries.find((entry) => {
      return entry.name === e.target.name;
    });

    // The inputs are generated progromatically and are not hard-coded, and so they live inside an array
    if (entry) {
      entry.value = e.target.value;
      this.updateEntries(newEntries);
    } else {
      const input = this.state.content.inputs.find((input) => {
        return input.name === e.target.name;
      });
      this.updateEntries([
        ...this.state.entries,
        {
          name: e.target.name,
          value: e.target.value,
          machine_name: input.machine_name,
        },
      ]);
    }
  };

  updateEntries = (entries) => {
    // Takes in the new entries array and sets it into state
    this.setState({ entries: [...entries] });
  };

  handleSelect = (selection, name) => {
    const { content } = this.state;
    let allText;

    if (content && content.utils && content.utils.all_text) {
      allText = content.utils.all_text;
    } else {
      allText = null;
    }

    // Update the dropdown menus with new selections
    const newEntries = JSON.parse(JSON.stringify(this.state.entries));

    const entry = newEntries.find((entry) => {
      return entry.name === name;
    });

    // The dropdowns are generated progromatically and are not hard-coded, and so they live inside an array
    if (entry) {
      if (allText && selection !== allText) {
        const input = this.state.content.inputs.find((input) => {
          return input.name === name;
        });

        // Handle country selection change
        if (input.machine_name === "country") {
          entry.value = selection;

          const filteredEntries = newEntries.filter((entry) => {
            return entry.machineName !== "state";
          });

          this.updateEntries(filteredEntries);
        } else {
          // Handle non-country selection changes
          entry.value = selection;
          this.updateEntries(newEntries);
        }
        // Handle changes for the default 'no-selection'/'All'
      } else if (allText && selection === allText) {
        const filteredEntries = newEntries.filter((selection) => {
          return selection.value !== entry.value;
        });
        this.updateEntries(filteredEntries);
      } else {
        entry.value = selection;
        this.updateEntries(newEntries);
      }
    } else if (allText === selection) {
      return;
    } else {
      const input = this.state.content.inputs.find((input) => {
        return input.name === name;
      });

      this.updateEntries([
        ...this.state.entries,
        { name, value: selection, machineName: input.machine_name },
      ]);
    }
  };

  checkForForm = () => {
    if (this.state.submitting) {
      return;
    }
    this.handleSubmit();
  };

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleSubmit = () => {
    // Handles the submission logic

    // Check to see if any required inputs/dropdowns are missing input/selection
    const warnings = this.state.content.inputs.reduce((accu, input) => {
      if (input.required) {
        const match = this.state.entries.find((entry) => {
          return entry.name === input.name;
        });

        if (input.machineName === "state") {
          const countrySelection = this.state.entries.find((entry) => {
            return entry.machineName === "country";
          });

          if (!countrySelection) {
            return accu;
          }

          const countryData = this.state.countryData.find((country) => {
            return country.name === countrySelection.value;
          });

          if (!countryData || !countryData.states) {
            return accu;
          }
        }

        if (input.machineName === "email_address" && match) {
          const validatedEmail = this.validateEmail(match.value);
          if (!validatedEmail) {
            accu.push(input.name);
          }
        }
        if (!match) {
          if (
            input.machine_name.toLowerCase() === "postal_code" &&
            this.checkForPostalCodeCountrySelectionRequirement()
          ) {
            return accu;
          } else {
            accu.push(input.name);
          }
        }
      }
      return accu;
    }, []);

    // Pass references to required inputs with missing fields into state and return
    if (warnings.length > 0) {
      this.updateWarnings(warnings);

      return;
    }

    this.clearWarnings();

    this.proceedToSubmit();
  };

  clearWarnings = () => {
    this.setState({ warnings: [] });
  };

  checkForPostalCodeCountrySelectionRequirement = () => {
    const countryEntry = this.state.entries.find(
      (entry) => entry.machineName === "country"
    );

    if (countryEntry) {
      const { value } = countryEntry;

      const countries = this.state.countryData || [];

      const countryData = countries.find((country) => country.name === value);

      if (countryData) {
        const { isoCode } = countryData;
        if (
          isoCode &&
          countriesThatDontRequirePostalCode.includes(isoCode.toLowerCase())
        ) {
          return true;
        }
      }
    }

    return false;
  };

  updateWarnings = (warnings) => {
    // Set required forms with missing data into state to warn the user when they have left the field empty
    this.setState({ warnings });
  };

  proceedToSubmit = () => {
    const urlParams = this.checkForUTMinURL();
    const payload = this.generateFormDataObject(urlParams);
    this.renderTagManagerArgs();

    this.submitMarketoForm();
  };

  submitMarketoForm = () => {
    const submitBtn = document.querySelector(".mktoButton");

    if (submitBtn) {
      // submitBtn.onClick = (e) => this.submitForm(e);
      submitBtn.click();
      localStorage.setItem("submission", true);
      this.setSubmitting(true);
    }
    // Marketo Javascript API for submission - for reference https://developers.marketo.com/javascript-api/forms/api-reference/
    window.MktoForms2.whenReady((form) => {
      form.onSubmit(() => {
        var vals = form.vals();
      });
    });
  };

  submitForm = (e) => {
    e.preventDefault();

    window.MktoForms2.whenReady((form) => {
      form.submit();
    });
  };

  handleError = (type, message) => {
    if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      const error = {
        statusCode: type,
        message,
      };
      Sentry.captureException(error);
    }
    this.setSubmissionError();
    console.log(type, message);
    // This is necessary for error tracking
  };

  handleSuccess = () => {
    const location = this.props.location.pathname;
    if (location.slice(-1) === "/") {
      this.props.history.push(`${location}submitted`);
    } else {
      this.props.history.push(`${location}/submitted`);
    }
  };

  generateFormDataObject = (utm) => {
    // this populates the actual Marketo form that we have hidden
    if (document.getElementById("FirstName"))
      document.getElementById("FirstName").value = this.getDataPoint(
        "first_name"
      );

    if (document.getElementById("LastName"))
      document.getElementById("LastName").value = this.getDataPoint(
        "last_name"
      );

    if (document.getElementById("Company"))
      document.getElementById("Company").value = this.getDataPoint(
        "company_name"
      );

    if (document.getElementById("Email"))
      document.getElementById("Email").value = this.getDataPoint(
        "email_address"
      );

    if (document.getElementById("State"))
      document.getElementById("State").value = this.getDataPoint("state");

    if (document.getElementById("Country"))
      document.getElementById("Country").value = this.getDataPoint("country");

    if (document.getElementById("PostalCode"))
      document.getElementById("PostalCode").value = this.getDataPoint(
        "postal_code"
      );

    if (document.getElementById("Phone"))
      document.getElementById("Phone").value = this.getDataPoint(
        "phone_number"
      );

    if (document.getElementById("Marketing_Sub_industry__c"))
      document.getElementById(
        "Marketing_Sub_industry__c"
      ).value = this.getTradeValue();

    if (document.getElementById("Product_Category__c"))
      document.getElementById(
        "Product_Category__c"
      ).value = this.getCategoryValue();

    if (document.getElementById("lastTouchutmcampaign"))
      document.getElementById("lastTouchutmcampaign").value =
        utm && utm.utm_campaign ? utm.utm_campaign : "";

    if (document.getElementById("lastTouchutmcontent"))
      document.getElementById("lastTouchutmcontent").value = "";

    if (document.getElementById("lastTouchutmmedium"))
      document.getElementById("lastTouchutmmedium").value =
        utm && utm.utm_medium ? utm.utm_medium : "";

    if (document.getElementById("lastTouchutmsource"))
      document.getElementById("lastTouchutmsource").value =
        utm && utm.utm_source ? utm.utm_source : "";

    if (document.getElementById("lastTouchutmterm"))
      document.getElementById("lastTouchutmterm").value = "";

    if (document.getElementById("lastTouchutmmedium"))
      document.getElementById("lastTouchutmmedium").value =
        utm && utm.utm_medium ? utm.utm_medium : "";

    if (document.getElementById("lastTouchutmmedium"))
      document.getElementById("lastTouchutmmedium").value =
        utm && utm.utm_medium ? utm.utm_medium : "";

    // update this when new form is ready to be used.
    if (document.getElementById("expressOptInForm"))
      document.getElementById("expressOptInForm").checked =
        this.getOptIn() === "yes" ? true : false;
  };

  getOptIn = () => {
    if (this.props.language === "de" || this.props.language === "fr") {
      return "yes";
    } else {
      if (this.state.submissionAgreementChecked) {
        return "yes";
      } else {
        return "no";
      }
    }
  };

  getDataPoint = (point) => {
    const data = this.state.entries.find((entry) => {
      return entry.machineName === point || entry.machine_name === point;
    });
    if (data) {
      return data.value;
    } else {
      return null;
    }
  };

  getCategoryValue = () => {
    // BIM is weird and not labeled properly in Drupal
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.categoryName === "bim-collaboration"
    ) {
      return "BIM Collaboration";
    }

    const data = this.state.entries.find((entry) => {
      return (
        entry.machineName === "product_category" ||
        entry.machine_name === "product_category"
      );
    });

    const allCategories = this.getAllCategories();

    const match = allCategories.find(
      (category) => category.name.toLowerCase() === data.value.toLowerCase()
    );

    if (match) {
      return match.machineName;
    }
  };

  getTradeValue = () => {
    const data = this.state.entries.find((entry) => {
      return entry.machineName === "trade" || entry.machine_name === "trade";
    });

    if (data) {
      const dropdownData = this.state.dropdowns.find((dropdown) => {
        return dropdown.name === data.value;
      });

      if (dropdownData) {
        return dropdownData.machineName;
      }
    }
  };

  filterDropdownsByRegion = () => {
    const dropdowns = JSON.parse(JSON.stringify(this.state.dropdowns));
    return dropdowns
      .map((trade = {}) => {
        if (trade.categories) {
          trade.categories = trade.categories.map((category = {}) => {
            if (category.products) {
              category.products = category.products.filter((product = {}) => {
                return (
                  product.regions &&
                  product.regions.reduce((acc, region = {}) => {
                    if (region.name === this.props.region) {
                      acc = true;
                    }
                    return acc;
                  }, false)
                );
              });
            }

            return category;
          });
        }
        return trade;
      })
      .map((trade = {}) => {
        if (trade.categories) {
          trade.categories = trade.categories.filter((category = {}) => {
            return category.products && category.products.length > 0;
          });
        }

        return trade;
      })
      .filter((trade = {}) => {
        return trade.categories && trade.categories.length > 0;
      });
  };

  getWarningText = () => {
    const { content } = this.state;
    if (content && content.utils && content.utils.warning_text) {
      return content.utils.warning_text;
    } else {
      return "There are required fields that are incomplete.";
    }
  };

  getChildren = () => {
    // old form logic here, remove when form 8456 is implemented -->
    const { region, language } = this.props;
    if (language === "en" || language.toLowerCase() === "en-gb") {
      return englishMessageText();
    } else if (language === "fr") {
      return frenchMessageText();
    } else if (language === "de") {
      return germanMessageText();
    } else if (language === "nl") {
      return dutchMessageText();
    } else {
      return "";
    }
    // new form logic --> (commented out for now)
    // return (
    //   <Checkbox
    //     label={this.state.content.utils.extra_text_2}
    //     name="additional-submission-agreement"
    //     checked={this.state.additionalSubmissionAgreementChecked || false}
    //     handleChange={(e) => this.handleAdditionalSubmissionAgreementChange(e)}
    //     required={false}
    //   />
    // );
  };

  generateForm = () => {
    // Generate the Marketo form
    // Does this need to be a method? Why is this a method?
    return (
      <MarketoForm
        inputs={renderInputs(
          this.state.content,
          this.filterDropdownsByRegion(),
          this.state.entries,
          this.state.countryData
        )}
        handleSelect={this.handleSelect}
        handleChange={this.handleChange}
        handleSubmit={this.checkForForm}
        entries={this.state.entries}
        warnings={this.state.warnings}
        submitButtonText={this.state.content.utils.submit || "Submit"}
        warningText={this.getWarningText()}
        submissionAgreementTextRequired={false}
        submissionAgreementText={this.state.content.utils.opt_in_label}
        // keeping this commented out for when we switch the form to 8456
        // submissionAgreementText={this.state.content.utils.extra_text_1}

        submissionAgreementChecked={this.state.submissionAgreementChecked}
        // comment back in when switching to new form id
        // handleSubmissionAgreementChange={
        //   this.state.content.utils.extra_text_1
        //     ? this.handleSubmissionAgreementChange
        //     : undefined
        // }

        // remove when switching to new form id
        handleSubmissionAgreementChange={
          this.getAgreementText()
            ? this.handleSubmissionAgreementChange
            : undefined
        }
        submissionAgreementWarning={this.state.submissionAgreementWarning}
      >
        {this.getChildren()}
      </MarketoForm>
    );
  };

  getAgreementText = () => {
    const { language } = this.props;

    if (language === "en" || language.toLowerCase() === "en-gb") {
      if (
        this.state.content &&
        this.state.content.utils &&
        this.state.content.utils.opt_in_label
      ) {
        return `${this.state.content.utils.opt_in_label} `;
      } else {
        return "";
      }
    } else if (language === "de") {
      return germanMessageText();
    } else if (language === "fr") {
      return frenchMessageText();
    } else {
      if (
        this.state.content &&
        this.state.content.utils &&
        this.state.content.utils.opt_in_label
      ) {
        return this.state.content.utils.opt_in_label;
      } else {
        return undefined;
      }
    }
  };

  handleAdditionalSubmissionAgreementChange = (e) => {
    this.setState({
      additionalSubmissionAgreementChecked: e.target.checked,
      additionalSubmissionAgreementWarning: false,
    });
  };

  handleSubmissionAgreementChange = (e) => {
    this.setState({
      submissionAgreementChecked: e.target.checked,
      submissionAgreementWarning: false,
    });
  };

  setSubmitting = () => {
    this.setState({ submitting: true });
  };

  setSubmissionError = () => {
    this.setState({
      submissionError: true,
      submitting: false,
    });
  };

  render() {
    const { content } = this.state;
    const { status, navbarReady } = this.props;
    return (
      <main
        className={`mep-demo-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-demo-page--loading"}`}
      >
        {!this.state.formReady && status === "success" && navbarReady && (
          <div className="mep-demo-page__loading">
            <img
              className="mep-demo-page__submitting-img"
              src={loadingAnimation}
              alt="loading"
            />
          </div>
        )}
        {content &&
          status === "success" &&
          this.state.formReady &&
          navbarReady &&
          !this.state.submissionError && (
            <>
              {content.utils &&
                content.utils.header &&
                this.props.page !== "contact" && (
                  <h3 className="mep-demo-page__header">
                    {content.utils.header}
                  </h3>
                )}
              {!this.state.submitting &&
                content.utils &&
                content.utils.contact_header &&
                this.props.page === "contact" && (
                  <h3 className="mep-demo-page__header">
                    {content.utils.contact_header}
                  </h3>
                )}

              {!this.state.submitting &&
                content.utils &&
                content.utils.extra_text_3 && (
                  <p className="mep-demo-page__subText">
                    {content.utils.extra_text_3}
                  </p>
                )}

              {!this.state.submitting && this.generateForm()}
              {this.state.submitting && (
                <div className="mep-demo-page__submitting-img-wrapper">
                  <img
                    className="mep-demo-page__submitting-img"
                    src={loadingAnimation}
                    alt="loading"
                  />
                </div>
              )}
            </>
          )}
        <form
          className="mep-demo-page__mkto-form"
          id="mktoForm_5201"
          onSubmit={(e) => {
            e.preventDefault();

            this.handleSubmit();
          }}
        />
        {(status === "loading" || (!navbarReady && status !== "error")) && (
          <Loading />
        )}
        {(status === "error" || this.state.submissionError) && <ErrorPage />}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  navbarReady: state.navbarReady,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (data) => dispatch(updateMetadata(data)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoPage);
