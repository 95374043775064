import basicFetch from "../async/basicFetch";
import stgURL from "../../utils/helperObjects/envURLs";
import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
import linkedin from "../../assets/images/linkedin.svg";
import youtube from "../../assets/images/youtube.svg";
import instagram from "../../assets/images/instagram.svg";

const icons = {
  facebook: facebook,
  twitter: twitter,
  linkedin: linkedin,
  youtube: youtube,
  instagram: instagram,
};

const footerFetch = async (lang, regionFooterURL) => {
  const url = `${stgURL}${lang}/api-v1/FetchFooterByMachineName/${regionFooterURL}`;

  const response = await basicFetch(url);
  const data = cleanFooterData(response.data);

  return data;
};

export const cleanFooterData = (data) => {
  const socialLinks = cleanSocialLinks(data.social);
  return {
    logo: { url: data.logo, altText: "Trimble logo" },
    socialLinks,
    linksOne: data.link_list_1,
    linksTwo: data.link_list_2,
    trimbleText: data.trimble_specific_text,
    trimbleLink: data.trimble_specific_link,
    siteLink: data.site_specific_link,
    siteText: data.site_specific_text,
    legalStuff: cleanLegalLinks(data.trimble_bar),
  };
};

export const cleanSocialLinks = (uncleanedLinks) => {
  if (!uncleanedLinks) {
    return [];
  }

  const links = uncleanedLinks || {};

  const keys = Object.keys(links);

  const socialLinks = keys.reduce((accu, key) => {
    const socialLink = {
      icon: { altText: `${key} icon`, url: icons[key.toLowerCase()] },
      link: { url: links[key] },
    };

    accu.push(socialLink);
    return accu;
  }, []);

  return socialLinks.filter((link) => {
    return link.link.url.length > 0;
  });
};

export const cleanLegalLinks = (legalLinks) => {
  const links = legalLinks || [];
  return links.map((link) => {
    const linkObject = {};
    linkObject.text = link.text;

    if (link.url === "<nolink>") {
      return linkObject;
    } else {
      linkObject.url = link.url;
      return linkObject;
    }
  });
};

export default footerFetch;
