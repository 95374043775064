import React, { Component } from "react";
import "./AdminHomePage.scss";
import { Button } from "terra-component-lib";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata
} from "../../actions";

class AdminHomePage extends Component {
  componentDidMount() {
    this.kickOff();
  }

  kickOff = () => {
    this.props.setStatus("success");
    this.props.setInnerPageNav({ pageTitle: "Admin Home", links: [] });
  };

  generateLinks = () => {
    const language = this.getLanguage();

    const links = [
      {
        url: `/${language}/admin/products`,
        text: "Products",
        className: "ter-button--primary--1"
      },
      {
        url: `/${language}/admin/trades`,
        text: "Trades",
        className: "ter-button--primary--1"
      },
      {
        url: `/${language}/admin/categories`,
        text: "Categories",
        className: "ter-button--primary--1"
      }
    ];

    return links.map(link => {
      const { text, url, className } = link;
      return <Button text={text} url={url} className={className} />;
    });
  };

  getLanguage = () => {
    return `${this.props.language || "en"}`;
  };

  render() {
    return (
      <div className="mep-admin-home-page">
        <h2>Admin Home</h2>
        <p>
          This page is for Trimble MEP employee use for navigating through page
          content without having to worry about region or language blocking.
        </p>
        <p>
          The links below will take you to pages that contain links for all
          corresponding content types including all available translations.
        </p>
        <div className="mep-admin-home-page__links">{this.generateLinks()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  status: state.status,
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  setStatus: status => dispatch(updateStatus(status)),
  setInnerPageNav: data => dispatch(updateInnerPageNav(data)),
  setMetadata: metadata => dispatch(updateMetadata(metadata))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHomePage);
