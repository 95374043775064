import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
} from "../../actions";
import "./SuccessPage.scss";
import * as Sentry from "@sentry/browser";
import stgURL from "../../utils/helperObjects/envURLs";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import successFetch from "../../utils/async/successFetch";
import { ButtonLink, Button } from "terra-component-lib";

class SuccessPage extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    localStorage.removeItem("submission");
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    // Check for pathname changes and fetch new data if necessary
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getLanguage = () => {
    if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  getURL = () => {
    return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/contact-page-success/$#`;
  };

  getData = async () => {
    const url = this.getURL();
    this.setLoading();

    try {
      const { content } = await successFetch(url);
      this.props.setInnerPageNav({ pageTitle: "", links: [] });
      this.props.updateMetadata({});
      this.props.updateCTAOverride(null);
      this.setState({ content });
      this.setSuccess();
    } catch (error) {
      console.log(error);
      this.setError();
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  renderCtas = () => {
    const { content = {} } = this.state;
    if (!content.ctas) {
      return null;
    }
    const trade = this.props.trade;
    const category =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.categoryName
        ? this.props.match.params.categoryName
        : null;
    const product =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.productName
        ? this.props.match.params.productName
        : null;

    if (trade && category && product) {
      const ctas = {
        ctaOne:
          content.ctas && content.ctas.ctaOne
            ? {
                url: content.ctas.ctaOne.url,
                text: content.ctas.ctaOne.text,
              }
            : null,
        ctaTwo:
          content.ctas && content.ctas.ctaTwo
            ? {
                onClick: () =>
                  this.props.history.push(
                    `/${this.getLanguage()}/${trade}-solutions/${category}/${product}`
                  ),
                text: "Go Back",
              }
            : null,
      };
      return (
        <div className="button-success-page--button-container">
          {ctas && ctas.ctaOne && ctas.ctaOne.url && ctas.ctaOne.text && (
            <ButtonLink
              url={ctas.ctaOne.url}
              text={ctas.ctaOne.text}
              className="ter-button--primary--1 ter-button--success-page--1"
            />
          )}
          {ctas && ctas.ctaTwo && ctas.ctaTwo.onClick && ctas.ctaTwo.text && (
            <Button
              onClick={
                ctas.ctaTwo && ctas.ctaTwo.onClick ? ctas.ctaTwo.onClick : null
              }
              text={ctas.ctaTwo.text}
              className="ter-button--secondary--1 ter-button--success-page--2"
            />
          )}
        </div>
      );
    } else {
      const cta =
        content.ctas && content.ctas.ctaOne
          ? {
              url: content.ctas.ctaOne.url,
              text: content.ctas.ctaOne.text,
            }
          : null;

      return (
        <ButtonLink
          url={cta.url}
          text={cta.text}
          className="ter-button--primary--1 ter-button--success-page"
        />
      );
    }
  };

  render() {
    const { status, navbarReady } = this.props;
    const { content = {} } = this.state;
    return (
      <main
        className={`mep-success-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-success-page--loading"}`}
      >
        {" "}
        {(status === "loading" || (!navbarReady && status !== "error")) && (
          <Loading />
        )}
        {status === "error" && <ErrorPage />}
        {status === "success" && content && navbarReady && (
          <div className="mep-success-page__container">
            <div className="mep-success-page__header-container">
              {content && content.header && (
                <h1 className="mep-success-page__header">{content.header}</h1>
              )}
              {content && content.subHeader && (
                <h3 className="mep-success-page__subHeader">
                  {content.subHeader}
                </h3>
              )}
            </div>
            {content && content.ctas && this.renderCtas()}
          </div>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  navbarReady: state.navbarReady,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (metadata) => dispatch(updateMetadata(metadata)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPage);
