let stgURL;

if (process.env.REACT_APP_DOMAIN === "development") {
  stgURL = "https://stg-mep.trimble.com/";
} else if (process.env.REACT_APP_DOMAIN === "staging") {
  stgURL = "https://stg-mep.trimble.com/";
} else if (process.env.REACT_APP_DOMAIN === "production") {
  stgURL = "https://back-mep.trimble.com/";
}

export default stgURL;
