import React from "react";
import "./Loading.scss";
import loadingAnimation from "../../assets/images/loading-mini.gif";

const Loading = () => {
  return (
    <main className="mep-loading-page">
      <img
        src={loadingAnimation}
        alt="loading animation three boxes"
        className="mep-loading-page__gif"
      />
    </main>
  );
};

export default Loading;
