import React from "react";

const addSpace = (regions, index) => {
  if (index === 0 && regions.length > 1) {
    return true;
  } else if (index !== 0 && index !== regions.length - 1) {
    return true;
  } else {
    return false;
  }
};

export const renderRegions = (regions = []) => {
  return (
    <ul className="mep-admin-product-data__region-list">
      {regions.map((region, index) => {
        const space = addSpace(regions, index);
        return (
          <li
            className={`mep-admin-product-data__region-list-item ${
              space
                ? "mep-admin-product-data__region-list-item--add-space"
                : "mep-admin-product-data__region-list-item--default"
            }`}
            key={region}
          >
            {region}
          </li>
        );
      })}
    </ul>
  );
};
