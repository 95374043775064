import basicFetch from "./basicFetch";
import { format404Data } from "../methods/contentSectionFormat";

export default async (url) => {
  const response = await basicFetch(url);
  const data =
    response.data &&
    response.data[0] &&
    response.data[0].content_sections &&
    response.data[0].content_sections["404_hero"]
      ? format404Data(response.data[0].content_sections["404_hero"])
      : null;

  return {
    content: [data],
    metadata:
      response.data && response.data[0] && response.data[0].metatags
        ? response.data[0].metatags
        : null,
  };
};
