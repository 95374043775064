import React, { Component } from "react";
import { Footer } from "@trimble-creative-strategy/luna-component-lib";
import { connect } from "react-redux";
import footerFetch from "../../utils/async/footerFetch";
import fallbackLogo from "./trimble_new_footer_logo.png";
class FooterHandler extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.region !== this.props.region) {
      this.getData();
    }
  }

  getData = async () => {
    const { region, regions, language } = this.props;

    const regionObject = regions.find((reg) => reg.name === region);

    if (regionObject && regionObject.footer_name) {
      try {
        const content = await footerFetch(language, regionObject.footer_name);
        if (content) {
          this.setState({ content });
        }
      } catch (e) {
        console.log(e);
        this.setState({ content: null });
      }
    }
  };

  getLogo = () => {
    const { logo } = this.state.content;

    if (!logo || !logo.url || !logo.altText) {
      return {
        url: fallbackLogo,
        altText: "Trimble Logo",
      };
    } else {
      return this.state.content.logo;
    }
  };

  render() {
    const { content } = this.state;
    if (content) {
      return (
        <Footer
          trimbleText={content.trimbleText}
          trimbleLink={content.trimbleLink}
          siteLink={content.siteLink}
          siteText={content.siteText}
          linksOne={content.linksOne}
          linksTwo={content.linksTwo}
          logo={this.getLogo()}
          socialLinks={content.socialLinks}
          legalStuff={content.legalStuff}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
});

export default connect(mapStateToProps, null)(FooterHandler);
