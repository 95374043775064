import React, { Component } from "react";
import { connect } from "react-redux";
import { NavBar } from "@trimble-creative-strategy/luna-component-lib";
import Routing from "../Routing/Routing";
import HelmetHandler from "../HelmetHandler/HelmetHandler";
import { withRouter } from "react-router-dom";
import {
  updateLanguage,
  updateStatus,
  updateRegion,
  updateAlert,
  updateNavbarReady,
  updateLearnMoreLabel,
  updateErrorStatus,
} from "../../actions";
import fetchRegionsAndLanguages, {
  getLocation,
  generalConfigFetch,
  generateRegionAndLanguages,
  menuFetch,
} from "../../utils/async/generalConfigFetch";
import checkRegion from "../../utils/methods/checkRegion";
import { logo } from "../../utils/mockData/navBarMockData";
import * as Sentry from "@sentry/browser";
import { Alert } from "terra-component-lib";
import {
  handleGetItem,
  handleSetItem,
  handleRemoveItem,
} from "../../utils/localStorage/localStorageHandler";
import fetchRoutesData from "../../utils/async/fetchRoutesData";
import "./App.scss";
import FooterHandler from "../FooterHandler/FooterHandler";

export class App extends Component {
  constructor() {
    super();

    this.state = {
      rightLinks: null,
      sitewideLanguages: null,
      sitewideRegions: null,
      isLoading: true,
      // Can ^ be folded into 'status' in Redux?
      menus: null,
      countryCode: null,
      generalConfig: null,
      innerPageNav: null,
      navCTA: null,
      divisionName: null,
      searchResults: null,
      searchTerm: "",
      currentRegion: null,
      regionLanguageDeployed: false,
      country: null,
      routesData: [],
      langLabel: null,
      regionLabel: null,
    };
  }

  componentDidMount() {
    this.clearRegionBlock();
    this.clearFourOhFourBlock();
    this.kickOff();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.language) {
      this.getLearnMoreLabel();
    }
  }

  checkForLanguageAndRegion = () => {
    if (localStorage.getItem("langAndRegionSet") === null) {
      this.setState({ regionLanguageDeployed: true });
    } else {
      return;
    }
  };

  kickOff = async () => {
    this.checkForLanguageAndRegion();
    const language = this.props.language ? this.props.language : "en";
    try {
      const routesData = await fetchRoutesData(language);
      this.setState({ routesData });
    } catch (e) {
      console.log(e);
    }
  };

  setDefaultDropdownLabels = () => {
    // if there is a region in localStorage saved, we want this to reflect in the dropdowns for Region
    if (localStorage.getItem("MEPRegionPref")) {
      this.setState({
        regionLabel: JSON.parse(localStorage.getItem("MEPRegionPref")),
      });
    }

    // if there is a language stored in localStorage, we want this to reflect in the dropdowns for Language BUT we have to format it properly bc languages come back shortened ("en", "es", "de")
    if (localStorage.getItem("MEPLangPref") && this.state.sitewideLanguages) {
      const currentLang = localStorage.getItem("MEPLangPref");

      // we find our current language, this will be an object

      const currentLanguageData = this.state.sitewideLanguages.find((lang) => {
        return lang.id === JSON.parse(currentLang);
      });

      // we check that the object has the keys we need to format the language displayed in the dropdown, and if it does, we save it to state.
      if (
        currentLanguageData &&
        currentLanguageData.id &&
        currentLanguageData.name
      ) {
        this.setState({
          langLabel: `${currentLanguageData.id.toUpperCase()}: ${
            currentLanguageData.name
          }`,
        });
      }
    }
  };

  setPrerender = (bool) => {
    window.prerenderReady = bool;
  };

  clearRegionBlock = () => {
    // Removes Region blocking content from local storage
    const data = handleGetItem("regionBlock");

    if (data) {
      handleRemoveItem("regionBlock");
    }
  };

  clearFourOhFourBlock = () => {
    // Removes the 404 content from local storage
    const data = handleGetItem("fourOhFourData");

    if (data) {
      handleRemoveItem("fourOhFourData");
    }
  };

  postLanguageSet = async (language, setFromNav) => {
    // fires after the initial pageload sets the language via componentDidMount in <Routing />
    await this.setGeneralConfig(
      language || this.props.language,
      language ? true : false
    );
    await this.getCountryLanguages();
    await this.setDefaultDropdownLabels();
    await this.getLocation(setFromNav);
  };

  searchSubmit = async (string) => {
    // handles search submission from the navbar. The <SearchResultsPage> takes the search parameters out of the route and uses them to query Drupal for results.
    // TODO App - Remove this return once Search is working, pls

    let formattedString = string.replace(" ", "%20");
    this.props.history.push(
      `/${this.props.language || "en"}/search-results/${formattedString}`
    );
  };

  getCountryLanguages = async () => {
    // let countriesLanguagesCache = localStorage.getItem('country_setting');
    let countriesLanguagesCache = false;
    if (countriesLanguagesCache) {
      // this.setState({ countryCode: JSON.parse(countryCache) });
    } else {
      try {
        // Lx7Oi3ihnOKR38Z is the API key for Trimble MEP
        const regionsAndLanguages = await fetchRegionsAndLanguages(
          this.props.language
        );

        this.setState({
          sitewideRegions: regionsAndLanguages.regions,
          sitewideLanguages: regionsAndLanguages.languages,
        });
      } catch (error) {
        console.log(error);
        if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
          Sentry.captureException(error);
        }
      }
    }
  };

  getLocation = async (setFromNav, langPref) => {
    // This code is a mess - KS 3/24/20
    // Pretty sure that the country code does not need to go into state, a lot of this code is vestigial
    const regionPref = handleGetItem("MEPRegionPref");
    // This is supposed use the above code to leverage localStorage for auto-setting the region, but it's not doing that for some reason? Not sure what heppened here, Brandon wrote it.

    if (regionPref) {
      this.props.updateRegion(regionPref);
      if (!setFromNav) {
        this.setMenus(
          this.props.language,
          this.state.generalConfig,
          regionPref
        );
      }
      try {
        const locationResponse = await getLocation();

        if (
          locationResponse &&
          locationResponse.data &&
          locationResponse.data.countryCode
        ) {
          this.setState({ country: locationResponse.data.countryCode });
        }
      } catch (error) {
        console.log(error);
        if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
          Sentry.captureException(error);
        }
      }
    } else {
      try {
        // Lx7Oi3ihnOKR38Z is the API key for Trimble MEP
        const locationResponse = await getLocation();

        // var elements = adjustKeyNames(locationResponse.data);

        if (
          locationResponse &&
          locationResponse.data &&
          locationResponse.data.status === "success" &&
          locationResponse.data.countryCode
        ) {
          handleSetItem("country_setting", locationResponse.data.countryCode);

          const currentRegion = checkRegion(
            locationResponse.data.countryCode,
            this.state.sitewideRegions
          );

          if (currentRegion) {
            this.setRegionToLocalStorage(currentRegion);
          }

          if (this.state.sitewideRegions && locationResponse.data.countryCode) {
            if (this.props.region) {
              this.setState({ country: locationResponse.data.countryCode });
              return;
            }

            const currentRegion = checkRegion(
              locationResponse.data.countryCode,
              this.state.sitewideRegions
            );
            this.setState({
              countryCode: locationResponse.data.countryCode,
            });
            if (currentRegion) {
              this.props.updateRegion(currentRegion);
              this.setRegionToLocalStorage(currentRegion);
              this.setMenus(
                this.props.language,
                this.state.generalConfig,
                currentRegion
              );
            }
          } else {
            this.setState({
              countryCode: locationResponse.data.countryCode,
            });
            this.props.updateRegion("North America");
            this.setRegionToLocalStorage("North America");
            this.setMenus(
              this.props.language,
              this.state.generalConfig,
              "North America"
            );
          }
        } else {
          this.props.updateRegion("North America");
          this.setRegionToLocalStorage("North America");
          this.setMenus(
            this.props.language,
            this.state.generalConfig,
            "North America"
          );
        }
      } catch (error) {
        console.log(error);
        if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
          Sentry.captureException(error);
        }
      }
    }
  };

  setGeneralConfig = async (language, languageSwitch) => {
    const lsLang = handleGetItem("MEPLangPref");

    try {
      const elements = await generalConfigFetch(lsLang || language);
      // Not all translations are completed in Smartling, so the following fields are currently undefined as of 6/15/21: region_label, language_label, switcher_help_text, region_save_btn, region_cancel_btn, and current_region_label. Due to this, the NavBar will not render on languages that do not have the translations. This defaults these labels to english so that the NavBar will still render.

      if (!elements?.[0]?.settings?.region_label) {
        elements[0].settings.region_label = "Region";
      }

      if (!elements?.[0]?.settings?.language_label) {
        elements[0].settings.language_label = "Language";
      }

      if (!elements?.[0]?.settings?.switcher_help_text) {
        elements[0].settings.switcher_help_text =
          "If needed, select or change your preferred region and language";
      }
      if (!elements?.[0]?.settings?.region_save_btn) {
        elements[0].settings.region_save_btn = "Save";
      }

      if (!elements?.[0]?.settings?.region_cancel_btn) {
        elements[0].settings.region_cancel_btn = "Dismiss";
      }

      if (!elements?.[0]?.settings?.current_region_label) {
        elements[0].settings.current_region_label =
          "Your Current Default Region and Language";
      }

      if (languageSwitch) {
        await this.setMenus(language, elements, null);
      } else {
        await this.setMenus(language, elements, null);
      }

      if (
        elements &&
        elements[0] &&
        elements[0].settings &&
        elements[0].settings.card_cta_text
      ) {
        this.props.updateLearnMoreLabel(elements[0].settings.card_cta_text);
      }

      this.setState({
        generalConfig: elements,
        rightLinks: [elements[0].settings.top_navigation_link],
        navCTA: elements[0].nav_cta_override,
        divisionName: elements[0].settings.site_division_name,
      });
    } catch (error) {
      console.log(error);
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
    }
  };

  setMenus = async (language, generalConfig, newRegion) => {
    // path will be "en/" if homepage is first to load.
    // if any other page is first to load, path will be, for example, "en/bim-services" (language/whatever-page-you-are-looking-at).
    // because I was previously checking language based on path alone, I did not take into account that a user may reach this site on a different path than homepage, and if this is the case, "language" is suddenly "en/bim-services", which was then put into the fetch call, causing nav and footer to return 404.
    // below puts the path into an array which looks like ["", "en", "bim-services"] if on a page other than homepage and ["", "en"] if user is on homepage. Regardless of the route, the 1st indice will be the language.

    this.setState({ isLoading: true });
    this.props.updateNavbarReady(false);

    if (
      !this.state.sitewideRegions ||
      !this.props.region ||
      !this.props.language
    ) {
      return;
    }

    const path = this.props.location.pathname.split("/");

    if (!language) {
      language = path[1];
    }

    if (language && language !== path[1]) {
      language = path[1];
    }

    /* Main Menu */
    // let mainMenuCache = localStorage.getItem("main_menu");

    try {
      const response = await menuFetch(
        language || this.props.language,
        generalConfig,
        newRegion || this.props.region,
        this.state.sitewideRegions
      );

      this.setState({
        menus: response.menus,
        isLoading: false,
      });
      this.props.updateNavbarReady(true);
    } catch (error) {
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
      console.log(error);
    }

    return;
  };

  setLanguageFromNav = (region) => {
    localStorage.setItem("langAndRegionSet", true);
    this.closeModal();

    const language = this.state.sitewideLanguages.find((lang) => {
      return region.languageSelected.split(": ")[1] === lang.name;
    });

    this.setState({ langLabel: region.languageSelected });
    this.setState({ regionLabel: region.regionSelected });

    // additional check of region.region to make sure region.region is valid and is not a falsy value
    if (this.props.region !== region.regionSelected && region.regionSelected) {
      this.props.updateRegion(region.regionSelected);
      this.setRegionToLocalStorage(region.regionSelected);

      if (this.props.language === language.id) {
        this.setMenus(
          this.props.language,
          this.state.generalConfig,
          region.regionSelected
        );
      }
    }

    // TODO App - remove this once Drupal side is set.
    // handles language changes that result from using the <LanguageSwitcher> in the <NavBar>

    if (this.props.language === language.id) {
      return;
    }

    const clone = { ...language };
    this.props.setLang(clone.id);
    this.setLanguageToLocalStorage(clone.id);
    this.clearFourOhFourBlock();
    this.clearRegionBlock();
    this.postLanguageSet(clone.id, true);

    // I'm sorry, it was a long day
    const yeah = this.props.location.pathname.split("/");
    const newPathArray = yeah.map((point) => {
      if (point === this.props.language) {
        return language.id;
      } else {
        return point;
      }
    });
    const newPath = newPathArray.join("/");
    this.props.history.push(newPath);
  };

  setRegionToLocalStorage = (region) => {
    if (region) {
      handleSetItem("MEPRegionPref", region);
    }
  };

  setLanguageToLocalStorage = (id) => {
    if (id) {
      handleSetItem("MEPLangPref", id);
    }
  };

  toggleRegionLanguageDeployed = () => {
    // Toggles the Region/Language switcher modal
    this.setState({
      regionLanguageDeployed: !this.state.regionLanguageDeployed,
    });
  };

  closeModal = (e) => {
    // Closes the Region/Language switcher modal
    this.setState({ regionLanguageDeployed: false });
  };

  getResultsForLabel = () => {
    // This is 'Results for' label from the search page.
    const label =
      this.state.generalConfig &&
      this.state.generalConfig[0].settings &&
      this.state.generalConfig[0].settings.results_for_label
        ? this.state.generalConfig[0].settings.results_for_label
        : "Results for";

    return label;
  };

  getLearnMoreLabel = () => {
    // This is the 'Learn More' label for all product cards
    const label =
      this.state.generalConfig &&
      this.state.generalConfig[0].settings &&
      this.state.generalConfig[0].settings.card_cta_text
        ? this.state.generalConfig[0].settings.card_cta_text
        : "Learn More";

    return label;
  };

  getAllProductsLabel = () => {
    // This is the text needed for the 'All Products' CTA in the MegaMenu
    const label =
      this.state.generalConfig &&
      this.state.generalConfig[0].settings &&
      this.state.generalConfig[0].settings.all_products_label
        ? this.state.generalConfig[0].settings.all_products_label
        : "All Products";

    return label;
  };

  addAllProductsToMenus = () => {
    // Pulls the menus out of state and inserts the AllProducts text into the MegaMenu
    if (this.state.menus) {
      const menus = JSON.parse(JSON.stringify(this.state.menus));
      const megaMenu = menus.find((menu) => menu.type === "mega menu");
      if (megaMenu && megaMenu.bottomLink) {
        megaMenu.bottomLink.text = this.getAllProductsLabel();
      }

      return menus;
    }
  };

  getNoProductsData = () => {
    let returnObj = {};
    if (
      this.state.generalConfig &&
      this.state.generalConfig[0] &&
      this.state.generalConfig[0].settings &&
      this.state.generalConfig[0].settings.no_products_label
    ) {
      returnObj.header = this.state.generalConfig[0].settings.no_products_label;
    } else {
      returnObj.header = "No Products";
    }

    if (
      this.state.generalConfig &&
      this.state.generalConfig[0] &&
      this.state.generalConfig[0].settings &&
      this.state.generalConfig[0].settings.no_products_text
    ) {
      returnObj.text = this.state.generalConfig[0].settings.no_products_text;
    } else {
      returnObj.text = "There are no products in this category in your region.";
    }

    if (
      this.state.generalConfig &&
      this.state.generalConfig[0] &&
      this.state.generalConfig[0].settings &&
      this.state.generalConfig[0].settings.all_products_label
    ) {
      returnObj.link = {
        text: this.state.generalConfig[0].settings.all_products_label,
        url: `/${this.props.language}/products`,
      };
    } else {
      returnObj.link = {
        text: "All Products",
        url: `/${this.props.language}/products`,
      };
    }

    return returnObj;
  };
  // TODO - update the url in hreflang to the master url when that is in place.
  render() {
    return (
      <div className="app">
        <HelmetHandler
          allLanguages={this.state.sitewideLanguages}
          allRegions={this.state.sitewideRegions}
          country={this.state.country}
          region={this.props.region ? this.props.region : null}
          status={this.props.status}
          error={this.props.errorStatus}
        />
        {this.state.menus &&
          !this.state.isLoading &&
          this.props.language &&
          this.state.sitewideLanguages &&
          this.state.sitewideRegions &&
          this.state.divisionName &&
          this.props.region &&
          this.props.language &&
          this?.state?.generalConfig?.[0]?.settings?.region_label &&
          this?.state?.generalConfig?.[0]?.settings?.language_label &&
          this?.state?.generalConfig?.[0]?.settings?.switcher_help_text &&
          this?.state?.generalConfig?.[0]?.settings?.current_region_label &&
          this?.state?.generalConfig?.[0]?.settings?.region_save_btn &&
          this?.state?.generalConfig?.[0]?.settings?.region_cancel_btn && (
            <NavBar
              language={this.props.language}
              rightLinks={this.state.rightLinks}
              logo={{
                ...logo,
                link: {
                  url: `/${this.props.language}`,
                },
              }}
              divisionName={this.state.divisionName}
              currentLanguage={{
                language: this.props.language,
                region: this.props.region || "",
              }}
              navCTA={
                this.props.ctaOverride
                  ? { link: this.props.ctaOverride }
                  : this.state.navCTA
              }
              navOptions={this.addAllProductsToMenus()}
              utilityNav={this.props.innerPageNav}
              searchSubmit={this.searchSubmit}
              languageSelectorData={generateRegionAndLanguages(
                this.state.sitewideLanguages,
                this.state.sitewideRegions,
                this.state.divisionName,
                this.props.region,
                this.props.language,
                this?.state?.generalConfig?.[0]?.settings?.region_label,
                this?.state?.generalConfig?.[0]?.settings?.language_label,
                this?.state?.generalConfig?.[0]?.settings?.switcher_help_text,
                this?.state?.generalConfig?.[0]?.settings?.current_region_label,
                this?.state?.generalConfig?.[0]?.settings?.region_save_btn,
                this?.state?.generalConfig?.[0]?.settings?.region_cancel_btn,
                this.state.regionLabel,
                this.state.langLabel
              )}
              setLanguage={this.setLanguageFromNav}
              regionLanguageDeployed={this.state.regionLanguageDeployed}
              toggleRegionLanguageDeployed={this.toggleRegionLanguageDeployed}
              closeModal={this.closeModal}
              languageDropdown
            />
          )}
        {this.props.alert && (
          <Alert
            onClick={() => {
              this.props.updateAlert(null);
            }}
            text={this.props.alert}
            name="alert"
            type="warning"
          />
        )}
        <Routing
          routesData={this.state.routesData}
          searchResults={this.state.searchResults}
          searchTerm={this.state.searchTerm}
          setMenus={this.setMenus}
          postLanguageSet={this.postLanguageSet}
          languages={this.state.sitewideLanguages}
          toggleRegionLanguageDeployed={this.toggleRegionLanguageDeployed}
          resultsForLabel={this.getResultsForLabel()}
          learnMoreLabel={this.getLearnMoreLabel()}
          noProductsData={this.getNoProductsData()}
          country={this.state.country}
          getLocation={this.getLocation}
        />
        {(this.props.status === "success" || this.props.status === "error") &&
          this.props.language &&
          this.props.region &&
          this.state.sitewideRegions && (
            <FooterHandler regions={this.state.sitewideRegions} />
          )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (lang) => dispatch(updateLanguage(lang)),
    updateStatus: (status) => dispatch(updateStatus(status)),
    updateRegion: (region) => dispatch(updateRegion(region)),
    updateAlert: (alert) => dispatch(updateAlert(alert)),
    updateNavbarReady: (bool) => dispatch(updateNavbarReady(bool)),
    updateLearnMoreLabel: (text) => dispatch(updateLearnMoreLabel(text)),
  };
};

const mapStateToProps = (state) => ({
  status: state.status,
  innerPageNav: state.innerPageNav,
  language: state.language,
  metadata: state.metadata,
  region: state.region,
  alert: state.alert,
  ctaOverride: state.ctaOverride,
  navbarReady: state.navbarReady,
  errorStatus: state.errorStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
