import React from "react";
import { Card } from "terra-component-lib";

const mapProducts = products => {
  return products.map((product, index) => {
    return (
      <Card
        key={`${product.header}-${index}`}
        header={product.header}
        image={product.image}
        text={product.text}
        buttonLink={product.link}
      />
    );
  });
};

export default mapProducts;
