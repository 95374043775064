import basicFetch from "./basicFetch";

const successFetch = async (url) => {
  const response = await basicFetch(url);
  if (response.data && response.data[0] && response.data[0].content_sections) {
    return formatSuccess(
      Object.values(response.data[0].content_sections)[0][0]
    );
  }
};

const formatSuccess = (data) => {
  const content = {
    header: data.header ? data.header : null,
    subHeader: data.subHeader ? data.subHeader : null,
    text: data.text ? data.text : null,
    image: {
      url:
        data.image_large && data.image_large.source
          ? data.image_large.source
          : null,
      altText:
        data.image_large && data.image_large.alt ? data.image_large.alt : null,
    },
    ctas: {
      ctaOne: {
        url:
          data.ctas && data.ctas.ctaOne && data.ctas.ctaOne.url
            ? data.ctas.ctaOne.url
            : null,
        text:
          data.ctas && data.ctas.ctaOne && data.ctas.ctaOne.text
            ? data.ctas.ctaOne.text
            : null,
      },
      ctaTwo:
        data.ctas && data.ctas.ctaTwo && data.ctas.ctaTwo.text
          ? { text: data.ctas.ctaTwo.text }
          : null,
    },
  };
  return { content };
};

export default successFetch;
