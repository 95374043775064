import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import ProductPage from "../../pages/ProductPage/ProductPage";
import TradePage from "../../pages/TradePage/TradePage";
import CollectionsPage from "../../pages/CollectionsPage/CollectionsPage";
import SearchResultsPage from "../../pages/SearchResultPage/SearchResultPage";
import PageBuilderPage from "../../pages/PageBuilderPage/PageBuilderPage";
import DemoPage from "../../pages/DemoPage/DemoPage";
import SupportPage from "../../pages/SupportPage/SupportPage";
import FourZeroFourPage from "../../pages/FourZeroFourPage/FourZeroFourPage";
import AdminPage from "../../pages/AdminPage/AdminPage";
import AdminTradeCatPage from "../../pages/AdminTradeCatPage/AdminTradeCatPage";
import tradeFetch from "../../utils/async/tradeFetch";
import languages from "../../utils/helperObjects/languages";
import Loading from "../Loading/Loading";
import BIMDemoPage from "../../pages/BIMDemoPage/BIMDemoPage";
import { connect } from "react-redux";
import {
  updateLanguage,
  updateOffsetY,
  updateAlert,
  updateErrorStatus,
} from "../../actions";
import {
  ScrollToTop,
  ParallaxController,
} from "@trimble-creative-strategy/luna-component-lib";
import * as Sentry from "@sentry/browser";
import SuccessPage from "../../pages/SuccessPage/SuccessPage";
import AdminHomePage from "../../pages/AdminHomePage/AdminHomePage";
import { handleGetItem } from "../../utils/localStorage/localStorageHandler";

class Routing extends Component {
  constructor() {
    super();

    this.state = {
      status: "loading",
      trades: null,
      categories: null,
      languages: null,
    };
  }

  componentDidMount() {
    this.getLang();
    this.getData();
  }

  // THIS CODE IS FOR MAINTAINING SEARCH QUERIES AS THE USER NAVIGATE THE PAGE FOR UTM //

  componentDidUpdate(prevProps) {
    this.checkForSearchParams(prevProps);
    this.checkForDoubleBack(prevProps);
  }

  checkForSearchParams = (prevProps) => {
    // checking to see if the previous location included a search query and pushes if the current location doesn't have it

    const prevSearchParam = prevProps?.location?.search;
    const currentSearchParam = this.props.location.search;
    const prevAction = prevProps.history.action;
    const currentPath = this.props.location.pathname;

    if (prevAction === "PUSH" || prevAction === "REPLACE") {
      // 'PUSH' and 'REPLACE' indicate fowrard browser movement
      if (prevSearchParam?.length && !currentSearchParam?.length) {
        // If there is a previous search term but not a current
        if (
          currentPath.substring(0, 3) !==
          `/${this.props.language.substring(0, 2)}`
        ) {
          // if language is missing
          const newPath = `/${this.props.language}${currentPath}${prevSearchParam}`;
          this.props.history.push(newPath);
        } else {
          // if language is present
          const newPath = `${currentPath}${prevSearchParam}`;
          this.props.history.push(newPath);
        }
      }
    }
  };

  checkForDoubleBack = (prevProps) => {
    // This is used to manage search queries when using back/forward browser address bar buttons

    const prevSearchParam = prevProps?.location?.search;
    const prevAction = prevProps?.history.action;
    const currentSearchParam = this.props.location.search;

    const currentPath = this.props.location.pathname;
    const prevPath = prevProps.location.pathname;

    if (
      prevAction === "POP" &&
      prevSearchParam?.length &&
      !currentSearchParam?.length
    ) {
      // 'POP' indicates that the user pressed either back or forward button
      if (prevPath === currentPath) {
        // indicates back was pushed and forces a second 'back'
        this.props.history.goBack();
      } else {
        // indicates forward was pushed and forces a second 'forward'
        this.props.history.goForward();
      }
    }
  };

  // END UTM SEARCH QUERY CODE //

  getData = async () => {
    // Fetch the names af all the trades from their taxonomies and set them into state for route rendering
    this.setLoading();

    try {
      const data = await tradeFetch();
      this.setSuccess(data);
    } catch (error) {
      console.log(error);
      this.setError();
      Sentry.captureException(error);
    }
  };

  setLoading = () => {
    this.setState({ status: "loading" });
  };

  setSuccess = (data) => {
    this.setState({
      status: "success",
      trades: data.trades,
      categories: data.categories,
      languages: data.languages,
    });
  };

  setError = () => {
    this.setState({ status: "error" });
  };

  mapTradeRoutes = () => {
    // Map over all the trades in state and render routes for their base pages
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${this.props.language}/${trade}-solutions`}
          render={(props) => (
            <TradePage
              page="trade"
              trade={trade}
              {...props}
              learnMoreLabel={this.props.learnMoreLabel}
            />
          )}
          exact
          key={trade}
          noProductsData={this.props.noProductsData || "No Products"}
        />
      );
    });
  };

  mapTradeAdminRoutes = () => {
    // Map over all the trades in state and render routes for their base pages
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${this.props.language}/admin/${trade}-solutions/:translation`}
          render={(props) => (
            <TradePage
              page="trade"
              trade={trade}
              admin
              learnMoreLabel={this.props.learnMoreLabel}
              {...props}
            />
          )}
          exact
          key={`${trade}`}
          noProductsData={this.props.noProductsData || "No Products"}
        />
      );
    });
  };

  mapTradeRedirectRoutes = () => {
    // Map over all the trades in state and render routes that will redirect the user if they left '-solutions' out after the trade name
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${this.props.language}/${trade}`}
          render={(props) => (
            <Redirect to={`/${this.props.language}/${trade}-solutions`} />
          )}
          exact
          key={trade}
        />
      );
    });
  };

  mapTradeNoLangRedirectRoutes = () => {
    // Map over all trades in state and render routes that will redirect the user if the language is missing from the internal link
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${trade}-solutions`}
          render={(props) => (
            <Redirect
              to={`/${this.props.language || "en"}/${trade}-solutions`}
            />
          )}
          exact
          key={trade}
        />
      );
    });
  };

  mapTradeWrongLanguageRedirectRoutes = () => {
    // Returns trade redirects for the wrong languages
    const languages = this.props.languages || [];
    return languages
      .filter((language) => language.id !== this.props.language)
      .reduce((accu, language) => {
        const newRoutes = this.state.trades.map((trade) => {
          return (
            <Route
              hreflang={this.props.language ? this.props.language : "en"}
              key={`${language.id}-wrong-trade-lang-${trade}`}
              path={`/${language.id}/${trade}-solutions`}
              exact
              render={() => (
                <Redirect to={`/${this.props.language}/${trade}-solutions`} />
              )}
            />
          );
        });

        accu = [...accu, ...newRoutes];

        return accu;
      }, []);
  };

  mapTradeNoLangNoProductRedirectRoutes = () => {
    // Map over all trades in state and render routes that will redirect the user if the language is missing from the internal link
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${trade}`}
          render={(props) => (
            <Redirect
              to={`/${this.props.language || "en"}/${trade}-solutions`}
            />
          )}
          exact
          key={trade}
        />
      );
    });
  };

  mapCategoryRoutes = () => {
    // generate category routes for every trade combination
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${this.props.language}/${trade}-solutions/:categoryName`}
          render={(props) => (
            <TradePage
              page="category"
              {...props}
              trade={trade}
              categories={this.state.categories}
              category
              learnMoreLabel={this.props.learnMoreLabel}
              noProductsData={this.props.noProductsData || {}}
            />
          )}
          key={`${trade}-category-page`}
          exact
        />
      );
    });
  };

  mapCategoryAdminRoutes = () => {
    // generate category routes for every trade combination
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${this.props.language}/admin/${trade}-solutions/:categoryName/:translation`}
          render={(props) => (
            <TradePage
              page="category"
              {...props}
              trade={trade}
              categories={this.state.categories}
              category
              admin
              learnMoreLabel={this.props.learnMoreLabel}
              noProductsData={this.props.noProductsData || {}}
            />
          )}
          key={`${trade}-category-page-admin`}
          exact
        />
      );
    });
  };

  mapCategoryWrongLanguageRedirectRoutes = () => {
    // Returns trade/category redirects for the incorrect language
    const languages = this.props.languages || [];
    return languages
      .filter((language) => language.id !== this.props.language)
      .reduce((accu, language) => {
        const newRoutes = this.state.trades.map((trade) => {
          return (
            <Route
              hreflang={this.props.language ? this.props.language : "en"}
              key={`${language.id}-wrong-trade-category-lang-${trade}`}
              path={`/${language.id}/${trade}-solutions/:categoryName`}
              exact
              render={(props) => (
                <Redirect
                  to={`/${this.props.language}/${trade}-solutions/${props.match.params.categoryName}`}
                />
              )}
            />
          );
        });

        accu = [...accu, ...newRoutes];
        return accu;
      }, []);
  };

  mapCategoryRedirectRoutes = () => {
    // Map over all the trades in state and render category routes that will redirect the user if they left '-solutions' out after the trade name
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${this.props.language}/${trade}/:categoryName`}
          render={(props) => (
            <Redirect
              to={`/${this.props.language}/${trade}-solutions/${props.match.params.categoryName}`}
            />
          )}
          key={`${trade}-category-page`}
          exact
        />
      );
    });
  };

  mapCategoryNoLangRedirectRoutes = () => {
    // Map over all trades in state and render trade/category routes that will redirect the user if the language is missing from the internal link
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${trade}-solutions/:categoryName`}
          render={(props) => (
            <Redirect
              to={`/${this.props.language}/${trade}-solutions/${props.match.params.categoryName}`}
            />
          )}
          key={`${trade}-category-page-no-lang-redirect`}
          exact
        />
      );
    });
  };

  mapCategoryNoLangNoProductsRedirectRoutes = () => {
    // Map over all trades in state and render trade/category routes that will redirect the user if the language is missing from the internal link
    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${trade}/:categoryName`}
          render={(props) => (
            <Redirect
              to={`/${this.props.language}/${trade}-solutions/${props.match.params.categoryName}`}
            />
          )}
          key={`${trade}-category-page-no-lang-redirect`}
          exact
        />
      );
    });
  };

  mapCatProductRoutes = () => {
    // generate product routes for every trade/category page
    const lang = this.props.language;

    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${lang}/${trade}-solutions/:categoryName/:productName`}
          render={(props) => (
            <ProductPage
              language={lang}
              {...props}
              trade={trade}
              toggleRegionLanguageDeployed={
                this.props.toggleRegionLanguageDeployed
              }
              page="product-page"
            />
          )}
          key={`${lang}-${trade}-product-page`}
          exact
        />
      );
    });
  };

  mapTradeCatProductsWrongLanguageRedirectRoutes = () => {
    // Returns trade/category/product redirects for the incorrect language
    const languages = this.props.languages || [];
    return languages
      .filter((language) => language.id !== this.props.language)
      .reduce((accu, language) => {
        const newRoutes = this.state.trades.map((trade) => {
          return (
            <Route
              hreflang={this.props.language ? this.props.language : "en"}
              key={`${language.id}-wrong-trade-category-prod-lang-${trade}`}
              path={`/${language.id}/${trade}-solutions/:categoryName/:productName`}
              exact
              render={(props) => (
                <Redirect
                  to={`/${this.props.language}/${trade}-solutions/${props.match.params.categoryName}/${props.match.params.productName}`}
                />
              )}
            />
          );
        });

        accu = [...accu, ...newRoutes];
        return accu;
      }, []);
  };

  mapCatProductNoLangRedirectRoutes = () => {
    // generate redirects for trade/cat/product pages that are missing language and 'x-solutions'
    const lang = this.props.language;

    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${trade}-solutions/:categoryName/:productName`}
          render={(props) => (
            <Redirect
              to={`/${lang}/${trade}-solutions/${props.match.params.categoryName}/${props.match.params.productName}`}
            />
          )}
          key={`${lang}-${trade}-product-page`}
          exact
        />
      );
    });
  };

  mapCatProductRedirectRoutes = () => {
    // generate redirects for trade/cat/product pages that are missing 'x-solutions'
    const lang = this.props.language;

    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${lang}/${trade}/:categoryName/:productName`}
          render={(props) => (
            <Redirect
              to={`/${lang}/${trade}-solutions/${props.match.params.categoryName}/${props.match.params.productName}`}
            />
          )}
          key={`${lang}-${trade}-product-page`}
          exact
        />
      );
    });
  };

  mapDemoRoutes = () => {
    // generate demo routes for every trade/category combination
    const lang = this.props.language;

    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${lang}/${trade}-solutions/:categoryName/:productName/demo`}
          render={(props) => (
            <DemoPage trade={trade} {...props} country={this.props.country} />
          )}
          key={`${lang}-${trade}-product-demo-page`}
          exact
        />
      );
    });
  };

  mapDemoWrongLanguageRedirectRoutes = () => {
    // Returns redirects for all trade/cat/prod/demo routes with the wrong language
    const languages = this.props.languages || [];
    return languages
      .filter((language) => language.id !== this.props.language)
      .reduce((accu, language) => {
        const newRoutes = this.state.trades.map((trade) => {
          return (
            <Route
              hreflang={this.props.language ? this.props.language : "en"}
              key={`${language.id}-wrong-trade-category-prod-lang-${trade}`}
              path={`/${language.id}/${trade}-solutions/:categoryName/:productName/demo`}
              exact
              render={(props) => (
                <Redirect
                  to={`/${this.props.language}/${trade}-solutions/${props.match.params.categoryName}/${props.match.params.productName}/demo`}
                />
              )}
            />
          );
        });

        accu = [...accu, ...newRoutes];
        return accu;
      }, []);
  };

  mapDemoSuccessRoutes = () => {
    // generate demo success routes for every trade/category combination
    const lang = this.props.language;

    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${lang}/${trade}-solutions/:categoryName/:productName/demo/submitted`}
          render={(props) => (
            <SuccessPage
              {...props}
              category={props.match.params.categoryName}
              trade={trade}
              product={props.match.params.productName}
            />
          )}
          key={`${lang}-${trade}-product-demo-page-submitted`}
        />
      );
    });
  };

  mapDemoSuccessRedirectRoutes = () => {
    // generate demo success routes for every trade/category combination
    const lang = this.props.language;

    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${lang}/${trade}-solutions/:categoryName/:productName/demo//submitted`}
          render={(props) => (
            <Redirect
              to={`/${lang}/${trade}-solutions/${props.match.params.categoryName}/${props.match.params.productName}/demo/submitted`}
            />
          )}
          key={`${lang}-${trade}-product-demo-page-submitted`}
        />
      );
    });
  };

  mapDemoSuccessWrongLanguageRedirectRoutes = () => {
    const languages = this.props.languages || [];
    return languages
      .filter((language) => language.id !== this.props.language)
      .reduce((accu, language) => {
        const newRoutes = this.state.trades.map((trade) => {
          return (
            <Route
              hreflang={this.props.language ? this.props.language : "en"}
              key={`${language.id}-wrong-trade-category-prod-lang-demo-submitted-${trade}`}
              path={`/${language.id}/${trade}-solutions/:categoryName/:productName/demo/submitted`}
              exact
              render={(props) => (
                <Redirect
                  to={`/${this.props.language}/${trade}-solutions/${props.match.params.categoryName}/${props.match.params.productName}/demo/submitted`}
                />
              )}
            />
          );
        });

        accu = [...accu, ...newRoutes];
        return accu;
      }, []);
  };

  mapDemoRedirectRoutes = () => {
    // generate demo routes for every trade/category combination
    const lang = this.props.language;

    return this.state.trades.map((trade) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${lang}/${trade}/:categoryName/:productName/demo`}
          render={(props) => (
            <Redirect
              to={`/${lang}/${trade}-solutions/${props.match.params.categoryName}/${props.match.params.productName}/demo`}
            />
          )}
          key={`${lang}-${trade}-product-demo-page`}
          exact
        />
      );
    });
  };

  getLang = () => {
    // Check local storage for a language preference
    const langPref = handleGetItem("MEPLangPref");

    if (langPref) {
      // set the language based on language preference in local storage
      this.setLangPrefFromLocalStorage(langPref);
    } else {
      // check the language in browser preferences
      this.getBrowserLangPref();
    }
  };

  getSitewideLanguageCodes = () => {
    const languages = this.props.languages || [
      { id: "en" },
      { id: "de" },
      { id: "en-GB" },
      { id: "nl" },
      { id: "fr" },
    ];

    const ids = languages.map((language) => language.id);
    return ids;
  };

  setLangPrefFromLocalStorage = (langPref) => {
    // get the full current path in the address bar
    const currentPath = this.props.location.pathname;
    // parse out the language code in the address bar
    const addressBarLangCode =
      currentPath.substring(1, 6) === "en-GB"
        ? "en-GB"
        : currentPath.substring(1, 3);
    // parse the language code from local storage

    // line 580 was previously const lang = JSON.prase(langPref) however - JSON.parse(langPref) was causing the site to white screen after changing the language and refreshing the page)
    const lang = langPref;

    // separate out any trailing characters in the language from local storage
    const langCode = lang === "en-GB" ? "en-GB" : lang.substring(0, 2);

    // set the language from local storage in state
    this.props.setLang(langCode);
    this.props.postLanguageSet();
    if (
      lang !== addressBarLangCode &&
      currentPath !== "/resources" &&
      currentPath !== addressBarLangCode
    ) {
      // if the current path in the address bar does not reflect the preferred language, forward the user to the appropriate path so that it does
      const newPath = `/${lang}${currentPath}`;
      if (currentPath.substring(1, 6) === "en-GB") {
        // properly converts 'en-GB' to the new language path
        const correctedPath = currentPath.replace("en-GB", lang);
        this.props.history.push(correctedPath);
      } else if (
        this.getSitewideLanguageCodes().includes(currentPath.substring(1, 3))
      ) {
        // Don't push, this gets handled elsewhere, I guess?
      } else {
        this.props.history.push(newPath);
      }
    }
    this.props.setMenus();
  };

  getBrowserLangPref = () => {
    // get the full current path in the address bar
    const currentPath = this.props.location.pathname;
    // parse out the language code in the address bar
    const addressBarLangCode = currentPath.substring(1, 3);

    // get the language preference from the browser and get rid of trailing charcters so that it's a two-character string
    const browserLangPref = navigator.language;
    const langCode = browserLangPref.substring(0, 2);

    if (languages.includes(langCode)) {
      // if the preferred browser language is supported by MEP, set the language in state
      this.props.setLang(langCode);
      this.props.postLanguageSet();
      if (langCode !== addressBarLangCode && currentPath !== "/resources") {
        // if the current path in the address bar does not reflect the preferred language, forward the user to the appropriate path so that it does

        const newPath = `/${langCode}${currentPath}`;
        this.props.history.push(newPath);
      }

      this.props.setMenus();
    } else {
      // if the preferred browser language is not supported by MEP, set the preferred language based on what's in the address bar
      this.setLangFromRoute();
    }
  };

  setLangFromRoute = () => {
    // get the full current path in the address bar
    const currentPath = this.props.location.pathname;
    // parse out the language code in the address bar
    const addressBarLangCode = currentPath.substring(1, 3);

    if (languages.includes(addressBarLangCode)) {
      // if the langage in the address bar is supported by MEP, set it into state and forward the user to the appropriate path

      const newPath = `/${addressBarLangCode}${currentPath}`;
      this.props.setLang(addressBarLangCode);
      this.props.postLanguageSet();
      this.props.history.push(newPath);
    } else {
      // if the langage in the address bar is not supported by MEP, set English into state and forward the user appropriately

      this.props.setLang("en");
      this.props.postLanguageSet();
      const newPath = `/en${currentPath}`;
      if (currentPath !== "/resources") {
        this.props.history.push(newPath);
      }
    }
    this.props.setMenus();
  };

  mapLanguageRedirectRoutes = () => {
    return this.props.languages
      .filter((language) => {
        // filter the current language so we're not generating redirects with the active language
        return language.id !== this.props.language;
      })
      .map((language) => {
        if (language.id.includes("-")) {
          // specifically for en-GB
          return (
            <Route
              hreflang={this.props.language ? this.props.language : "en"}
              key={`${language.id}-mass-redirect-route`}
              path={`/${language.id}/`}
              render={() => {
                return (
                  <Redirect
                    to={this.getLanguageRedirectPath(
                      language.id,
                      this.state.languages
                    )}
                  />
                );
              }}
            />
          );
        } else {
          return (
            // all other languages
            <Route
              hreflang={this.props.language ? this.props.language : "en"}
              key={`${language.id}-mass-redirect-route`}
              path={`/${this.props.language}/${language.id}/`}
              render={() => {
                return (
                  <Redirect
                    to={this.getDoubledLanguageRedirectPath(language.id)}
                  />
                );
              }}
            />
          );
        }
      });
  };

  getLanguageRedirectPath = (id, languages = []) => {
    // get the current location
    const { pathname } = this.props.location;

    // takes the languages and returns the one that matches the language id
    let routeLanguage = languages.find(() => {
      return this.props.location.pathname.includes(id);
    });

    // replaces the matching language id with the current language and returns it
    const newPath = pathname.replace(routeLanguage.id, this.props.language);

    return newPath;
  };

  getDoubledLanguageRedirectPath = (id) => {
    return this.props.location.pathname.replace(
      `/${this.props.language}/${id}/`,
      `/${this.props.language}/`
    );
  };

  mapSearchWrongLanguageRedirectRoutes = () => {
    const languages = this.props.languages || [];

    const filteredLanguages = languages.filter((language) => {
      return language.id !== this.props.language;
    });

    return filteredLanguages.map((language) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          key={`${language.id}-search-redirect`}
          path={`/${language.id}/search-results/:searchTerm`}
          render={(props) => (
            <Redirect
              to={`/${this.props.language}/search-results/${props.match.params.searchTerm}`}
            />
          )}
        />
      );
    });
  };

  mapProductPageWrongLanguageRedirectRoute = () => {
    const languages = this.props.languages || [];

    const filteredLanguages = languages.filter((language) => {
      return language.id !== this.props.language;
    });

    return filteredLanguages.map((language) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          key={`${language.id}-product-redirect`}
          path={`/${language.id}/products/:productName`}
          render={(props) => (
            <Redirect
              to={`/${this.props.language}/products/${props.match.params.productName}`}
            />
          )}
        />
      );
    });
  };

  mapServicesWrongLanguageRedirectRoute = () => {
    const languages = this.props.languages || [];

    const filteredLanguages = languages.filter((language) => {
      return language.id !== this.props.language;
    });

    return filteredLanguages.map((language) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          key={`services-redirect-${language.id}`}
          path={`/${language.id}/services/:query`}
          render={(props) => (
            <Redirect
              to={`/${this.props.language}/services/${props.match.params.query}`}
            />
          )}
          exact
        />
      );
    });
  };

  mapSolutionsPageWrongLanguageRedirectRoute = () => {
    const languages = this.props.languages || [];

    const filteredLanguages = languages.filter((language) => {
      return language.id !== this.props.language;
    });

    return filteredLanguages.map((language) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          key={`solutions-page-wrong-lang-redirect-${language.id}`}
          path={`/${language.id}/solutions/`}
          render={() => <Redirect to={`/${this.props.language}/solutions`} />}
          exact
        />
      );
    });
  };

  mapBIMResourceTrainingWrongLanguageRedirectRoutes = () => {
    const languages = this.props.languages || [];

    const filteredLanguages = languages.filter((language) => {
      return language.id !== this.props.language;
    });

    const pages = this.getPages();

    return filteredLanguages.reduce((accu, language) => {
      const newRoutes = pages.map((page) => {
        return (
          <Route
            hreflang={this.props.language ? this.props.language : "en"}
            path={`/${language.id}/${page}`}
            exact
            key={`language-redirect-${language.id}-${page}`}
            render={() => (
              <Redirect to={`/${this.props.language || "en"}/${page}`} />
            )}
          />
        );
      });

      accu = [...accu, ...newRoutes];
      return accu;
    }, []);
  };

  mapBIMResourceTrainingNoLanguageRedirectRoutes = () => {
    const pages = this.getPages();

    return pages.map((page) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${page}`}
          exact
          key={`no-language-redirect-${page}`}
          render={() => (
            <Redirect to={`/${this.props.language || "en"}/${page}`} />
          )}
        />
      );
    });
  };

  getPages = () => {
    return [
      "bim-services",
      "bim-services-contact",
      "resources",
      "training",
      "products",
      "submitted",
      "support",
      "contact",
      "get-in-touch",
      "get-in-touch/submitted",
    ];
  };

  filterCustomRoutes = () => {
    // This is returning an empty array - apparently all custom routes are now gone? - KS 6/29
    const { routesData = {} } = this.props;
    const pathname = this.props.location.pathname.replace(
      `${this.props.language}/`,

      ""
    );
    const routes = routesData
      .filter((route) => {
        return (
          !route.includes("/products/") &&
          !route.includes("/solutions/") &&
          !route.includes("resources") &&
          !route.includes("services")
        );
      })
      .filter((route) => {
        return (
          route !== `/products` &&
          route !== "/solutions" &&
          route !== "/support"
        );
      })
      .filter((route) => {
        return !route.includes("products") && !route.includes("solutions");
      })
      .filter((route) => {
        return route === pathname;
      });

    return routes;
  };

  generatePageBuilderCustomRoutes = () => {
    const routes = this.filterCustomRoutes();

    return routes.map((route) => {
      return (
        <Route
          to={`/${this.props.language}${route}`}
          key={route}
          render={(props) => <PageBuilderPage customRoute {...props} />}
          exact
        />
      );
    });
  };

  generatePageBuilderCustomRedirects = () => {
    const routes = this.filterCustomRoutes();

    return routes.map((route) => {
      return (
        <Route
          path={`${route}`}
          key={route}
          render={() => <Redirect to={`/${this.props.language}${route}`} />}
          exact
        />
      );
    });
  };

  generateCollectionsPagesRoutes = () => {
    const pages = ["solutions", "products"];

    return pages.map((page) => {
      return (
        <Route
          hreflang={this.props.language ? this.props.language : "en"}
          path={`/${this.props.language}/${page}`}
          exact
          key={`${page}-collections-page`}
          render={(props) => (
            <CollectionsPage
              language={this.props.language}
              {...props}
              trades={this.state.trades}
              categories={this.state.categories}
              country={this.props.country}
            />
          )}
        />
      );
    });
  };

  redirectProductToProducts = () => {
    if (
      this.props.location.pathname.includes("product") &&
      !this.props.location.pathname.includes("products")
    ) {
      const product = this.props.location.pathname.split("/product");
      if (product && product[1])
        return (
          <Redirect to={`/${this.props.language}/products${product[1]}`} />
        );
    } else {
      return;
    }
  };

  render() {
    const { language, searchResults, searchTerm } = this.props;
    return (
      <>
        <ParallaxController
          updateOffsetY={this.props.updateOffsetY}
          updateWindowHeight={() => {}}
        />
        <ScrollToTop />
        <Switch>
          <Route
            hreflang={this.props.language ? this.props.language : "en"}
            path={"/truest"}
            exact
            render={() => {
              window.location = "https://go.truest.trimble.com";
              return null;
            }}
          />
          <Route
            hreflang={this.props.language ? this.props.language : "en"}
            path={`/${this.props.language || "en"}/truest`}
            exact
            render={() => {
              window.location = "https://go.truest.trimble.com";
              return null;
            }}
          />
          <Route
            hreflang={this.props.language ? this.props.language : "en"}
            path={`${this.props.language}/products/:productName`}
            render={(props) => (
              <ProductPage
                page="product-page"
                language={this.props.language}
                {...props}
                toggleRegionLanguageDeployed={
                  this.props.toggleRegionLanguageDeployed
                }
              />
            )}
            exact
          />
          {this.state.status === "success" && (
            <Switch>
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language}`}
                exact
                render={(props) => (
                  <PageBuilderPage
                    page="home"
                    {...props}
                    learnMoreLabel={this.props.learnMoreLabel}
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path="/"
                exact
                render={(props) => <Redirect to={`/${this.props.language}/`} />}
              />
              {this.redirectProductToProducts()}
              {this.mapTradeRoutes()}
              {this.mapCategoryRoutes()}
              {this.mapCatProductRoutes()}
              {this.mapDemoRoutes()}
              {this.mapTradeRedirectRoutes()}
              {this.mapTradeNoLangRedirectRoutes()}
              {this.mapTradeNoLangNoProductRedirectRoutes()}
              {this.mapCategoryNoLangRedirectRoutes()}
              {this.mapCategoryRedirectRoutes()}
              {this.mapCatProductRedirectRoutes()}
              {this.mapDemoRedirectRoutes()}
              {this.mapDemoSuccessRoutes()}
              {this.mapCatProductNoLangRedirectRoutes()}
              {this.mapCategoryNoLangNoProductsRedirectRoutes()}
              {this.mapTradeWrongLanguageRedirectRoutes()}
              {this.mapCategoryWrongLanguageRedirectRoutes()}
              {this.mapTradeCatProductsWrongLanguageRedirectRoutes()}
              {this.mapDemoWrongLanguageRedirectRoutes()}
              {this.mapDemoSuccessWrongLanguageRedirectRoutes()}
              {this.mapSearchWrongLanguageRedirectRoutes()}
              {this.mapProductPageWrongLanguageRedirectRoute()}
              {this.mapServicesWrongLanguageRedirectRoute()}
              {this.mapSolutionsPageWrongLanguageRedirectRoute()}
              {this.mapBIMResourceTrainingWrongLanguageRedirectRoutes()}
              {this.mapBIMResourceTrainingNoLanguageRedirectRoutes()}
              {this.mapTradeAdminRoutes()}
              {this.mapCategoryAdminRoutes()}
              {this.mapDemoSuccessRedirectRoutes()}
              {this.generateCollectionsPagesRoutes()}
              {this.props.languages &&
                language &&
                this.mapLanguageRedirectRoutes()}
              {this.generatePageBuilderCustomRedirects()}
              {this.generatePageBuilderCustomRoutes()}
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                key={`product-route-${this.props.language}`}
                path={`/${this.props.language}/products/:productName`}
                render={(props) => (
                  <ProductPage
                    {...props}
                    page="product-page"
                    toggleRegionLanguageDeployed={
                      this.props.toggleRegionLanguageDeployed
                    }
                  />
                )}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language}/services/:query`}
                render={(props) => (
                  <PageBuilderPage page="services" {...props} />
                )}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/services/:query`}
                render={(props) => {
                  return (
                    <Redirect
                      to={`/${this.props.language}/services/${props.match.params.query}`}
                    />
                  );
                }}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/search-results/:searchTerm`}
                render={(props) => (
                  <SearchResultsPage
                    results={searchResults}
                    searchTerm={searchTerm}
                    {...props}
                    resultsForLabel={this.props.resultsForLabel}
                    learnMoreLabel={this.props.learnMoreLabel}
                  />
                )}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/products/:productName/demo`}
                render={(props) => (
                  // <DemoPage {...props} country={this.props.country} />
                  <Redirect to={`/${this.props.language || "en"}/contact-us`} exact />
                )}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/products/:productName/demo/submitted`}
                render={(props) => <Redirect to={`/${this.props.language || "en"}/contact-us`} exact /> }
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/solutions/:query`}
                render={(props) => (
                  <PageBuilderPage
                    page="solutions"
                    {...props}
                    learnMoreLabel={this.props.learnMoreLabel}
                  />
                )}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/demo`}
                render={(props) => {
                  return <Redirect to={`/${this.props.language || "en"}/contact-us`} exact />;
                }}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/demo`}
                render={(props) => {
                  return <Redirect to={`/${this.props.language || "en"}/contact-us`} exact />;
                }}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/bim-services`}
                render={(props) => (
                  <PageBuilderPage
                    page="bim-services"
                    {...props}
                    learnMoreLabel={this.props.learnMoreLabel}
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/training`}
                exact
                render={(props) => (
                  <PageBuilderPage
                    page="training"
                    {...props}
                    learnMoreLabel={this.props.learnMoreLabel}
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/training/`}
                exact
                render={(props) => (
                  <Redirect to={`/${this.props.language || "en"}/training/`} />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/support`}
                render={(props) => <SupportPage {...props} />}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/submitted`}
                render={(props) => <SuccessPage {...props} />}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${language}/demo/submitted`}
                render={(props) => <SuccessPage {...props} />}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/demo/submitted`}
                render={(props) => <SuccessPage {...props} />}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/support/`}
                render={(props) => (
                  <Redirect
                    to={`/${this.props.language || "en"}/support/`}
                    exact
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/products/:productName`}
                render={(props) => (
                  <Redirect
                    to={`/${this.props.language || "en"}/products/${
                      props.match.params.productName
                    }`}
                    exact
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language || "en"}/admin`}
                component={AdminHomePage}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language || "en"}/admin/products`}
                component={AdminPage}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language || "en"}/admin/trades`}
                render={(props) => (
                  <AdminTradeCatPage type="trades" {...props} />
                )}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language || "en"}/admin/categories`}
                render={(props) => (
                  <AdminTradeCatPage type="categories" {...props} />
                )}
                exact
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language}/admin/products/:productName/:adminLang`}
                exact
                render={(props) => (
                  <ProductPage
                    {...props}
                    toggleRegionLanguageDeployed={
                      this.props.toggleRegionLanguageDeployed
                    }
                    admin
                    page="product-page"
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language || "en"}/contact`}
                exact
                render={(props) => (
                  // <DemoPage
                  //   {...props}
                  //   page="contact"
                  //   country={this.props.country}
                  // />
                  <Redirect
                    to={`/${this.props.language || "en"}/contact-us`}
                    exact
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language || "en"}/get-in-touch`}
                exact
                render={(props) => (
                  <Redirect
                    to={`/${this.props.language || "en"}/contact-us`}
                    exact
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language || "en"}/get-in-touch/submitted`}
                exact
                render={(props) => (
                  <Redirect
                    to={`/${this.props.language || "en"}/contact-us`}
                    exact
                  />
                )}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language || "en"}/bim-services-contact`}
                exact
                render={(props) => <BIMDemoPage {...props} />}
              />
              <Route
                hreflang={this.props.language ? this.props.language : "en"}
                path={`/${this.props.language ||
                  "en"}/bim-services-contact/submitted`}
                exact
                render={(props) => <SuccessPage {...props} />}
              />

              <Route
                path="*"
                hreflang={this.props.language ? this.props.language : "en"}
                render={(props) => {
                  this.props.updateErrorStatus(true);
                  return (
                    <FourZeroFourPage
                      languages={this.getSitewideLanguageCodes()}
                      location={this.props.location}
                    />
                  );
                }}
              />
            </Switch>
          )}

          {!this.props.language && (
            <Route
              hreflang={this.props.language ? this.props.language : "en"}
              component={Loading}
            />
          )}
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  errorStatus: state.errorStatus,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (status) => dispatch(updateLanguage(status)),
    updateOffsetY: (offsetY) => dispatch(updateOffsetY(offsetY)),
    updateAlert: () => dispatch(updateAlert(null)),
    updateErrorStatus: (errorStatus) =>
      dispatch(updateErrorStatus(errorStatus)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Routing));
