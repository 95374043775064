import basicFetch from "./basicFetch";
import {
  demoDataFormat,
  demoFormTaxonomy,
} from "../methods/contentSectionFormat";

export const demoLabelFetch = async (url, taxUrl, countryURL) => {
  const response = await basicFetch(url);

  const metadata = {
    title:
      response.data[0].metatags && response.data[0].metatags.title
        ? response.data[0].metatags.title
        : null,
  };

  const taxUrlResponse = await basicFetch(taxUrl);
  const formattedTaxonomy = demoFormTaxonomy(taxUrlResponse.data);

  const countryData = await basicFetch(countryURL);
  const formattedCountryData = formatCountryData(countryData.data);
  return {
    dropdowns: formattedTaxonomy,
    data: demoDataFormat(response.data[0]),
    countryData: formattedCountryData,
    metadata,
  };
};

const formatCountryData = (data) => {
  return data.map((country) => {
    return {
      isoCode: country.iso_code,
      name: country.display_label,
      states: country.admin_regions
        ? formatCountryStates(country.admin_regions)
        : undefined,
    };
  });
};

const formatCountryStates = (states = []) => {
  return states.map((state = {}) => {
    return { name: state.display_label, isoCode: state.iso_code };
  });
};
