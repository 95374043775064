import React from "react";
import Fade from "react-reveal/Fade";

export const renderCards = (cards = []) => {
  return cards.map((card, index) => {
    return (
      <Fade key={index} bottom delay={index * 200} distance="100px">
        <div
          className="mep-support-html-card"
          dangerouslySetInnerHTML={{
            __html: card,
          }}
        ></div>
      </Fade>
    );
  });
};
