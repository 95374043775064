import React from "react";
import "./SupportHTMLCards.scss";
import { renderCards } from "./SupportHTMLCardsMethods";

const SupportHTMLCards = ({ cards = [] }) => {
  return (
    <section className="mep-support-html-cards">{renderCards(cards)}</section>
  );
};

export default SupportHTMLCards;
