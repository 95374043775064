export default (keys, data) => {
  const content = keys.map(key => {
    return data[key][0];
  });

  // Find the video organism and move it to index 1
  const videoOrganism = content.find(organism => {
    return organism.video;
  });

  const indexOfVideo = content.indexOf(videoOrganism);

  const rearrangedOrganismsOne = rearrangeOrder(content, indexOfVideo, 1);

  // Find the feat 6 organism and move to the last index
  const feat6OrgIndex = content.indexOf(
    content.find(organism => {
      return organism.type === "feat_6";
    })
  );

  const rearrangedOrganismsTwo = rearrangeOrder(
    rearrangedOrganismsOne,
    feat6OrgIndex,
    content.length - 1
  );

  return setTheme(rearrangedOrganismsTwo);
};

const rearrangeOrder = (arr, old_index, new_index) => {
  // This moves things around an array - how does it work? I dunno! I copied/pasted from Stack Overflow don't @ me laura
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

const themes = [
  "theme-1",
  "theme-3",
  "theme-2",
  "theme-4",
  "theme-1",
  "theme-3",
  "theme-5"
];

const setTheme = organisms => {
  // This will set theme based on index of the organism
  return organisms.map((organism, index) => {
    organism.pattern = [themes[index]];

    return organism;
  });
};
