export const handleGetItem = (request, defaultResponse) => {
  try {
    const response = localStorage.getItem(request);

    if (response) {
      return JSON.parse(response) || defaultResponse;
    }
  } catch {
    console.log("localStorage disabled");
    return defaultResponse;
  }
};

export const handleSetItem = (key, request) => {
  try {
    localStorage.setItem(key, JSON.stringify(request));
  } catch {
    console.log("localStorage disabled");
  }
};

export const handleRemoveItem = (request) => {
  try {
    localStorage.removeItem(request);
  } catch {
    console.log("localStorage disabled");
  }
};
