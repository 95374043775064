import Logo from "../../assets/images/trimble-logo.svg";

export const rightLinks = [
  {
    url: "/#",
    text: "Support",
  },
];

export const logo = {
  url: Logo,
  altText: "Trimble Logo",
};

export const divisionName = "Geospatial";

export const location = "N. America";

export const navCTA = {
  className: "ter-button--primary--1",
  link: {
    url: "/#",
    text: "Contact Sales",
  },
};

/* We're not going to be able to do this at this level, because the page has this information */

export const innerPageNav = {
  pageTitle: "Trimble Accubid Enterprise",
  links: [
    {
      text: "Product Anchor One",
      url: "/#",
    },
    {
      text: "Product Anchor Two",
      url: "/#",
    },
  ],
};
