import basicFetch from "./basicFetch";

const adminPageFetch = async url => {
  const response = await basicFetch(url);
  const content = formatContent(response.data);
  return { content };
};

const formatContent = (products = []) => {
  const uniqueList = products
    // Map over all the products and get their non-language-specific data formatted - this will leave duplicates
    .reduce((accu, product = {}) => {
      const productData = {
        name: product.name,
        regions: product.regions,
        url: product["canonical_url"],
        translations: []
      };

      if (!accu.includes(productData)) {
        accu.push(productData);
      }
      return accu;
    }, [])
    .reduce((accu, product) => {
      // Remove duplicates - I couldn't get lodash to work, don't @ me
      // This probably could have been incorporated into the first reduce(), but my brain fell apart when trying to manage both tasks at once, okay? Okay.
      const duplicate = accu.reduce((result, accuProduct) => {
        if (product.name === accuProduct.name) {
          result = true;
        }
        return result;
      }, false);

      if (!duplicate) {
        accu.push(product);
      } else if (accu.length === 0) {
        accu.push(product);
      }
      return accu;
    }, []);

  products.forEach(product => {
    // Iterate over the products again, this time pushing a 'translation object' into the translations array of the corresponding product object in the unique list
    const uniqueProduct = uniqueList.find(uniqueProduct => {
      return uniqueProduct.name === product.name;
    });

    const cardData = {
      language: product.lang,
      teaserName: product["teaser_title"],
      teaserText: product["teaser_desc"],
      teaserImage: {
        url: product["teaser_media"],
        altText: product["teaser_title"]
      }
    };

    if (uniqueProduct && uniqueProduct.translations) {
      uniqueProduct.translations.push(cardData);
    }
  });

  return uniqueList;
};

export default adminPageFetch;
