import basicFetch from "./basicFetch";
import stgURL from "../helperObjects/envURLs";

const fetchRoutesData = async (language = "en/") => {
  const url = `${stgURL}${language}/api-v1/FetchAllURLandType`;
  const response = await basicFetch(url);
  return formatRoutesData(response.data.nodes, language);
};

const formatRoutesData = (data, language) => {
  return data
    .filter((point) => {
      return (
        point.type === "page_builder" ||
        point.type === "product_category" ||
        point.type === "solutions_page" ||
        point.type === "collection_page" ||
        point.type === "resource" ||
        point.type === "challenge_page" ||
        point.type === "support_page" ||
        point.type === "service_category_page" ||
        point.type === "external_category_page" ||
        point.type === "bim-services"
      );
    })
    .map((point) => {
      return point.url.replace(`${language}/`, "");
    });
};

export default fetchRoutesData;
