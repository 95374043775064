const contentSectionFormat = (content) => {
  const keys = Object.keys(content);
  const data = keys.reduce((accu, key) => {
    // This is a weird thing that is showing up in Drupal that is bad and React doesn't like it and isn't necessary for React to know.
    if (key.includes("debug")) {
      return accu;
    }

    const section = content[key];

    // Drupal JSON comes back in some objects; this formats them to make them digestable in <MapContent>

    if (section) {
      const keys = Object.keys(section);

      const numKey = keys.find((key) => {
        return key !== "sectionName";
      });

      if (typeof section[numKey] === "object") {
        section[numKey].sectionName = key;
      }

      accu.push(section[numKey]);
    }

    return accu;
  }, []);

  return data;
};

export default contentSectionFormat;

export const formatInnerNavData = (data) => {
  if (data) {
    return { pageTitle: data.page_name, links: formatAnchors(data.anchors) };
  } else {
    return { pageTitle: "", links: [] };
  }
};

const formatAnchors = (anchors) => {
  if (!anchors) {
    return [];
  }
  return anchors.map((anchor) => {
    // need to remove the # for anchors to work.
    if (anchor.url[0] === "#") {
      return {
        text: anchor.text,
        url: anchor.url.slice(1),
      };
    } else {
      return {
        text: anchor.text,
        url: anchor.url,
      };
    }
  });
};

export const formatPageBuilderData = (data, page) => {
  if (data && data.hero) {
    const organisms = [data.hero[Object.keys(data.hero)]];

    const keys = Object.keys(data.organisms);

    keys.forEach((key) => {
      organisms.push(data.organisms[key]);
    });

    organisms.forEach((organism) => {
      // added this bc a white screen happened due to one of the organisms missing .type when testing
      if (organism && organism.type) {
        if (
          organism.type &&
          organism.type === "feat_4_flex" &&
          page === "home"
        ) {
          organism.type = "home-page-trades";

          // Set the header text
          if (organism.field_molecules_0 && organism.field_molecules_0.value) {
            const headerDataOne = Object.values(
              organism.field_molecules_0.value
            );
            const headerDataTwo = Object.values(headerDataOne[0])[0];
            if (
              headerDataTwo.field_header_text &&
              headerDataTwo.field_header_text.value
            ) {
              const headerDataThree = headerDataTwo.field_header_text.value;
              if (headerDataThree[0] && headerDataThree[0].value) {
                organism.header = headerDataThree[0].value;
              }
            }
          }
          if (
            organism.field_color_scheme &&
            organism.field_color_scheme.value
          ) {
            organism.theme = organism.field_color_scheme.value;
          } else {
            organism.theme = "theme-1";
          }

          // Format the cards
          if (organism.field_molecules_1 && organism.field_molecules_1.value) {
            const cards = organism.field_molecules_1.value
              .map((card) => {
                return Object.values(card)[0];
              })
              .map((card) => {
                const returnObj = {};

                if (
                  card.field_header &&
                  card.field_header.value &&
                  card.field_header.value[0] &&
                  card.field_header.value[0].value
                ) {
                  returnObj.header = card.field_header.value[0].value;
                }

                if (card.field_card_media && card.field_card_media.source) {
                  const image = {
                    url: card.field_card_media.source,
                    alt: card.field_card_media.alt || "",
                  };
                  returnObj.image = image;
                }
                if (
                  card.field_text &&
                  card.field_text.value &&
                  card.field_text.value[0] &&
                  card.field_text.value[0].value
                ) {
                  returnObj.text = card.field_text.value[0].value;
                }
                if (
                  card.field_secondary_cta &&
                  card.field_secondary_cta.url &&
                  card.field_secondary_cta.text
                ) {
                  returnObj.link = {
                    url: card.field_secondary_cta.url,
                    text: card.field_secondary_cta.text,
                  };
                }

                return returnObj;
              });
            if (cards) {
              organism.cards = cards;
            }
          }
        }
      }
    });

    return organisms;
  } else {
    return [];
  }
};

export const getCountrySettings = (regionSettings, currentRegion, admin) => {
  if (admin) {
    return true;
  } else if (regionSettings) {
    if (regionSettings.country_exclude) {
      if (
        regionSettings.countries.find((region) => {
          return region.name === currentRegion;
        })
      ) {
        return false;
      }
    } else if (!regionSettings.country_exclude) {
      if (
        regionSettings.countries.find((region) => {
          return region.name === currentRegion;
        })
      ) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  } else {
    return true;
  }
};

export const formatErrorData = (errorData, lang) => {
  const key = Object.keys(errorData)[0];

  const data = errorData[key];
  data.ctas.ctaOne.url = `/${lang}`;

  return [errorData[key]];
};

export const format404Data = (unformatedData) => {
  const key = Object.keys(unformatedData)[0];

  unformatedData[key].ctas.ctaTwo = null;

  return unformatedData[key];
};

export const demoDataFormat = (data) => {
  const textInputs = data.content_sections.text_inputs.map((input) => {
    input.type = "text";
    input.machineName = input.machine_name;
    return input;
  });
  const dropdowns = data.content_sections.dropdowns.map((dropdown) => {
    dropdown.type = "dropdown";
    dropdown.machineName = dropdown.machine_name;

    if (
      dropdown.machineName === "product_category" ||
      dropdown.machineName === "trade"
    ) {
      dropdown.required = true;
    }
    return dropdown;
  });
  const inputs = [...textInputs, ...dropdowns];

  const utils =
    data.content_sections && data.content_sections.demo_form_utility_data
      ? data.content_sections.demo_form_utility_data
      : null;

  return { inputs, utils };
};

export const demoFormTaxonomy = (data = []) => {
  return data.map((trade) => {
    return {
      name: trade.name,
      machineName: trade.machine_name,
      categories: formatDemoCategories(trade.children),
    };
  });
};

const formatProducts = (data) => {
  return data.map((product) => {
    return {
      name: product.name || null,
      machineName: product.machine_name || null,
      regions: product.region || null,
    };
  });
};

const formatDemoCategories = (data) => {
  return data.map((cat) => {
    return {
      name: cat.name || null,
      machineName: cat.machine_name || null,
      products: cat.machine ? formatProducts(cat.children) : null,
    };
  });
};

export const formatCTAOverride = (data = {}) => {
  if (
    data.nav_cta_override &&
    data.nav_cta_override.toggle &&
    data.nav_cta_override.link &&
    data.nav_cta_override.link.url &&
    data.nav_cta_override.link.text
  ) {
    return {
      url: data.nav_cta_override.link.url,
      text: data.nav_cta_override.link.text,
    };
  } else {
    return null;
  }
};
