import basicFetch from "./basicFetch";
import stgURL from "../helperObjects/envURLs";

const tradeFetch = async (lang) => {
  const url = `${stgURL}${lang ||
    "en"}/api-v1/FetchTaxonomyByMachineName/mep_trades`;
  const response = await basicFetch(url);

  const trades = response.data.map((trade) => {
    return trade.machine;
  });

  const categories = await categoryFetch();
  const languages = await languageFetch();

  return { trades, categories, languages };
};

export default tradeFetch;

const categoryFetch = async (lang) => {
  const url = `${stgURL}${lang ||
    "en"}/api-v1/FetchTaxonomyByMachineName/mep_product_categories`;
  const response = await basicFetch(url);

  const categoryNames = response.data.map((category) => {
    return category.machine;
  });

  return categoryNames.filter((category) => {
    return Boolean(category);
  });
};

const languageFetch = async () => {
  const languages = ["en", "de", "es", "ja", "fr", "nl"];

  return Promise.resolve(languages);
};
