import basicFetch from "./basicFetch";
import formatSupportPageData from "../methods/formatSupportPageData";
import stgURL from "../helperObjects/envURLs";

export default async (lang) => {
  const response = await basicFetch(
    `${stgURL}${lang}/api-v1/GetNodeByURL/support/$`
  );

  const {
    content,
    innerNavData,
    metadata,
    ctaOverride,
  } = formatSupportPageData(response);
  return { content, innerNavData, metadata, ctaOverride };
};
