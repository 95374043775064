import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { BrowserRouter } from "react-router-dom";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as Sentry from "@sentry/browser";
import TagManager from "react-gtm-module";
if (process && process.env && process.env.NODE_ENV === "development") {
  // DON'T CALL TagManager
} else {
  const tagManagerArgs = {
    gtmId: "GTM-MXDGR2F",
    dataLayerName: "dataLayer",
  };
  TagManager.initialize(tagManagerArgs);
}

const store = createStore(rootReducer, composeWithDevTools());

const ignoredErrors = [
  "Network Error",
  "MktoForms2 is not defined",
  "Can't find variable: MktoForms2",
  "'MktoForms2' is undefined",
  `$ is not a function. (In '$("#addToCartButton")', '$' is undefined)`,
  "Request failed with status code 404",
  "$ is not a function",
];

Sentry.init({
  dsn: "https://7cc0120812bb4e9fb5a65a72d1b5e127@sentry.io/1827050",
  release: "mep-frontend@" + process.env.npm_package_version,
  beforeSend(event, hint) {
    // Modify the event here
    const error = hint?.originalException?.message;

    if (ignoredErrors.includes(error)) {
      return null;
    }

    return event;
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
