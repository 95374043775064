export const parallaxOrganisms = [
  "feat_1",
  "drupal_view",
  "feat_7",
  "feat_8",
  "feat_6"
];

export const isEven = n => {
  return n % 2 === 0;
};

export const isParallax = (organismsLength, index) => {
  if (index === 1 || (index + 1 === organismsLength && organismsLength !== 3)) {
    if (index !== 2) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
