import fetchFromURL from "../async/basicFetch";
import contentSectionFormat, {
  formatInnerNavData,
  formatPageBuilderData,
  formatCTAOverride,
} from "../../utils/methods/contentSectionFormat";
import formatBIMServicesContent from "../methods/formatBIMServicesContent";

// The organization of this file is bad, and you should feel bad, Kevin. -KS

export const formattedPageFetch = async (url, pageBuilder, page) => {
  const response = await fetchFromURL(url);
  if (
    // sometimes the 404 shows up as response.data[0] and other times it is response.data.status_code.
    (response.data && response.data.status_code === "404") ||
    (response.data && response.data[0] && response.data[0] === "404")
  ) {
    return { error: 404 };
  }

  // check for redirect and if redirect exists, go to redirect
  if (response?.data?.status_code === "301") {
    if (
      response?.data?.redirect_destination &&
      response?.data?.redirect_destination.length
    ) {
      window.open(response?.data?.redirect_destination[0], "_self");
    }
  }

  const innerNavData = formatInnerNavData(response.data[0]);
  const ctaOverride = formatCTAOverride(response.data[0]);

  let content;

  if (!pageBuilder) {
    content =
      response.data && response.data[0] && response.data[0].content_sections
        ? contentSectionFormat(response.data[0].content_sections)
        : null;
  } else {
    content =
      response.data && response.data[0] && response.data[0].content_sections
        ? formatPageBuilderData(response.data[0].content_sections, page)
        : null;
  }
  const metadata =
    response.data && response.data[0] && response.data[0].metatags
      ? response.data[0].metatags
      : null;

  if (
    response.data &&
    response.data[0] &&
    response.data[0].type === "product_page"
  ) {
    const regionSettings = response.data[0].country_settings;

    return { innerNavData, content, metadata, regionSettings, ctaOverride };
  } else {
    return { innerNavData, content, metadata, ctaOverride };
  }
};

export const bimServicesPageFetch = async (url) => {
  const response = await fetchFromURL(url);

  const data = {};

  if (
    response &&
    response.data &&
    response.data[0] &&
    response.data[0].content_sections
  ) {
    const keys = Object.keys(response.data[0].content_sections);
    data.content = formatBIMServicesContent(
      keys,
      response.data[0].content_sections
    );
  }

  if (
    response &&
    response.data &&
    response.data[0] &&
    response.data[0].metatags
  ) {
    data.metadata = response.data[0].metatags;
  }

  data.ctaOverride = formatCTAOverride(response.data[0]);

  const innerNavData = formatInnerNavData(response.data[0]);

  if (innerNavData) {
    data.innerNavData = innerNavData;
  }

  return data;
};

export const pageBuilderPageFetch = async (url) => {
  const response = await fetchFromURL(url);
  return response;
};
