//TODO PageBuilder - Set offsetX off of trade image due to inconsistent negative space

import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Element } from "react-scroll";
import { connect } from "react-redux";
import {
  Feat1,
  Feat2,
  Feat3,
  Feat4,
  Feat5,
  Feat6,
  Feat8,
  Feat9,
  Feat10,
  VideoFeat,
  Hero1,
  Hero2,
  Hero3,
  Hero4,
  Hero5,
  Hero6,
  Feat4Flex,
  CustomFeat,
} from "@trimble-creative-strategy/luna-component-lib";
import mapTabs from "../../utils/methods/mapTabs";
import "./PageBuilder.scss";
import mechanicalPattern5 from "../../assets/patterns/MEP-Mechanical-5.svg";
import mechanicalPattern4 from "../../assets/patterns/MEP-Mechanical-4.svg";
import mechanicalPattern3 from "../../assets/patterns/MEP-Mechanical-3.svg";
import mechanicalPattern2 from "../../assets/patterns/MEP-Mechanical-2.svg";
import mechanicalPattern1 from "../../assets/patterns/MEP-Mechanical-1.svg";
import mechanicalPattern0 from "../../assets/patterns/MEP-Mechanical-0.svg";

import electricalPattern5 from "../../assets/patterns/MEP-Electrical-5.svg";
import electricalPattern4 from "../../assets/patterns/MEP-Electrical-4.svg";
import electricalPattern3 from "../../assets/patterns/MEP-Electrical-3.svg";
import electricalPattern2 from "../../assets/patterns/MEP-Electrical-2.svg";
import electricalPattern1 from "../../assets/patterns/MEP-Electrical-1.svg";
import electricalPattern0 from "../../assets/patterns/MEP-Electrical-0.svg";

import plumbingPattern5 from "../../assets/patterns/MEP-Plumbing-5.svg";
import plumbingPattern4 from "../../assets/patterns/MEP-Plumbing-4.svg";
import plumbingPattern3 from "../../assets/patterns/MEP-Plumbing-3.svg";
import plumbingPattern2 from "../../assets/patterns/MEP-Plumbing-2.svg";
import plumbingPattern1 from "../../assets/patterns/MEP-Plumbing-1.svg";
import plumbingPattern0 from "../../assets/patterns/MEP-Plumbing-0.svg";

import defaultPattern0 from "../../assets/patterns/MEP-Electrical-0.svg";
import defaultPattern1 from "../../assets/patterns/MEP-Electrical-1.svg";
import defaultPattern2 from "../../assets/patterns/MEP-Electrical-2.svg";
import defaultPattern3 from "../../assets/patterns/MEP-Electrical-3.svg";
import defaultPattern4 from "../../assets/patterns/MEP-Electrical-4.svg";
import defaultPattern5 from "../../assets/patterns/MEP-Electrical-5.svg";

// These imports are required for cleaning and formatting JSON to fit properly into the components don't @ me.
import {
  generatePatternData,
  featOneImageCheck,
  cleanFeatFourFlexCards,
  formatHeroOneImages,
  generateHeroFiveImage,
  cleanFeatEightContent,
  generateFeatEightCTAs,
  cleanCTAData,
  generateFeatThreeItems,
  formatVideoThumbnail,
  formatFeatNineContent,
  generateFeatThreeHeader,
  generateFeatThreeImages,
  generateFeatFourFlexHeader,
  formatFeatFourCards,
  formatBIMCards,
  formatHomePageTradesCards,
  formatCTAsForDemoLink,
  formatFeatTwoRedirectData,
  formatHTMLCards,
} from "../../utils/methods/pageBuilderMethods";
import { isParallax } from "../../utils/helperObjects/parallaxHelpers";
import SupportHTMLCards from "../../components/SupportHTMLCards/SupportHTMLCards";

class PageBuilder extends Component {
  constructor() {
    super();

    this.state = {
      randomTheme: [
        Math.floor(Math.random() * 3),
        Math.floor(Math.random() * 3),
      ],
      backgroundImageHeight: 1800,
      backgroundImageWidth: 3600,
    };
  }

  getMapMethod = (type) => {
    // return the organism generator method that matches the appropriate type
    const organismRefs = {
      feat_1: this.generateFeatOne,
      feat_2: this.generateFeatTwo,
      feat_3: this.generateFeatThree,
      feat_4: this.generateFeatFour,
      feat_5: this.generateFeatFive,
      feat_6: this.generateFeatSix,
      feat_7: this.generateVideoFeat,
      feat_8: this.generateFeatEight,
      feat_9: this.generateFeatNine,
      feat_10: this.generateFeatTen,
      feat_10_list_items: this.generateFeatTen,
      hero_1: this.generateHeroOne,
      hero_2: this.generateHeroTwo,
      hero_3: this.generateHeroThree,
      hero_4: this.generateHeroFour,
      hero_5: this.generateHeroFive,
      hero_6: this.generateHeroSix,
      drupal_view: this.generateFeatFourFlex,
      "home-page-trades": this.generateHomePageTrades,
      feat_4_flex: this.generateBIMFeatFourFlex,
      support_page_wysiwyg: this.generateSupportHTMLCards,
      feat_custom: this.generateCustomFeat,
    };

    return organismRefs[type];
  };

  getPattern = (theme = "theme-1", index = 0) => {
    // Returns the appropriate pattern for an organism depending on its assigned theme and corresponding trade
    const trade = this.props.trade || "default";

    const newPatterns = {
      electrical: {
        "theme-1": electricalPattern1,
        "theme-2": electricalPattern2,
        "theme-3": electricalPattern3,
        "theme-4": electricalPattern4,
        "theme-5": electricalPattern5,
        default: electricalPattern0,
      },
      mechanical: {
        "theme-1": mechanicalPattern1,
        "theme-2": mechanicalPattern2,
        "theme-3": mechanicalPattern3,
        "theme-4": mechanicalPattern4,
        "theme-5": mechanicalPattern5,
        default: mechanicalPattern0,
      },
      plumbing: {
        "theme-1": plumbingPattern1,
        "theme-2": plumbingPattern2,
        "theme-3": plumbingPattern3,
        "theme-4": plumbingPattern4,
        "theme-5": plumbingPattern5,
        default: plumbingPattern0,
      },
      default: {
        "theme-1": defaultPattern1,
        "theme-2": defaultPattern2,
        "theme-3": defaultPattern3,
        "theme-4": defaultPattern4,
        "theme-5": defaultPattern5,
        default: defaultPattern0,
      },
    };

    const themes = {
      "theme-1": [electricalPattern1, mechanicalPattern1, plumbingPattern1],
      "theme-2": [electricalPattern2, mechanicalPattern2, plumbingPattern2],
      "theme-3": [electricalPattern3, mechanicalPattern3, plumbingPattern3],
      "theme-4": [electricalPattern4, mechanicalPattern4, plumbingPattern4],
      "theme-5": [electricalPattern5, mechanicalPattern5, plumbingPattern5],
    };

    if (trade === "default") {
      // If no corresponding trade, assign one of the trades at random
      const slot = index === 1 ? 0 : 1;
      return themes[theme][this.state.randomTheme[slot]];
    }

    return newPatterns[trade][theme] || defaultPattern0;
  };

  mapContent = () => {
    // iterate over all the content from Drupal and return a component organism for each item
    const { content } = this.props;

    return content.reduce((accu, item, index) => {
      if (!item || !item.type) {
        return accu;
      }
      const method = this.getMapMethod(item.type);

      if (method) {
        // if there is a generation method that matches the organism type, return the method, call it, and push it into the array to render.

        const organism = method(item, index);

        if (organism) {
          accu.push(organism);
        }
      }

      return accu;
    }, []);
  };

  generateFeatOne = (content, index) => {
    const pattern = generatePatternData(content.pattern);
    const parallax = isParallax(this.props.content.length, index);
    const theme = pattern.theme || content.theme;

    let newTheme;

    if (this.props.page && this.props.page === "training") {
      if (index === 2) {
        newTheme = "theme-4";
      }
    }

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat1"}-${index}`}
      >
        <Feat1
          header={content.header}
          subHeader={content.subHeader}
          text={content.text}
          cta={content.cta}
          ctas={content.ctas}
          image={featOneImageCheck(content.image_large)}
          variant={
            content.variant && content.variant === "right"
              ? content.variant
              : "b"
          }
          backgroundColor={content.backgroundColor}
          insertHTML={true}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          theme={newTheme ? newTheme : pattern.theme}
          backgroundImage={
            parallax
              ? this.getPattern(newTheme ? newTheme : theme, index)
              : content.backgroundImage
          }
          backgroundPosition={index === 1 ? "top-right" : "top-left"}
          backgroundImageHeight={this.state.backgroundImageHeight}
          backgroundImageWidth={this.state.backgroundImageWidth}
          backgroundOffsetY={pattern.offsetY}
          backgroundOffsetX={index === 1 ? 700 : 0}
          parallaxPosition={parallax ? this.props.parallaxPosition : null}
        />
      </Element>
    );
  };

  generateFeatTwo = (content = {}, index) => {
    const pattern = generatePatternData(content.pattern);

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat2"}-${index}`}
      >
        <Feat2
          header={content.header}
          text={content.text}
          image={content.image_large}
          backgroundColor={content.backgroundColor}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          theme={pattern.theme}
        >
          {content.children}
        </Feat2>
      </Element>
    );
  };

  generateFeatThree = (content, index) => {
    if (content && content.list && content.list["accordion-0"]) {
      const formattedContent = formatFeatTwoRedirectData(content);
      return this.generateFeatTwo(formattedContent, index);
    }

    const images = generateFeatThreeImages(content);

    const pattern = generatePatternData(content.pattern);

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat3"}-${index}`}
      >
        <Feat3
          header={generateFeatThreeHeader(content)}
          text={content.text || content.subHeader || null}
          cta={content.cta}
          content={generateFeatThreeItems(content.list)}
          images={images}
          backgroundColor={content.backgroundColor}
          insertCardHTML={true}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          theme={pattern.theme}
        />
      </Element>
    );
  };

  generateFeatFour = (content = {}, index) => {
    const pattern = generatePatternData(content.pattern);

    const cards = formatFeatFourCards(content.list);
    // Theme is hard-coded to 'theme-4' so it doesn't blend into other organisms on homepage
    let newTheme;

    if (this.props.page && this.props.page === "home") {
      newTheme = "theme-3";
    } else if (this.props.page && this.props.page === "bim-services") {
      newTheme = "theme-5";
    }

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat4"}-${index}`}
      >
        <Feat4
          header={content.header}
          text={content.text}
          cta={content.cta}
          content={cards}
          columns={content.columns || 3}
          image={content.image_large}
          variant={content.variant}
          backgroundColor={content.backgroundColor}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          theme={newTheme ? newTheme : pattern.theme || "theme-4"}
        />
      </Element>
    );
  };

  generateFeatFourFlex = (content, index) => {
    const pattern = generatePatternData(content.pattern);
    const cards = cleanFeatFourFlexCards(
      content,
      this.props.category,
      this.props.trade,
      this.props.sort,
      this.props.learnMoreLabel
    );
    let filteredCards;

    if (this.props.admin) {
      // Don't filter cards if looking at an admin page
      filteredCards = cards;
    } else if (
      (this.props.category && content.sectionName === "cat_products_section") ||
      (this.props.trade && content.sectionName === "trade_category_section")
    ) {
      filteredCards = cards.filter((card) => {
        if (card.regions && card.regions.country_exclude === false) {
          // Filter out cards that are marked for region inclusion that don't have the matching region in redux
          return card.regions.countries.reduce((accu, region) => {
            if (
              region &&
              region.name &&
              region.name.toLowerCase() === this.props.region.toLowerCase()
            ) {
              accu = true;
            }
            return accu;
          }, false);
        } else if (card.regions && card.regions.country_exclude === true) {
          // Filter out the cards that are marked for region exclusion that match the region in redux
          return card.regions.countries.reduce((accu, region) => {
            if (
              region &&
              region.name &&
              region.name.toLowerCase() === this.props.region.toLowerCase()
            ) {
              accu = false;
            }
            return accu;
          }, true);
        } else {
          return card;
        }
      });
    } else {
      filteredCards = cards;
    }

    const noProductsData = this.props.noProductsData || {};

    if (filteredCards.length === 0 || !filteredCards) {
      filteredCards = [
        {
          header: noProductsData.header,
          text: noProductsData.text,
          link: noProductsData.link,
        },
      ];
    }

    const parallax = isParallax(this.props.content.length, index);
    const theme = pattern.theme || content.theme;
    let newTheme;

    if (this.props.page && this.props.page === "bim-services") {
      newTheme = "theme-3";
    } else if (this.props.page && this.props.page === "services") {
      newTheme = "theme-3";
    }

    return (
      <Element name={content.sectionName} key={`mep-feat-four-flex-${index}`}>
        <Feat4Flex
          header={generateFeatFourFlexHeader(content)}
          cards={filteredCards}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          theme={newTheme ? newTheme : theme}
          backgroundImage={
            parallax
              ? this.getPattern(newTheme ? newTheme : theme, index)
              : content.backgroundImage
          }
          backgroundPosition={index === 1 ? "top-right" : "top-left"}
          backgroundImageHeight={this.state.backgroundImageHeight}
          backgroundImageWidth={this.state.backgroundImageWidth}
          backgroundOffsetY={pattern.offsetY}
          backgroundOffsetX={index === 1 ? 700 : 0}
          parallaxPosition={parallax ? this.props.parallaxPosition : null}
        />
      </Element>
    );
  };

  generateFeatFive = (content, index) => {
    const pattern = generatePatternData(content.pattern);

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat5"}-${index}`}
      >
        <Feat5
          header={content.header}
          subHeader={content.subHeader}
          dumplings={content.dumplings}
          type={content.type || "a"}
          image={content.image_large}
          variant={content.variant}
          backgroundColor={content.backgroundColor}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          theme={pattern.theme}
        />
      </Element>
    );
  };

  generateFeatSix = (content, index) => {
    let newTheme;

    if (this.props.page && this.props.page === "home") {
      if (index === 4) {
        newTheme = "theme-4";
      } else if (index === 6) {
        newTheme = "theme-3";
      }
    } else if (
      (this.props.page && this.props.page === "trade") ||
      (this.props.page && this.props.page === "category")
    ) {
      newTheme = "theme-3";
    } else if (this.props.page && this.props.page === "product-page") {
      if (index === 8) {
        newTheme = "theme-4";
      }
    } else if (this.props.page && this.props.page === "services") {
      newTheme = "theme-4";
    }
    const pattern = generatePatternData(content.pattern);
    const parallax = isParallax(this.props.content.length, index);
    const theme = pattern.theme || content.theme;
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat6"}-${index}`}
      >
        <Feat6
          header={content.header}
          subHeader={content.subHeader}
          ctas={content.ctas}
          backgroundColor={content.backgroundColor}
          theme={newTheme ? newTheme : theme}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          backgroundImage={
            parallax
              ? this.getPattern(newTheme ? newTheme : theme, index)
              : null
          }
          backgroundPosition={index === 1 ? "top-right" : "top-left"}
          backgroundImageHeight={this.state.backgroundImageHeight}
          backgroundImageWidth={this.state.backgroundImageWidth}
          backgroundOffsetY={pattern.offsetY}
          backgroundOffsetX={index === 1 ? 700 : 0}
          parallaxPosition={parallax ? this.props.parallaxPosition : null}
        />
      </Element>
    );
  };

  generateFeatSeven = (content, index) => {
    const video = {
      ...content.video,
      url: content.video.fullurl,
      uuid: content.video.id,
    };
    const pattern = generatePatternData(content.pattern);
    const parallax = isParallax(this.props.content.length, index);
    const theme = pattern.theme || content.theme;
    const vidyard = content.video.service === "vidyard" ? true : false;

    // Sometimes this comes as .text, sometimes as .subHeader, never both.
    const subHeader = content.subHeader || content.text;

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "video-feat"}-${index}`}
      >
        <VideoFeat
          thumbnail={formatVideoThumbnail(content.thumbnail)}
          header={content.header}
          subHeader={subHeader}
          video={video}
          backgroundColor={content.backgroundColor}
          id={content.sectionName}
          motion={this.props.motion}
          zIndex={index + 1}
          motionDirection={this.props.motionDirection}
          theme={pattern.theme}
          backgroundImage={parallax ? this.getPattern(theme, index) : null}
          backgroundPosition={index === 1 ? "top-right" : "top-left"}
          backgroundImageHeight={this.state.backgroundImageHeight}
          backgroundImageWidth={this.state.backgroundImageWidth}
          backgroundOffsetY={pattern.offsetY}
          backgroundOffsetX={index === 1 ? 900 : 200}
          parallaxPosition={parallax ? this.props.parallaxPosition : null}
          vidyard={vidyard}
        />
      </Element>
    );
  };

  generateFeatEight = (content, index) => {
    if (!content.list) {
      content.list = [];
    }
    // Clean up the card data and make it servicable for Feat8
    const cards = cleanFeatEightContent(
      content,
      this.props.category,
      this.props.trade,
      this.props.learnMoreLabel
    );

    // external category page URLs were getting weird once it got to this point
    // urls looked like "mechanical-solutions/field-technology/https://fieldtech.trimble.com...."
    if (cards) {
      cards.map((card) => {
        if (
          card.buttonLink &&
          card.buttonLink.url &&
          card.buttonLink.url.includes("https://")
        ) {
          const newPath = card.buttonLink.url.split("https://");
          if (newPath[1].includes("fieldtech.trimble.com")) {
            card.buttonLink.url = `https://${newPath[1]}`;
          }
        }
      });
    }

    // Clean up the CTA data and make it servicable for Feat8
    const ctas = generateFeatEightCTAs(content.ctas);

    if (!content.header && !cards) {
      // If there are no cards and no header, just return an empty fragment
      return <Fragment key={`fragment-${index}`}> </Fragment>;
    }

    const pattern = generatePatternData(content.pattern);
    const parallax = isParallax(this.props.content.length, index);
    const theme = pattern.theme || content.theme;
    let newTheme;

    if (this.props.page && this.props.page === "product-page") {
      newTheme = "theme-5";
    }

    const checkforTrainingPage = () => {
      const checkforMobile = () => {
        if (
          /Mobi|Android/i.test(navigator.userAgent) ||
          /Mobi|iPad|iPhone|iPod/i.test(navigator.userAgent)
        ) {
          return true;
        } else {
          return false;
        }
      };

      if (
        this.props.location &&
        this.props.location.pathname &&
        this.props.location.pathname.includes(
          `/${this.props.language}/training`
        ) &&
        checkforMobile()
      ) {
        return false;
      } else {
        return this.props.motion;
      }
    };

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat8"}-${index}`}
      >
        <Feat8
          header={content.header}
          buttonLink={content.cta}
          cards={cards}
          body={content.text}
          subHeader={content.subHeader}
          backgroundColor={content.backgroundColor}
          ctas={ctas}
          id={content.sectionName}
          motion={checkforTrainingPage()}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          theme={newTheme ? newTheme : theme}
          CTAType={content.CTAType || "ter-button--primary--1"}
          backgroundImage={
            parallax
              ? this.getPattern(newTheme ? newTheme : theme, index)
              : null
          }
          backgroundPosition={index === 1 ? "top-right" : "top-left"}
          backgroundImageHeight={this.state.backgroundImageHeight}
          backgroundImageWidth={this.state.backgroundImageWidth}
          backgroundOffsetY={pattern.offsetY}
          backgroundOffsetX={index === 1 ? 900 : 200}
          parallaxPosition={parallax ? this.props.parallaxPosition : null}
        />
      </Element>
    );
  };

  generateFeatNine = (content, index) => {
    const pattern = generatePatternData(content.pattern);

    let image;
    // ^ What's going on with this ^
    // Declaration with no assignment, passed as a prop below?

    const data = formatFeatNineContent(content);

    if (!data) {
      return <React.Fragment key={`feat-nine-${index}`} />;
    }

    return (
      <Element name={content.sectionName} key={`feat-nine-${index}`}>
        <Feat9
          imageSide={content.imageSide || "right"}
          quote={data.quote}
          cta={data.cta}
          image={image}
          variant={content.variant}
          backgroundColor={content.backgroundColor}
          theme={pattern.theme}
          insertHTML={true}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
        />
      </Element>
    );
  };

  generateFeatTen = (content, index) => {
    let theme;

    if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat10"}-${index}`}
      >
        <Feat10
          header={content.header}
          subHeader={content.subHeader}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          theme={theme}
          zIndex={index + 1}
        >
          {mapTabs(content.tabs, theme)}
        </Feat10>
      </Element>
    );
  };

  generateHeroOne = (content, index) => {
    const images = formatHeroOneImages(content);
    const ctas = cleanCTAData(content.ctas);
    let theme;

    if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "hero1"}-${index}`}
      >
        <Hero1
          header={content.header}
          text={content.subHeader}
          ctas={ctas}
          images={images}
          variant={content.variant}
          backgroundImage={content.backgroundImage}
          backgroundColor={content.backgroundColor}
          theme={theme}
          contentSide={content.imageSide === "right" ? "right" : "left"}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
        />
      </Element>
    );
  };

  generateHeroTwo = (content, index) => {
    let theme;

    if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "hero2"}-${index}`}
      >
        <Hero2
          header={content.header}
          subHeader={content.subHeader}
          text={content.text}
          ctas={content.ctas}
          images={content.images}
          variant={content.variant || "a"}
          backgroundImage={content.backgroundImage}
          backgroundColor={content.backgroundColor}
          theme={theme}
          imageSide={content.imageSide}
          id={content.sectionName}
          motion={this.props.motion}
          zIndex={index + 1}
          motionDirection={this.props.motionDirection}
        />
      </Element>
    );
  };

  generateHeroThree = (content, index) => {
    let theme;

    if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "hero3"}-${index}`}
      >
        <Hero3
          header={content.header}
          subHeader={content.subHeader}
          images={content.images}
          backgroundImage={content.backgroundImage}
          backgroundColor={content.backgroundColor}
          theme={theme}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
        />
      </Element>
    );
  };

  generateHeroFour = (content = {}, index) => {
    let theme;

    if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }
    const vidyard =
      content.video && content.video.service === "vidyard" ? true : false;
    const video = {
      ...content.video,
      url: content.video.fullurl,
      uuid: content.video.id,
    };
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "hero4"}-${index}`}
      >
        <Hero4
          header={content.header}
          video={video}
          text={content.text}
          ctas={content.ctas}
          backgroundImage={content.backgroundImage}
          backgroundColor={content.backgroundColor}
          theme={theme}
          thumbnail={content.thumbnail}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          vidyard={vidyard}
        />
      </Element>
    );
  };

  generateHeroFive = (content, index) => {
    let theme;

    if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }

    let ctas = cleanCTAData(content.ctas);
    if (this.props.page && this.props.page === "product-page") {
      const path = this.props.history.location.pathname;
      ctas = formatCTAsForDemoLink(ctas, path);
    }

    let newTheme;

    if (this.props.page && this.props.page === "services") {
      newTheme = "theme-1";
    }

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "hero5"}-${index}`}
      >
        <Hero5
          optionalHeader={
            content.intro && content.intro.header ? content.intro.header : null
          }
          optionalSubheader={
            content.intro && content.intro.subHeader
              ? content.intro.subHeader
              : null
          }
          image={generateHeroFiveImage(content)}
          header={content.header}
          subHeader={content.subHeader}
          text={content.text}
          ctas={ctas === {} ? null : ctas}
          backgroundImage={content.backgroundImage}
          backgroundColor={content.backgroundColor}
          theme={newTheme ? newTheme : theme}
          insertHTML={true}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
        />
      </Element>
    );
  };

  generateHeroSix = (content, index) => {
    // I don't think this is actually used on the site at all

    let theme;

    if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "hero6"}-${index}`}
      >
        <Hero6
          header={content.header}
          subHeader={content.subHeader}
          text={content.text}
          ctas={content.ctas}
          backgroundImage={content.backgroundImage}
          backgroundColor={content.backgroundColor}
          theme={theme}
          imageSide={content.imageSide || "right"}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
        />
      </Element>
    );
  };

  generateVideoFeat = (content, index) => {
    // AKA Feat7

    const video = {
      ...content.video,
      url: content.video.fullurl,
      uuid: content.video.id,
    };
    let theme;

    if (content.theme) {
      theme = content.theme;
    } else if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }

    const vidyard = content.video.service === "vidyard" ? true : false;

    const pattern = generatePatternData(content.pattern);
    const parallax = isParallax(this.props.content.length, index);

    let newTheme;
    if (this.props.page && this.props.page === "home") {
      newTheme = "theme-4";
    } else if (this.props.page && this.props.page === "services") {
      newTheme = "theme-3";
    }

    // Sometimes this comes as .subHeader, others as .text
    const subHeader = content.subHeader || content.text;

    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "video-feat"}-${index}`}
      >
        <VideoFeat
          thumbnail={formatVideoThumbnail(content.video.thumbnail)}
          header={content.header}
          subHeader={subHeader}
          video={video}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          theme={newTheme ? newTheme : theme ? theme : pattern.theme}
          zIndex={index + 1}
          backgroundImage={
            parallax
              ? this.getPattern(newTheme ? newTheme : theme, index)
              : content.backgroundImage
          }
          backgroundPosition={index === 1 ? "top-right" : "top-left"}
          backgroundImageHeight={this.state.backgroundImageHeight}
          backgroundImageWidth={this.state.backgroundImageWidth}
          backgroundOffsetY={pattern.offsetY}
          backgroundOffsetX={index === 1 ? 900 : 200}
          parallaxPosition={parallax ? this.props.parallaxPosition : null}
          vidyard={vidyard}
        />
      </Element>
    );
  };

  generateHomePageTrades = (content = {}, index) => {
    // The Trades cards on the home page require some unique formatting compared to other Feat4Flex JSON objects
    const cards = formatHomePageTradesCards(
      content.list,
      this.props.learnMoreLabel
    );

    return (
      <Element
        name={`#${content.sectionName || null}`}
        key={`${content.header || "home-page-trades-feat"}-${index}`}
      >
        <Feat4Flex
          header={content.header}
          cards={cards}
          theme={content.theme || "theme-1"}
        />
      </Element>
    );
  };

  generateBIMFeatFourFlex = (content, index) => {
    const cards = formatBIMCards(content.list, this.props.learnMoreLabel);
    let theme;
    if (content.theme) {
      theme = content.theme;
    } else if (content.pattern) {
      if (content.pattern[0]) {
        theme = content.pattern[0];
      }
    }

    if (this.props.page === "bim-services") {
      theme = "theme-5";
    }

    const parallax = isParallax(this.props.content.length, index);
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat4"}-${index}-bim`}
      >
        <Feat4
          header={content.header}
          text={content.text}
          cta={content.cta}
          content={cards}
          columns={content.columns || 3}
          image={content.image_large}
          variant={content.variant}
          id={content.sectionName}
          motion={this.props.motion}
          motionDirection={this.props.motionDirection}
          zIndex={index + 1}
          theme={theme}
          backgroundImage={parallax ? this.getPattern(theme, index) : null}
          backgroundPosition={index === 1 ? "top-right" : "top-left"}
          backgroundImageHeight={this.state.backgroundImageHeight}
          backgroundImageWidth={this.state.backgroundImageWidth}
          backgroundOffsetY={0}
          backgroundOffsetX={index === 1 ? 900 : 200}
          parallaxPosition={parallax ? this.props.parallaxPosition : null}
        />
      </Element>
    );
  };

  generateSupportHTMLCards = (content, index) => {
    const cards = formatHTMLCards(content) || null;

    return (
      <Element name={`#${content.sectionName || null}`}>
        <SupportHTMLCards content={cards} />
      </Element>
    );
  };

  generateCustomFeat = (content, index) => {
    if (!content.content.length) {
      return (
        <Element
          className={
            content.width_toggle && content.width_toggle === "1"
              ? "custom-feat-mep custom-feat-mep-full-width"
              : "custom-feat-mep"
          }
          name={
            content.sectionName
              ? `#${content.sectionName}`
              : content.type
              ? `${content.type}-${index}`
              : null
          }
          key={`customFeat-${index}`}
        >
          <CustomFeat embeddedCode={content.content} />
        </Element>
      );
    } else if (content.content && typeof content.content === "string") {
      return (
        <Element
          key={index}
          className={
            content.width_toggle && content.width_toggle === "1"
              ? "custom-feat-mep custom-feat-mep-full-width"
              : "custom-feat-mep"
          }
        >
          <CustomFeat embeddedCode={content.content} />
        </Element>
      );
    } else if (
      content.content &&
      content.content.length &&
      typeof content.content === "object"
    ) {
      return content.content.map((item) => {
        return (
          <Element
            key={index}
            className={
              content.width_toggle && content.width_toggle === "1"
                ? "custom-feat-mep custom-feat-mep-full-width"
                : "custom-feat-mep"
            }
          >
            <CustomFeat embeddedCode={item.value} />
          </Element>
        );
      });
    }
  };

  render() {
    return <section className="luna-page-builder">{this.mapContent()}</section>;
  }
}

const mapStateToProps = (state) => {
  return {
    parallaxPosition: state.offsetY,
    region: state.region,
    learnMoreLabel: state.learnMoreLabel,
    language: state.language,
  };
};

export default connect(mapStateToProps)(withRouter(PageBuilder));
