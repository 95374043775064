import React, { Component } from "react";
import { formattedPageFetch } from "../../utils/async/pageFetch";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
  updateErrorStatus,
} from "../../actions";
import { getCountrySettings } from "../../utils/methods/contentSectionFormat";
import PropTypes from "prop-types";
import stgURL from "../../utils/helperObjects/envURLs";
import PageBuilder from "../../components/PageBuilder/PageBuilder";
import "./ProductPage.scss";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import * as Sentry from "@sentry/browser";
import FourZeroFourPage from "../FourZeroFourPage/FourZeroFourPage";
import { ctaOverrideReducer } from "../../reducers/ctaOverrideReducer";

class ProductPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
      regionSettings: null,
      innerNavData: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
    this.checkBlocked();
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  // commenting this out due to new requirement via email, but just in case we need to revert back to this I am keep this here. This was orignially being called in componentDidMount - LS

  // redirectForUTM = () => {
  //   if (
  //     this.props.location.pathname.includes("trimble-sysque") ||
  //     this.props.location.pathname.includes("trimble-accubid-anywhere")
  //   ) {
  //     this.props.history.push(
  //       "?utm_medium=email&utm_source=constructionexecutive&utm_campaign=2020-06-MEPNA-CE"
  //     );
  //   }
  // };

  checkBlocked = () => {
    // Changes the innerPageNav when switching from a region-blocked region to a non-region-blocked region
    if (
      getCountrySettings(
        this.state.regionSettings,
        this.props.region,
        this.props.admin
      )
    ) {
      if (
        this.props.innerPageNav &&
        this.props.innerPageNav.pageTitle === "403"
      ) {
        if (this.state.innerNavData) {
          this.props.setInnerPageNav(this.state.innerNavData);
        }
      }
    }
  };

  getQueryParams = () => {
    const params = this.props.match.params.productName;
    return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/products$${params}/$`;
  };

  getLanguage = () => {
    if (this.props.match.params.adminLang) {
      return this.props.match.params.adminLang;
    } else if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  getData = async () => {
    const url = this.getQueryParams();
    this.setLoading();

    try {
      const {
        content,
        innerNavData,
        metadata,
        regionSettings,
        error,
        ctaOverride = null,
      } = await formattedPageFetch(url);

      if (!content && error === 404) {
        this.set404();
        return;
      }

      if (!content) {
        this.setError();
        return;
      }

      if (innerNavData) {
        this.props.setInnerPageNav(innerNavData);
      }

      if (metadata) {
        this.props.updateMetadata(metadata);
      }

      this.props.updateCTAOverride(ctaOverride);

      this.setState({ content, regionSettings, innerNavData });
      this.setSuccess();
    } catch (error) {
      console.log(error);
      this.setError();
      this.props.setInnerPageNav({ pageTitle: "500", links: [] });
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
    }
  };

  set404 = () => {
    this.props.updateErrorStatus(true);
    this.props.setStatus("success");
    this.setState({ content: "404" });
    window.prerenderReady = true;
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };
  setSuccess = () => {
    this.props.updateErrorStatus(false);
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  render() {
    const { status, page, navbarReady } = this.props;
    const { content } = this.state;
    return (
      <main
        className={`mep-product-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-product-page--loading"}`}
      >
        {(((status === "loading" || (!navbarReady && status !== "error")) &&
          !content) ||
          (!this.props.region && !content)) && <Loading />}
        {status === "error" && <ErrorPage />}
        {content === "404" && navbarReady && <FourZeroFourPage />}
        {status === "success" &&
          navbarReady &&
          this.props.region &&
          content &&
          content !== "404" && (
            // keeping this commented out incase MEP wants regionBlocking back in
            /*getCountrySettings(
            this.state.regionSettings,
            this.props.region,
            this.props.admin
          ) && */
            <PageBuilder
              page={page}
              content={content}
              motion={true}
              motionDirection={"bottom"}
              trade={this.props.trade}
            />
          )}

        {
          // keeping this commented out incase MEP wants regionBlocking back in
          /* {status === "success" &&
          navbarReady &&
          this.props.region &&
          content &&
          content !== "404" &&
          !getCountrySettings(
            this.state.regionSettings,
            this.props.region,
            this.props.admin
          ) && (
            <RegionBlockPage
              content={this.state.content}
              location={this.props.location}
              toggleRegionLanguageDeployed={
                this.props.toggleRegionLanguageDeployed
              }
            />
          )}*/
        }
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  innerPageNav: state.innerPageNav,
  navbarReady: state.navbarReady,
  errorStatus: state.errorStatus,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (metadata) => dispatch(updateMetadata(metadata)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
    updateErrorStatus: (errorStatus) =>
      dispatch(updateErrorStatus(errorStatus)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);

ProductPage.propTypes = {
  match: PropTypes.object.isRequired,
};
