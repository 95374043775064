import { Component } from "react";
import redirects from "../../utils/helperObjects/redirects";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkForRedirectFetch } from "../../utils/async/checkForRedirectFetch";

class RedirectManagement extends Component {
  componentDidMount() {
    this.checkForPathMatch();
  }

  checkForPathMatch = () => {
    const pathname =
      this.props.location && this.props.location.pathname
        ? this.props.location.pathname
        : null;
    if (!pathname) {
      return;
    }

    this.checkForRedirects(pathname);
  };

  checkForRedirects = (pathname) => {
    // first we check if there are any redirects listed for this path in Drupal
    checkForRedirectFetch(
      pathname,
      this.props.getLanguage,
      this.props.language
    );

    // if we get to this next part of this method, we know this path does not contain a redirect from Drupal and we will check our redirect helper object:
    const redirectsArray = redirects || [];

    let redirectAddress = undefined;
    let external;
    redirectsArray.forEach((point) => {
      let push = true;

      if (pathname !== `/${this.props.language || "en"}${point.redirect}`) {
        if (point.conditionals) {
          point.conditionals.forEach((conditional) => {
            if (conditional.contains === true) {
              if (
                pathname
                  .toLowerCase()
                  .includes(conditional.string.toLowerCase())
              ) {
                push = true;
              }
            } else if (conditional.contains === false) {
              if (
                pathname
                  .toLowerCase()
                  .includes(conditional.string.toLowerCase())
              ) {
                push = false;
              }
            } else {
              if (
                !pathname
                  .toLowerCase()
                  .includes(conditional.string.toLowerCase())
              ) {
                push = false;
              }
            }
          });
        }
      } else {
        push = false;
      }

      if (push) {
        if (point.external) {
          redirectAddress = point.redirect;
          external = point.external;
        } else {
          redirectAddress = `/${this.props.language}${point.redirect}`;
        }
      }
    });

    if (redirectAddress) {
      if (external) {
        window.location = redirectAddress;
      } else {
        console.log("pushing");
        this.props.history.push(redirectAddress);
      }
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps, null)(withRouter(RedirectManagement));
