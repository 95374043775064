import React, { Component } from "react";
import "../DemoPage/DemoPage.scss";
// Uses the same styling as DemoPage
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
} from "../../actions";
import stgURL from "../../utils/helperObjects/envURLs";
import Loading from "../../components/Loading/Loading";
import { demoLabelFetch } from "../../utils/async/demoFetch";
import { MarketoForm } from "@trimble-creative-strategy/luna-component-lib";
import ErrorPage from "../ErrorPage/ErrorPage";
import * as Sentry from "@sentry/browser";
import {
  countriesThatDontRequirePostalCode,
  renderBIMInputs,
  germanMessageText,
  englishMessageText,
  frenchMessageText,
} from "../DemoPage/DemoFormMethods";
import loadingAnimation from "../../assets/images/loading-mini.gif";
import { send } from "marketfaux";

class BIMDemoPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
      entries: [],
      warnings: [],
      optin: false,
      dropdowns: null,
      formReady: false,
      submitting: false,
      submissionError: false,
      submissionAgreementChecked: false,
      submissionAgreementWarning: false,
    };
    this.timeoutID = null;
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    // Check for pathname changes and fetch new data if necessary
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getURL = () => {
    // Generate the URL for the fetch request
    return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/demo-form-labels/$`;
  };

  getLanguage = () => {
    // Return the current language in Redux or 'en' if undefined
    if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  getTaxonomyUrl = () => {
    // Only need trades for this, no categories or products
    return `${stgURL}${this.getLanguage()}/api-v1/FetchTaxonomyByMachineName/marketo_mapping`;
  };

  getCountryURL = () => {
    return `${stgURL}${this.getLanguage()}/api-v1/FetchCountryList`;
  };

  getData = async () => {
    // Fetch data to build the page
    this.setLoading();
    const url = this.getURL();
    const taxonomyURL = this.getTaxonomyUrl();
    const countryURL = this.getCountryURL();
    // Using same data as DemoPage plus a few added properties specific to this page
    try {
      const { data, dropdowns, countryData, metadata } = await demoLabelFetch(
        url,
        taxonomyURL,
        countryURL
      );

      if (metadata) {
        this.props.updateMetadata(metadata);
      }

      this.setState({
        content: this.removeRequirementFromTrade(data),
        dropdowns,
        countryData,
      });
      // TODO DemoPage - Update the setInnerPageNav logic in DemoPage to use non-hard-coded results
      this.props.setInnerPageNav({
        pageTitle: data.utils.contact_header,
        links: [],
      });
      this.props.updateCTAOverride(null);
      this.setSuccess();
    } catch (error) {
      this.setError();
      console.log("error", error);
      this.props.setInnerPageNav({ pageTitle: "500", links: [] });
      Sentry.captureException(error);
    }
  };

  removeRequirementFromTrade = (data) => {
    const tradesInput = data.inputs.find(
      (input) =>
        input.machineName && input.machineName.toLowerCase() === "trade"
    );
    if (tradesInput) {
      tradesInput.required = false;
    }

    const categoriesInput = data.inputs.find((input) => {
      return (
        input.machineName &&
        input.machineName.toLowerCase() === "product_category"
      );
    });
    if (categoriesInput) {
      categoriesInput.required = false;
    }
    return data;
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };

  handleChange = (e) => {
    // Update text inputs in state
    const newEntries = [...this.state.entries];
    const entry = newEntries.find((entry) => {
      return entry.name === e.target.name;
    });

    // The inputs are generated progromatically and are not hard-coded, and so they live inside an array
    if (entry) {
      entry.value = e.target.value;
      this.updateEntries(newEntries);
    } else {
      const input = this.state.content.inputs.find((input) => {
        return input.name === e.target.name;
      });
      this.updateEntries([
        ...this.state.entries,
        {
          name: e.target.name,
          value: e.target.value,
          machine_name: input.machine_name,
        },
      ]);
    }
  };

  updateEntries = (entries) => {
    // Takes in the new entries array and sets it into state
    this.setState({ entries: [...entries] });
  };

  handleSelect = (selection, name) => {
    const { content } = this.state;
    let allText;

    if (content && content.utils && content.utils.all_text) {
      allText = content.utils.all_text;
    } else {
      allText = null;
    }

    // Update the dropdown menus with new selections
    const newEntries = JSON.parse(JSON.stringify(this.state.entries));

    const entry = newEntries.find((entry) => {
      return entry.name === name;
    });

    // The dropdowns are generated progromatically and are not hard-coded, and so they live inside an array
    if (entry) {
      if (allText && selection !== allText) {
        const input = this.state.content.inputs.find((input) => {
          return input.name === name;
        });

        // Handle country selection change
        if (input.machine_name === "country") {
          entry.value = selection;

          const filteredEntries = newEntries.filter((entry) => {
            return entry.machineName !== "state";
          });

          this.updateEntries(filteredEntries);
        } else {
          // Handle non-country selection changes
          entry.value = selection;
          this.updateEntries(newEntries);
        }
        // Handle changes for the default 'no-selection'/'All'
      } else if (allText && selection === allText) {
        const filteredEntries = newEntries.filter((selection) => {
          return selection.value !== entry.value;
        });
        this.updateEntries(filteredEntries);
      } else {
        entry.value = selection;
        this.updateEntries(newEntries);
      }
    } else if (allText === selection) {
      return;
    } else {
      const input = this.state.content.inputs.find((input) => {
        return input.name === name;
      });

      this.updateEntries([
        ...this.state.entries,
        { name, value: selection, machineName: input.machine_name },
      ]);
    }
  };

  checkForForm = () => {
    if (this.state.submitting) {
      return;
    }

    this.handleSubmit();
  };

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleSubmit = () => {
    // Handles the submission logic

    // Check to see if any required inputs/dropdowns are missing input/selection
    const warnings = this.state.content.inputs.reduce((accu, input) => {
      if (input.required) {
        const match = this.state.entries.find((entry) => {
          return entry.name === input.name;
        });

        if (input.machineName === "state") {
          const countrySelection = this.state.entries.find((entry) => {
            return entry.machineName === "country";
          });

          if (!countrySelection) {
            return accu;
          }

          const countryData = this.state.countryData.find((country) => {
            return country.name === countrySelection.value;
          });

          if (!countryData || !countryData.states) {
            return accu;
          }
        }

        if (input.machineName === "email_address" && match) {
          const validatedEmail = this.validateEmail(match.value);
          if (!validatedEmail) {
            accu.push(input.name);
          }
        }
        if (!match) {
          if (
            input.machine_name.toLowerCase() === "postal_code" &&
            this.checkForPostalCodeCountrySelectionRequirement()
          ) {
            return accu;
          } else {
            accu.push(input.name);
          }
        }
      }
      return accu;
    }, []);

    const checkboxWarning = this.getCheckboxWarning();

    // Pass references to required inputs with missing fields into state and return
    if (warnings.length > 0 || checkboxWarning) {
      if (checkboxWarning) {
        this.setState({ submissionAgreementWarning: true });
      }

      if (warnings.length > 0) {
        this.updateWarnings(warnings);
      }

      return;
    }

    this.clearWarnings();

    this.proceedToSubmit();
  };

  clearWarnings = () => {
    this.setState({ warnings: [] });
  };

  getCheckboxWarning = () => {
    const { language } = this.props;

    if (language === "de" || language === "fr") {
      if (!this.state.submissionAgreementChecked) {
        return true;
      }
    }

    return false;
  };

  checkForPostalCodeCountrySelectionRequirement = () => {
    const countryEntry = this.state.entries.find(
      (entry) => entry.machineName === "country"
    );

    if (countryEntry) {
      const { value } = countryEntry;

      const countries = this.state.countryData || [];

      const countryData = countries.find((country) => country.name === value);

      if (countryData) {
        const { isoCode } = countryData;

        if (
          isoCode &&
          countriesThatDontRequirePostalCode.includes(isoCode.toLowerCase())
        ) {
          return true;
        }
      }
    }

    return false;
  };

  updateWarnings = (warnings) => {
    // Set required forms with missing data into state to warn the user when they have left the field empty
    this.setState({ warnings });
  };

  proceedToSubmit = () => {
    const payload = this.generateFormDataObject();
    this.setSubmitting();
    try {
      send("//app-ab13.marketo.com", "593-HFN-635", 5201, payload)
        .badRequest(() => {
          // Handle 400 error
          this.handleError(400, "Bad request");
        })
        .timeout(() => {
          // Handle 408 error
          this.handleError(408, "timeout");
        })
        .internalError(() => {
          // Handle 500 error
          this.handleError(500, "internal error");
        })
        .json((result) => {
          if (result && result.aliId) {
            // Handle success
            this.handleSuccess();
          } else {
            this.handleError(422, "Unprocessable Entity");
          }
        });
    } catch (error) {
      console.log("Marketo form fill error", error);
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
      this.setSubmissionError();
    }
  };

  generateFormDataObject = () => {
    return {
      FirstName: this.getDataPoint("first_name"),
      LastName: this.getDataPoint("last_name"),
      Company: this.getDataPoint("company_name"),
      Country: this.getDataPoint("country"),
      Email: this.getDataPoint("email_address"),
      PostalCode: this.getDataPoint("postal_code"),
      Product_Category__c: "BIM / VDC Services",
      State: this.getDataPoint("state"),
      Marketing_Sub_industry__c: this.getTradeValue(),
      Phone: this.getDataPoint("phone_number"),
      expressOptInForm: this.getOptIn(),
      LeadSource: "webform - contact",
      firstTouchutmmedium: "",
      firstTouchutmcampaign: "",
      firstTouchutmcontent: "",
      firstTouchutmsource: "",
      firsTouchutmterm: "",
      lastTouchutmcampaign: "",
      lastTouchutmcontent: "",
      lastTouchutmmedium: "",
      lastTouchutmsource: "",
      lastTouchutmterm: "",
      expressOptInForm: this.getOptIn(),
    };
  };

  getOptIn = () => {
    if (this.props.language === "de" || this.props.language === "fr") {
      return "yes";
    } else {
      if (this.state.submissionAgreementChecked) {
        return "yes";
      } else {
        return "no";
      }
    }
  };

  handleError = (type, message) => {
    if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      const error = {
        statusCode: type,
        message,
      };
      Sentry.captureException(error);
    }
    this.setSubmissionError();
    console.log(type, message);
  };

  handleSuccess = () => {
    const location = this.props.location.pathname;
    if (location.charAt(-1) === "/") {
      this.props.history.push(`${location}submitted`);
    } else {
      this.props.history.push(`${location}/submitted`);
    }
  };

  getDataPoint = (point) => {
    const data = this.state.entries.find((entry) => {
      return entry.machineName === point || entry.machine_name === point;
    });

    if (data) {
      return data.value;
    } else {
      return null;
    }
  };

  getTradeValue = () => {
    const data = this.state.entries.find((entry) => {
      return entry.machineName === "trade" || entry.machine_name === "trade";
    });

    if (data) {
      const dropdownData = this.state.dropdowns.find((dropdown) => {
        return dropdown.name === data.value;
      });

      if (dropdownData) {
        return dropdownData.machineName;
      }
    }
  };

  getWarningText = () => {
    const { content } = this.state;
    if (content && content.utils && content.utils.warning_text) {
      return content.utils.warning_text;
    } else {
      return "There are required fields that are incomplete.";
    }
  };

  generateForm = () => {
    // Generate the Marketo form
    // Does this need to be a method? Why is this a method?

    return (
      <MarketoForm
        inputs={renderBIMInputs(
          this.state.content,
          this.state.dropdowns,
          this.state.entries,
          this.state.countryData
        )}
        handleSelect={this.handleSelect}
        handleChange={this.handleChange}
        handleSubmit={this.checkForForm}
        entries={this.state.entries}
        warnings={this.state.warnings}
        submitButtonText={this.state.content.utils.submit || "Submit"}
        warningText={this.getWarningText()}
        submissionAgreementText={this.getAgreementText()}
        submissionAgreementChecked={this.state.submissionAgreementChecked}
        handleSubmissionAgreementChange={
          this.getAgreementText()
            ? this.handleSubmissionAgreementChange
            : undefined
        }
        submissionAgreementWarning={this.state.submissionAgreementWarning}
      >
        {this.state.submitting && (
          <div className="mep-demo-page__submitting-img-wrapper">
            <img
              className="mep-demo-page__submitting-img"
              src={loadingAnimation}
              alt="loading"
            />
          </div>
        )}
        {this.getChildren()}
      </MarketoForm>
    );
  };

  handleSubmissionAgreementChange = (e) => {
    this.setState({
      submissionAgreementChecked: e.target.checked,
      submissionAgreementWarning: false,
    });
  };

  getAgreementText = () => {
    const { language, region } = this.props;

    if (language === "en" || language.toLowerCase() === "en-gb") {
      if (
        this.state.content &&
        this.state.content.utils &&
        this.state.content.utils.opt_in_label
      ) {
        return `${this.state.content.utils.opt_in_label} `;
      } else {
        return "";
      }
    } else if (language === "de") {
      return germanMessageText();
    } else if (language === "fr") {
      return frenchMessageText();
    } else {
      if (
        this.state.content &&
        this.state.content.utils &&
        this.state.content.utils.opt_in_label
      ) {
        return this.state.content.utils.opt_in_label;
      } else {
        return undefined;
      }
    }
  };

  submissionAgreementTextRequired = () => {
    const { language } = this.props;

    if (language === "de" || language === "fr") {
      return true;
    } else {
      return false;
    }
  };

  getChildren = () => {
    const { region, language } = this.props;
    if (region === "North America" || region === "UK and Middle East") {
      if (language === "en" || language.toLowerCase() === "en-gb") {
        return englishMessageText();
      }
    } else {
      return;
    }
  };

  setSubmitting = () => {
    this.setState({ submitting: true });
  };
  setSubmissionError = () => {
    this.setState({
      submissionError: true,
      submitting: false,
    });
  };

  render() {
    const { content } = this.state;
    const { status, navbarReady } = this.props;
    return (
      <main
        className={`mep-demo-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-demo-page--loading"}`}
      >
        {content &&
          status === "success" &&
          navbarReady &&
          !this.state.submissionError && (
            <>
              {content.utils &&
                content.utils.header &&
                this.props.page !== "contact" && (
                  <h3 className="mep-demo-page__header">
                    {content.utils.contact_header}
                  </h3>
                )}
              {content.utils &&
                content.utils.contact_header &&
                this.props.page === "contact" && (
                  <h3 className="mep-demo-page__header">
                    {content.utils.contact_header}
                  </h3>
                )}
              {this.generateForm()}
            </>
          )}
        {(status === "loading" || (!navbarReady && status !== "error")) && (
          <Loading />
        )}
        {(status === "error" || this.state.submissionError) && <ErrorPage />}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  navbarReady: state.navbarReady,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (data) => dispatch(updateMetadata(data)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BIMDemoPage);
