import React from "react";
import {
  formatDropdownsForCategorySelections,
  formatDropdownsForNoSelections,
  formatDropdownsForTradeCategorySelection,
  formatDropdownsForTradeSelections,
  formatDropdownsForBIM,
} from "./DemoFormDropdownFilters";

export const renderInputs = (
  content = { utils: {} },
  dropdowns = [],
  entries = [],
  countryData = []
) => {
  const tradeSelection = determineTradeSelection(content, entries);
  const categorySelection = determineCategorySelection(content, entries);

  const countryDropdowns = formatCountryDropdowns(
    countryData,
    content.inputs,
    entries
  );

  if (tradeSelection && !categorySelection) {
    const newDropdowns = formatDropdownsForTradeSelections(
      content.inputs,
      tradeSelection,
      dropdowns,
      content.utils.all_text
    );
    return checkForPostalCodeNecessity(
      content,
      countryDropdowns,
      newDropdowns,
      entries,
      countryData
    );
  } else if (categorySelection && !tradeSelection) {
    const newDropdowns = formatDropdownsForCategorySelections(
      content.inputs,
      categorySelection,
      dropdowns,
      content.utils.all_text
    );
    return checkForPostalCodeNecessity(
      content,
      countryDropdowns,
      newDropdowns,
      entries,
      countryData
    );
  } else if (categorySelection && tradeSelection) {
    const newDropdowns = formatDropdownsForTradeCategorySelection(
      content.inputs,
      tradeSelection,
      categorySelection,
      dropdowns,
      content.utils.all_text
    );
    return checkForPostalCodeNecessity(
      content,
      countryDropdowns,
      newDropdowns,
      entries,
      countryData
    );
  } else {
    const newDropdowns = formatDropdownsForNoSelections(
      content.inputs,
      dropdowns,
      content.utils.all_text
    );

    return checkForPostalCodeNecessity(
      content,
      countryDropdowns,
      newDropdowns,
      entries,
      countryData
    );
  }
};

export const countriesThatDontRequirePostalCode = [
  "ca",
  "br",
  "ie",
  "it",
  "in",
  "mx",
  "us",
];

const checkForPostalCodeNecessity = (
  content = {},
  countryDropdowns,
  newDropdowns,
  entries,
  countryData
) => {
  // These need to be referenced by country code, so we'll have to search the country objects instead of hard-coding since country names could be translated.

  // Corresponding iso codes to countries that shouldn't display postal code

  // Find the country selection from all current entries
  const countrySelection = entries.find((entry) => {
    return entry.machineName && entry.machineName.toLowerCase() === "country";
  });

  // Get the data for the selected country or null
  const selectedCountryData =
    countrySelection && countrySelection.value
      ? countryData.find((country) => {
          return (
            country.name.toLowerCase() === countrySelection.value.toLowerCase()
          );
        })
      : null;

  // Get the ISOcode from the selected country's data
  const selectedCountryISO = selectedCountryData
    ? selectedCountryData.isoCode
    : null;

  if (
    (selectedCountryISO &&
      countriesThatDontRequirePostalCode.includes(
        selectedCountryISO.toLowerCase()
      )) ||
    !countrySelection
  ) {
    // If there is a selected country that matches one of our ISO codes OR there isn't a selected country, filter out the postal code input so that it doesn't render

    const inputs = content.inputs.filter(
      (input) => input.machineName !== "postal_code"
    );

    return [
      ...inputs.filter((input) => input.type === "text"),
      ...countryDropdowns,
      ...newDropdowns,
    ];
  } else {
    const returnObj = [
      ...content.inputs.filter((input) => input.type === "text"),
      ...countryDropdowns,
      ...newDropdowns,
    ];

    // the postal code input should come after the country input, but in the Marketo form, it's in an order, so this rearranges it appropriately
    const postalInput = returnObj.find(
      (input) => input.machineName === "postal_code"
    );

    if (postalInput) {
      const postalIndex = returnObj.indexOf(postalInput);

      if (postalIndex) {
        const removedPostalInput = returnObj.splice(postalIndex, 1)[0];

        const countryInput = returnObj.find(
          (input) => input.machineName === "country"
        );

        if (countryInput) {
          const countryIndex = returnObj.indexOf(countryInput);

          returnObj.splice(countryIndex + 1, 0, removedPostalInput);
        }
      }
    }

    return returnObj;
  }
};

const determineTradeSelection = (content, entries) => {
  const { name } = content.inputs.find(
    (input) => input.machineName === "trade"
  );
  const tradeEntry = entries.find(
    (entry) => entry.name.toLowerCase() === name.toLowerCase()
  );
  if (tradeEntry) {
    return tradeEntry;
  } else {
    return null;
  }
};

const determineCategorySelection = (content, entries) => {
  const { name } = content.inputs.find(
    (input) => input.machineName === "product_category"
  );
  const categoryEntry = entries.find(
    (entry) => entry.name.toLowerCase() === name.toLowerCase()
  );
  if (categoryEntry) {
    return categoryEntry;
  } else {
    return null;
  }
};

const formatCountryDropdowns = (data = [], inputs, entries) => {
  const countryInputData = inputs.find(
    (input) => input.machine_name === "country"
  );

  const countryEntry = entries.find((entry) => {
    return entry.name === countryInputData.name;
  });

  countryInputData.options = data.map((country) => country.name);
  countryInputData.required = true;

  if (countryEntry) {
    const countryEntryInputData = data.find((country) => {
      return country.name === countryEntry.value;
    });

    if (countryEntryInputData && countryEntryInputData.states) {
      const stateInputData = inputs.find(
        (input) => input.machine_name === "state"
      );
      stateInputData.required = true;
      stateInputData.options = countryEntryInputData.states.map(
        (state) => state.name
      );
      return [countryInputData, stateInputData];
    }

    return [countryInputData];
  } else {
    return [countryInputData];
  }
};

export const renderBIMInputs = (
  content = { utils: {} },
  dropdowns = [],
  entries = [],
  countryData = []
) => {
  const countryDropdowns = formatCountryDropdowns(
    countryData,
    content.inputs,
    entries
  );

  const newDropdowns = formatDropdownsForBIM(
    content.inputs,
    dropdowns,
    content.utils.all_text
  );

  return checkForPostalCodeNecessity(
    content,
    countryDropdowns,
    newDropdowns,
    entries,
    countryData
  );
};

export const dutchMessageText = () => (
  <p className="luna-marketo-form__bottom-text">
    Ik begrijp dat Trimble de door mij verstrekte informatie kan opslaan en
    gebruiken om contact met mij op te nemen. Ik kan deze toestemming te allen
    tijde intrekken. Ik heb de{" "}
    <a target="_blank" href="https://www.trimble.com/Corporate/Privacy.aspx">
      gebruiksvoorwaarden
    </a>{" "}
    gelezen.{" "}
  </p>
);

export const germanMessageText = () => (
  <p className="luna-marketo-form__bottom-text">
    Ich verstehe, dass Trimble die von mir angegebenen Daten speichern und
    verwenden darf, um mich per E-Mail, Post und Telefon zu kontaktieren. Dieses
    Einverständnis kann ich jederzeit widerrufen. Ich habe die stimme der{" "}
    <a target="_blank" href="https://www.trimble.com/Corporate/Privacy.aspx">
      Datenschutzerklärung
    </a>{" "}
    zu.{" "}
  </p>
);

export const frenchMessageText = () => (
  <p className="luna-marketo-form__bottom-text">
    Trimble peut stocker et utiliser les données que vous fournissez, pour vous
    contacter avec les ressources de l'industrie pertinentes et les informations
    sur les produits par email, courrier et téléphone. Pour en savoir plus,
    consultez{" "}
    <a target="_blank" href="https://www.trimble.com/Corporate/Privacy.aspx">
      notre politique de confidentialité
    </a>
  </p>
);

export const englishMessageText = () => (
  <p className="luna-marketo-form__bottom-text">
    I understand that by submitting my personal information to Trimble it is
    subject to the Trimble{" "}
    <a target="_blank" href="https://www.trimble.com/Corporate/Privacy.aspx">
      Privacy Notice
    </a>
    . I agree that Trimble, its authorized representatives and its group of
    companies can contact me by email to promote their products and services. I
    consent to receive digital communications from Trimble. I understand I can
    opt-out of digital communications with Trimble at any time using the
    unsubscribe link in Trimble’s email communications.{" "}
  </p>
);

export const removeHiddenFormAndScripts = () => {
  // Get all the elements created by the Marketo script and remove them from the DOM when the component unmounts
  const form = document.getElementById("mktoForm_5201");
  if (form) {
    form.remove();
  }

  const form2 = document.getElementsByClassName("mktoForm");
  if (form2.length > 0) {
    for (let i = 0; i < form2.length; i++) {
      form2[i].remove();
    }
  }

  const scriptCaller = document.getElementById("mktoForm_script_caller");
  if (scriptCaller) {
    scriptCaller.remove();
  }

  const bigScript = document.getElementById("mktoScript_big");
  if (bigScript) {
    bigScript.remove();
  }

  const iframe = document.getElementById("MktoForms2XDIframe");
  if (iframe) {
    iframe.remove();
  }

  const mktoStyleDiv = document.getElementById("mktoStyleLoaded");
  if (mktoStyleDiv) {
    mktoStyleDiv.remove();
  }
};
