import { generateFeatEightCTAs, cleanCardImages } from "./pageBuilderMethods";
const formatSupportPageData = (response) => {
  const innerNavData = {
    pageTitle: response.data[0].name,
    link: [],
  };
  const metadata =
    response.data && response.data[0] && response.data[0].metatags
      ? response.data[0].metatags
      : null;

  const ctaOverride = formatCTAOverride(response.data[0]) || null;

  const content =
    response.data && response.data[0] && response.data[0].content_sections
      ? formatContent(response.data[0].content_sections)
      : null;
  return {
    content,
    innerNavData,
    metadata,
    ctaOverride,
  };
};

const formatCTAOverride = (data = {}) => {
  if (
    data.nav_cta_override &&
    data.nav_cta_override.toggle &&
    data.nav_cta_override.link &&
    data.nav_cta_override.link.url &&
    data.nav_cta_override.link.text
  ) {
    return {
      url: data.nav_cta_override.link.url,
      text: data.nav_cta_override.link.text,
    };
  } else {
    return null;
  }
};

const formatContent = (data = {}) => {
  const hero = {
    header:
      data &&
      data.support_page_hero &&
      data.support_page_hero[0] &&
      data.support_page_hero[0].header
        ? data.support_page_hero[0].header
        : null,
    text:
      data &&
      data.support_page_hero &&
      data.support_page_hero[0] &&
      data.support_page_hero[0].text
        ? data.support_page_hero[0].text
        : null,
    link:
      data &&
      data.support_page_hero &&
      data.support_page_hero[0] &&
      data.support_page_hero[0].ctas &&
      data.support_page_hero[0].ctas.ctaOne
        ? data.support_page_hero[0].ctas.ctaOne
        : null,
    images: {
      desktop:
        data &&
        data.support_page_hero &&
        data.support_page_hero[0] &&
        data.support_page_hero[0].image_large &&
        data.support_page_hero[0].image_large.source &&
        data.support_page_hero[0].image_large.alt
          ? {
              url: data.support_page_hero[0].image_large.source,
              altText: data.support_page_hero[0].image_large.alt,
            }
          : null,
      tablet:
        data &&
        data.support_page_hero &&
        data.support_page_hero[0] &&
        data.support_page_hero[0].image_medium &&
        data.support_page_hero[0].image_medium.source &&
        data.support_page_hero[0].image_medium.alt
          ? {
              url: data.support_page_hero[0].image_medium.source,
              altText: data.support_page_hero[0].image_medium.alt,
            }
          : null,
      mobile:
        data &&
        data.support_page_hero &&
        data.support_page_hero[0] &&
        data.support_page_hero[0].image_medium &&
        data.support_page_hero[0].image_medium.source &&
        data.support_page_hero[0].image_medium.alt
          ? {
              url: data.support_page_hero[0].image_medium.source,
              altText: data.support_page_hero[0].image_medium.alt,
            }
          : null,
    },
  };

  const feat8 = data.feat_8 ? formatFeat8(data.feat_8) : null;

  const linkTiles = data.support_page_link_tile
    ? formatLinkTiles(data.support_page_link_tile)
    : null;
  const HTMLCards =
    data.support_page_wysiwyg &&
    data.support_page_wysiwyg[0] &&
    data.support_page_wysiwyg[0].value &&
    data.support_page_wysiwyg[0].field_wysiwyg_card.value
      ? formatHTMLCards(data.support_page_wysiwyg[0].field_wysiwyg_card.value)
      : null;
  return { hero, linkTiles, HTMLCards, feat8 };
};

const formatFeat8 = (data) => {
  return {
    header: data[0] && data[0].header ? data[0].header : null,
    subHeader: data[0] && data.subHeader ? data[0].subHeader : null,
    ctas: generateFeatEightCTAs(data[0].ctas),
    cards: featEightCards(data[0].list),
  };
};

const featEightCards = (data) => {
  const cards = Object.values(data);
  return cards.map((card) => {
    return {
      header:
        card.teaser && card.teaser.teaser_title
          ? card.teaser.teaser_title
          : null,
      text:
        card.teaser && card.teaser.teaser_desc ? card.teaser.teaser_desc : null,
      image:
        card.teaser && card.teaser.teaser_img
          ? cleanCardImages(card.teaser.teaser_img)
          : null,
      buttonLink: {
        url:
          card.teaser && card.teaser.teaser_cta && card.teaser.teaser_cta.url
            ? card.teaser.teaser_cta.url
            : null,
        text:
          card.teaser && card.teaser.teaser_cta && card.teaser.teaser_cta.text
            ? card.teaser.teaser_cta.text
            : null,
      },
    };
  });
};

const formatLinkTiles = (data) => {
  return data.map((tile) => {
    return {
      header:
        tile &&
        tile.field_link_tile_header &&
        tile.field_link_tile_header.value &&
        tile.field_link_tile_header.value[0] &&
        tile.field_link_tile_header.value[0].value
          ? tile.field_link_tile_header.value[0].value
          : null,
      text:
        tile &&
        tile.field_link_tile_text &&
        tile.field_link_tile_text.value &&
        tile.field_link_tile_text.value[0] &&
        tile.field_link_tile_text.value[0].value
          ? tile.field_link_tile_text.value[0].value
          : null,
      link:
        tile && tile.field_link_tile_link && tile.field_link_tile_link.url
          ? { url: tile.field_link_tile_link.url }
          : null,
    };
  });
};

const formatHTMLCards = (data) => {
  return data.map((card) => {
    return card.value;
  });
};

export default formatSupportPageData;
