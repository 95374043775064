import React from "react";
import "./SupportLinkTiles.scss";
import { renderLinkTiles } from "./SupportLinkTilesMethods";

const SupportLinkTiles = ({ cards = [] }) => {
  return (
    <section className="mep-support-link-tiles">
      {renderLinkTiles(cards)}
    </section>
  );
};
export default SupportLinkTiles;
