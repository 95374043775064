import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import adminPageFetch from "../../utils/async/adminPageFetch";
import stgURL from "../../utils/helperObjects/envURLs";
import Loading from "../../components/Loading/Loading";
import "./AdminPage.scss";
import * as Sentry from "@sentry/browser";
import AdminProductData from "../../components/AdminProductData/AdminProductData";
import { TextInput, Radios, Button } from "terra-component-lib";

class AdminPage extends Component {
  constructor() {
    super();

    this.state = {
      content: [],
      filter: "",
      radio: null,
    };
  }

  onChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getURL = () => {
    return `${stgURL}en/api-v1/FetchUtility/product_page`;
  };

  getData = async () => {
    const url = this.getURL();
    this.setLoading();

    try {
      const { content } = await adminPageFetch(url);
      this.props.setInnerPageNav({ pageTitle: "Admin QA", links: [] });
      this.setState({ content });
      this.setSuccess();
    } catch (error) {
      console.log(error);
      this.setError();
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  renderProductData = () => {
    const products = this.state.content || [];

    const filteredProducts = this.filterProducts(products);

    return filteredProducts.map((product) => {
      return (
        <AdminProductData
          key={product.name}
          content={product}
          currentLanguageURL={this.props.language}
        />
      );
    });
  };

  filterProducts = (products) => {
    if (!this.state.filter || this.state.filter.length === 0) {
      if (!this.state.radio) {
        return products;
      } else {
        return products.filter((product) => {
          return product.translations.reduce((result, translation = {}) => {
            if (translation.language === this.state.radio) {
              result = true;
            }
            return result;
          }, false);
        });
      }
    } else {
      if (!this.state.radio) {
        return products.filter((product = {}) => {
          return product.name
            .toLowerCase()
            .includes(this.state.filter.toLowerCase());
        });
      } else {
        return products
          .filter((product = {}) => {
            return product.name
              .toLowerCase()
              .includes(this.state.filter.toLowerCase());
          })
          .filter((product) => {
            return product.translations.reduce((result, translation = {}) => {
              if (translation.language === this.state.radio) {
                result = true;
              }
              return result;
            }, false);
          });
      }
    }
  };

  handleSelect = (radio) => {
    const langs = {
      English: "en",
      "UK - English": "en-GB",
      French: "fr",
      German: "de",
      Dutch: "nl",
    };

    this.setState({ radio: langs[radio] });
  };

  clearRadios = () => {
    this.setState({ radio: null });
  };

  determineSelected = () => {
    const langs = {
      en: "English",
      "en-GB": "UK - English",
      fr: "French",
      de: "German",
      nl: "Dutch",
    };

    return langs[this.state.radio];
  };

  render() {
    const { status } = this.props;
    const { content } = this.state;
    return (
      <main
        className={`mep-admin-page ${status === "loading" &&
          "mep-admin-page--loading"}`}
      >
        {status === "loading" && <Loading />}
        {status === "error" && <p>error</p>}
        {status === "success" && content && (
          <main>
            <h1 className="mep-admin-page__header">Administrative QA -</h1>
            <TextInput
              label="Search products:"
              inputChange={this.onChange}
              value={this.state.filter}
              placeholder="search"
            />
            <p className="mep-admin-page__lang-filter-label">
              Filter by language:
            </p>
            <section className="mep-admin-page__radios">
              <Radios
                radios={[
                  "English",
                  "UK - English",
                  "French",
                  "German",
                  "Dutch",
                ]}
                selected={this.determineSelected()}
                selectRadio={this.handleSelect}
              />
              <Button
                onClick={this.clearRadios}
                text="Clear"
                className="ter-button--primary--1"
              />
            </section>
            <p className="mep-admin-page__prod-section-label">Products:</p>
            {this.renderProductData()}
          </main>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (metadata) => dispatch(updateMetadata(metadata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
