import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
  updateErrorStatus,
} from "../../actions";
import { formattedPageFetch } from "../../utils/async/pageFetch";
import PageBuilder from "../../components/PageBuilder/PageBuilder";
// import Loading from "../../components/Loading/Loading";
import stgURL from "../../utils/helperObjects/envURLs";
import "./TradePage.scss";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import * as Sentry from "@sentry/browser";
import FourZeroFourPage from "../FourZeroFourPage/FourZeroFourPage";

class TradePage extends Component {
  constructor() {
    super();

    this.state = { content: null };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getURL = () => {
    if (this.props.admin) {
      if (this.props.category) {
        const trade = this.props.trade;
        const category = this.props.match.params.categoryName;
        return `${stgURL}${this.props.match.params.translation}/api-v1/GetNodeByURL/${trade}-solutions$${category}/$`;
      } else {
        return `${stgURL}${this.props.match.params.translation}/api-v1/GetNodeByURL/${this.props.trade}-solutions/$`;
      }
    }
    if (this.props.category) {
      const trade = this.props.trade;
      const category = this.props.match.params.categoryName;
      return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/${trade}-solutions$${category}/$`;
    } else {
      return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/${
        this.props.trade
      }-solutions/$`;
    }
  };

  getLanguage = () => {
    if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  getData = async () => {
    this.setLoading();
    const url = this.getURL();

    if (this.props.admin) {
      console.log(url);
      // YES I KNOW THIS IS HERE - IT'S ON PURPOSE DON'T @ ME LAURA
    }

    try {
      const {
        content,
        innerNavData,
        metadata,
        error,
        ctaOverride = null,
      } = await formattedPageFetch(url);
      if (error && error === 404) {
        this.set404();
        return;
      }

      if (innerNavData) {
        this.props.setInnerPageNav(innerNavData);
      }

      if (metadata) {
        this.props.updateMetadata(metadata);
      }

      this.props.updateCTAOverride(ctaOverride);

      this.setState({ content });
      this.setSuccess();
    } catch (error) {
      this.setError();
      console.log(error);
      this.props.setInnerPageNav({ pageTitle: "500", links: [] });
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
    }
  };

  set404 = () => {
    this.props.updateErrorStatus(true);
    this.props.setStatus("success");
    this.setState({ content: "404" });
    window.prerenderReady = true;
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };
  setSuccess = () => {
    this.props.updateErrorStatus(false);
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  render() {
    const { status, navbarReady } = this.props;
    const { content } = this.state;
    return (
      <main
        className={`mep-trade-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-trade-page--loading"}`}
      >
        {(status === "loading" || (!navbarReady && status !== "error")) &&
          content !== "404" && <Loading />}
        {status === "error" && <ErrorPage />}
        {content === "404" && <FourZeroFourPage />}
        {status === "success" &&
          navbarReady &&
          content &&
          content !== "404" && (
            <PageBuilder
              learnMoreLabel={this.props.learnMoreLabel}
              page={this.props.page}
              content={content}
              motion={true}
              motionDirection="bottom"
              trade={this.props.trade}
              category={this.props.match.params.categoryName}
              noProductsData={this.props.noProductsData || "No Products"}
              admin={this.props.admin}
              sort
            />
          )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  navbarReady: state.navbarReady,
  errorStatus: state.errorStatus,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (data) => dispatch(updateMetadata(data)),
    updateCTAOverride: (data) => dispatch(updateCTAOverride(data)),
    updateErrorStatus: (errorStatus) =>
      dispatch(updateErrorStatus(errorStatus)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradePage);
