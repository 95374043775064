import React, { Component } from "react";
import {
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
} from "../../actions";
import { connect } from "react-redux";
import errorFetch from "../../utils/async/errorFetch";
import PageBuilder from "../../components/PageBuilder/PageBuilder";
import stgURL from "../../utils/helperObjects/envURLs";
import RedirectManagement from "../../components/RedirectManagement/RedirectManagement";

import "./ErrorPage.scss";

class ErrorPage extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.getData();
  }

  getLanguage = () => {
    if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  getData = async () => {
    const url = `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/500/$#`;
    try {
      const response = await errorFetch(url);
      const errorData = this.formatErrorData(response);
      this.setState({ errorData });
      this.props.setInnerPageNav({ pageTitle: 500, links: [] });
      this.props.updateCTAOverride(null);
      if (errorData && errorData.metadata) {
        this.props.updateMetadata(errorData.metadata);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // added this for one off changes. errorFetch is used for the 404 page, which is used here too. We needed a small adjustment of how we manipulate the data so I did that here.
  formatErrorData = (data) => {
    const content = [
      {
        header:
          data.content &&
          data.content[0] &&
          data.content[0].intro &&
          data.content[0].intro.header
            ? data.content[0].intro.header
            : "500 Error",
        subHeader:
          data.content &&
          data.content[0] &&
          data.content[0].intro &&
          data.content[0].intro.subHeader
            ? data.content[0].intro.subHeader
            : "Server Error",
        ctas:
          data.content && data.content[0] && data.content[0].ctas
            ? data.content[0].ctas
            : null,
        image_large:
          data.content && data.content[0] && data.content[0].image_large
            ? data.content[0].image_large
            : null,
        image_medium:
          data.content && data.content[0] && data.content[0].image_medium
            ? data.content.image_medium
            : null,
        type: "hero_5",
      },
    ];
    data.content = content;
    return data;
  };

  render() {
    return (
      <div>
        <RedirectManagement getLanguage={this.getLanguage} />
        {this.state.errorData && this.state.errorData.content && (
          <PageBuilder content={this.state.errorData.content} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (metadata) => dispatch(updateMetadata(metadata)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
  };
};

export default connect(null, mapDispatchToProps)(ErrorPage);
