import _ from "lodash";

export const formatDropdownsForTradeSelections = (
  data = {},
  selection = {},
  tradeCatProdObject = [],
  allText
) => {
  const { tradeDropdown, categoryDropdown } = getDropdowns(data);

  const selectedTrade = tradeCatProdObject.find((dropdown = {}) => {
    return dropdown.name === selection.value;
  });

  const newTradeOptions = tradeCatProdObject.map(
    (dropdown = {}) => dropdown.name
  );

  if (selectedTrade && selectedTrade.categories) {
    const newCategoryOptions = selectedTrade.categories.map((cat = {}) => {
      return cat.name;
    });

    newTradeOptions.unshift(allText);
    newCategoryOptions.unshift(allText);

    tradeDropdown.defaultText = allText;
    categoryDropdown.defaultText = allText;

    categoryDropdown.options = _.uniq(newCategoryOptions);
    tradeDropdown.options = _.uniq(newTradeOptions);
  }
  return [tradeDropdown, categoryDropdown];
};

export const formatDropdownsForCategorySelections = (
  data = {},
  selection = {},
  tradeCatProdObject = {},
  allText
) => {
  const { tradeDropdown, categoryDropdown } = getDropdowns(data);
  const newCategoryOptions = tradeCatProdObject.reduce((acc, trade = {}) => {
    if (trade.categories) {
      const categories = trade.categories.map((category = {}) => {
        return category.name;
      });

      return _.uniq([...acc, ...categories]);
    } else {
      return acc;
    }
  }, []);

  const newTradeOptions = tradeCatProdObject
    .filter((trade = {}) => {
      return (
        trade.categories &&
        trade.categories.reduce((acc, cat = {}) => {
          if (cat.name && selection.value && cat.name === selection.value) {
            acc = true;
          }
          return acc;
        }, false)
      );
    })
    .map((trade = {}) => trade.name);

  newTradeOptions.unshift(allText);
  newCategoryOptions.unshift(allText);

  categoryDropdown.options = _.uniq(newCategoryOptions);
  tradeDropdown.options = _.uniq(newTradeOptions);

  return [tradeDropdown, categoryDropdown];
};

export const formatDropdownsForTradeCategorySelection = (
  data = {},
  tradeSelection = {},
  categorySelection = {},
  tradeCatProdObject = {},
  allText
) => {
  const { tradeDropdown, categoryDropdown } = getDropdowns(data);

  const selectedTrade = tradeCatProdObject.find((dropdown) => {
    return dropdown.name === tradeSelection.value;
  });

  // need to filter trades by category if category is chosen first
  const newTradeOptions = tradeCatProdObject
    .filter((dropdown) => {
      return dropdown.categories.reduce((acc, cat) => {
        if (cat.name === categorySelection.value) {
          acc = true;
        }
        return acc;
      }, false);
    })
    .map((dropdown) => dropdown.name);

  if (selectedTrade && selectedTrade.categories) {
    const newCategoryOptions = selectedTrade.categories.map((cat) => {
      return cat.name;
    });

    newTradeOptions.unshift(allText);
    newCategoryOptions.unshift(allText);

    tradeDropdown.defaultText = allText;
    categoryDropdown.defaultText = allText;

    categoryDropdown.options = _.uniq(newCategoryOptions);
    tradeDropdown.options = _.uniq(newTradeOptions);
  }

  return [tradeDropdown, categoryDropdown];
};

export const formatDropdownsForNoSelections = (
  data = {},
  tradeCatProdObject = {},
  allText
) => {
  const { tradeDropdown, categoryDropdown } = getDropdowns(data);

  const newTradeOptions = tradeCatProdObject.map((dropdown) => dropdown.name);

  const newCategoryOptions = tradeCatProdObject.reduce((acc, trade) => {
    const categories = trade.categories.map((category) => {
      return category.name;
    });

    return _.uniq([...acc, ...categories]);
  }, []);

  const newProductOptions = tradeCatProdObject.reduce((acc, trade) => {
    const categories = trade.categories.reduce((acc, category) => {
      const products = category.products.map((product) => {
        return product.name;
      });
      if (products) {
        return [...acc, ...products];
      } else {
        return [...acc];
      }
    }, []);
    return _.uniq([...acc, ...categories]);
  }, []);

  newTradeOptions.unshift(allText);
  newCategoryOptions.unshift(allText);
  newProductOptions.unshift(allText);

  tradeDropdown.defaultText = allText;
  categoryDropdown.defaultText = allText;

  tradeDropdown.options = _.uniq(newTradeOptions);
  categoryDropdown.options = _.uniq(newCategoryOptions);

  return [tradeDropdown, categoryDropdown];
};

const getDropdowns = (data) => {
  const dropdowns = data.filter((point) => point.type === "dropdown");

  const tradeDropdown = dropdowns.find(
    (dropdown) => dropdown.machineName === "trade"
  );
  const categoryDropdown = dropdowns.find((dropdown) => {
    return dropdown.machineName === "product_category";
  });

  return { tradeDropdown, categoryDropdown };
};

export const formatDropdownsForBIM = (data = {}, dropdowns = [], allText) => {
  const { tradeDropdown } = getDropdowns(data);
  const newTradeOptions = dropdowns.map((dropdown) => dropdown.name);
  newTradeOptions.unshift(allText);

  tradeDropdown.defaultText = allText;

  tradeDropdown.options = _.uniq(newTradeOptions);

  return [tradeDropdown];
};
