import React, { Component } from "react";
import {
  formattedPageFetch,
  bimServicesPageFetch,
} from "../../utils/async/pageFetch";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
  updateCTAOverride,
  updateErrorStatus,
} from "../../actions";
import PropTypes from "prop-types";
import stgURL from "../../utils/helperObjects/envURLs";
import PageBuilder from "../../components/PageBuilder/PageBuilder";
import "./PageBuilderPage.scss";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import * as Sentry from "@sentry/browser";
import FourZeroFourPage from "../FourZeroFourPage/FourZeroFourPage";
import { errorStatusReducer } from "../../reducers/errorStatusReducer";

class PageBuilderPage extends Component {
  constructor() {
    super();

    this.state = { content: null, type: null };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  }

  getURL = () => {
    if (this.props.match.params.query) {
      // If the route uses a query parameter, return the appropriate URL with the corresponding page type
      const query = this.props.match.params.query;

      const url = `${stgURL}${this.getLanguage()}/${
        this.props.page
      }/${query}/$`;

      return url;
    } else if (this.props.customRoute) {
      // As of 6/29, there aren't currently any customRoutes left in Drupal that aren't being filtered out - KS 6/29
      const pathname =
        this.props.location && this.props.location.pathname
          ? this.props.location.pathname.replace(`${this.props.language}/`, "")
          : null;
      if (pathname) {
        return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL${pathname}/$`;
      }
    } else {
      return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/${
        // If not, it's a simple GetNodeByURL
        this.props.page
      }/$`;
    }
  };

  getBIMServicesURL = () => {
    // This can probably be refactored into the getURL/else logic - KS
    return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/bim-services/$#`;
  };

  getLanguage = () => {
    if (this.props.language) {
      return this.props.language;
    } else {
      return "en";
    }
  };

  getServicesPageURL = () => {
    return `${stgURL}${this.getLanguage()}/api-v1/GetNodeByURL/services$${
      this.props.match.params.query
    }/$`;
  };

  getData = async () => {
    this.setLoading();
    const url = this.getURL();
    try {
      // const data = await pageBuilderPageFetch(url);
      // true indicates that this fetch is for a pagebuilder page, which requires different formatting of the JSON data

      if (this.props.page === "bim-services") {
        const url = this.getBIMServicesURL();
        const {
          content,
          innerNavData,
          metadata,
          error,
          ctaOverride = null,
        } = await bimServicesPageFetch(url);

        if (error && error === 404) {
          this.set404();
          return;
        }
        this.props.setInnerPageNav(innerNavData);
        this.props.updateMetadata(metadata);
        this.props.updateCTAOverride(ctaOverride);
        this.setSuccess(content);
      } else if (this.props.page === "services") {
        const url = this.getServicesPageURL();

        const {
          content,
          innerNavData,
          metadata,
          error,
          ctaOverride,
        } = await formattedPageFetch(url);

        if (error && error === 404) {
          this.set404();
          return;
        }

        this.props.updateCTAOverride(ctaOverride);

        if (innerNavData) {
          this.props.setInnerPageNav(innerNavData);
        }
        if (metadata) {
          this.props.updateMetadata(metadata);
        }

        this.setSuccess(content);
      } else {
        const {
          content,
          innerNavData,
          metadata,
          error,
          ctaOverride,
        } = await formattedPageFetch(url, true, this.props.page);

        if (error && error === 404) {
          this.set404();
          return;
        }
        // wysiwig will either update content OR return content as is
        const wysiwig = this.checkForWysWig(content);
        this.setState({ content: wysiwig });
        this.props.setInnerPageNav(innerNavData);
        this.props.updateMetadata(metadata);
        this.props.updateCTAOverride(ctaOverride);
        this.setSuccess(content);
      }
    } catch (error) {
      console.log(error);
      this.setError();
      this.props.setInnerPageNav({ pageTitle: "500", links: [] });
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(error);
      }
    }
  };

  checkForWysWig = (data) => {
    let wysContent = {};
    const wys = data.find((datum) => {
      return datum.field_wysiwyg_card;
    });
    if (wys) {
      // we want to remove the indice if wys exists bc we are updating it
      const index = data.indexOf(wys);
      if (index > -1) {
        data.splice(index, 1);
      }
      wysContent["type"] = "feat_custom";
      wysContent["content"] = wys.field_wysiwyg_card.value;
    }
    // checks if wysContent is an empty object, if it is we want to return our data untouched.
    if (Object.keys(wysContent).length === 0) {
      return data;
    } else {
      // if wys has key value pairs, we want to put it into our data array
      data.push(wysContent);
      return data;
    }
  };

  set404 = () => {
    this.props.updateErrorStatus(true);
    this.props.setStatus("success");
    this.setState({ content: "404" });
    window.prerenderReady = true;
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };
  setSuccess = (content) => {
    this.props.updateErrorStatus(false);
    this.setState({ content });
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  render() {
    const { status, navbarReady } = this.props;
    const { content } = this.state;
    return (
      <main
        className={`mep-page-builder-page ${(status === "loading" ||
          (!navbarReady && status !== "error")) &&
          "mep-page-builder-page--loading"}`}
      >
        {(status === "loading" || (!navbarReady && status !== "error")) && (
          <Loading />
        )}
        {status === "error" && <ErrorPage />}
        {status === "success" && content && content === "404" && (
          <FourZeroFourPage />
        )}
        {status === "success" &&
          navbarReady &&
          content &&
          content !== "404" && (
            <PageBuilder
              page={this.props.page}
              content={content}
              motion={true}
              learnMoreLabel={this.props.learnMoreLabel}
              motionDirection={"bottom"}
            />
          )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  navbarReady: state.navbarReady,
  errorStatus: state.errorStatus,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (data) => dispatch(updateMetadata(data)),
    updateCTAOverride: (link) => dispatch(updateCTAOverride(link)),
    updateErrorStatus: (errorStatus) =>
      dispatch(updateErrorStatus(errorStatus)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageBuilderPage);

PageBuilderPage.propTypes = {
  match: PropTypes.object.isRequired,
};
