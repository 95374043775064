import React from "react";
import { renderRegions } from "./AdminProductDataMethods";
import "./AdminProductData.scss";
import { NavLink } from "react-router-dom";

const AdminProductData = ({ content, currentLanguageURL }) => {
  const { name, regions, translations, url } = content;
  return (
    <div className="mep-admin-product-data">
      {name && <h4 className="mep-admin-product-data__name">{name}</h4>}
      {regions && (
        <p className="mep-admin-product-data__regions">
          Regions: {renderRegions(regions)}
        </p>
      )}
      {translations && (
        <>
          {translations.map((translation) => {
            return (
              <TranslationData
                content={translation}
                url={url}
                currentLanguageURL={currentLanguageURL}
              />
            );
          })}
        </>
      )}
      <div></div>
    </div>
  );
};

export default AdminProductData;

const TranslationData = ({ content = {}, url = "", currentLanguageURL }) => {
  const {
    language = "",
    teaserImage = {},
    teaserName = "",
    teaserText = "",
  } = content;

  return (
    <div className="mep-translation-data">
      <img src={teaserImage.url} alt={teaserName} />
      <div>
        <p>
          <span className="bold-me-pls">Language:</span>{" "}
          {languageCodeThing[language]} - {language.toUpperCase()}
        </p>
        <p>
          <span className="bold-me-pls">Card teaser name:</span> {teaserName}
        </p>
        <p>
          <span className="bold-me-pls">Card teaser text:</span> {teaserText}
        </p>
        <p>
          <span className="bold-me-pls">Normal URL:</span>{" "}
          <NavLink to={`/${language}${url}`}>{`/${language}${url}`}</NavLink>
        </p>
        <p>
          <span className="bold-me-pls">QA URL:</span>{" "}
          <NavLink
            to={`/${currentLanguageURL}/admin${url}/${language}`}
          >{`/admin${url}/${language}`}</NavLink>
        </p>
      </div>
    </div>
  );
};

const languageCodeThing = {
  en: "English",
  "en-GB": "UK English",
  fr: "French",
  de: "German",
  nl: "Dutch",
};
